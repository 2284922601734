import React from 'react';
import { CommandType } from '../../types/chat';
import { CommandParser } from '../../services/commandParser';

interface CommandPaletteProps {
  isOpen: boolean;
  inputValue: string;
  onSelectCommand: (commandType: CommandType) => void;
}

const CommandPalette: React.FC<CommandPaletteProps> = ({ isOpen, inputValue, onSelectCommand }) => {
  if (!isOpen) return null;

  const commands = CommandParser.getAllCommands();
  const searchTerm = inputValue.toLowerCase().replace('@', '');
  
  const filteredCommands = commands.filter(command => 
    command.type.toLowerCase().includes(searchTerm) ||
    command.description.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="absolute bottom-full left-0 w-full bg-white rounded-lg shadow-lg border border-gray-200 mb-2 max-h-64 overflow-y-auto">
      {filteredCommands.map((command) => (
        <div
          key={command.type}
          className="p-3 hover:bg-gray-50 cursor-pointer border-b last:border-b-0"
          onClick={() => onSelectCommand(command.type)}
        >
          <div className="flex items-center justify-between">
            <span className="font-medium text-[#004DB5]">{command.type}</span>
            <span className="text-sm text-gray-500">{command.usage}</span>
          </div>
          <p className="text-sm text-gray-600 mt-1">{command.description}</p>
          <div className="mt-2">
            {command.examples.map((example, index) => (
              <div
                key={index}
                className="text-xs bg-gray-50 text-gray-600 px-2 py-1 rounded inline-block mr-2 mt-1"
              >
                {example}
              </div>
            ))}
          </div>
        </div>
      ))}
      {filteredCommands.length === 0 && (
        <div className="p-4 text-center text-gray-500">
          No matching commands found
        </div>
      )}
    </div>
  );
};

export default CommandPalette;
