// Let's implement the command handlers that will connect our parser to the API
import { chatApi } from './api/chatApi';
import { ParsedCommand } from '../types/chat';

export const commandHandlers = {
  async handleRunCommand(command: ParsedCommand, agentId: string) {
    const response = await chatApi.runCommand(agentId, command.content, command.params?.goalId);
    return {
      type: 'run',
      content: response.result,
      metadata: {
        status: response.status,
        command: command.type
      }
    };
  },

  async handleTaskCommand(command: ParsedCommand, agentId: string) {
    const response = await chatApi.processTask(agentId, command.content);
    return {
      type: 'task',
      content: response.result,
      metadata: {
        status: response.status,
        taskId: response.id
      }
    };
  },

  async handleGoalCommand(command: ParsedCommand, agentId: string) {
    const response = await chatApi.processGoal(agentId, command.content);
    return {
      type: 'goal',
      content: response.result,
      metadata: {
        status: response.status,
        goalId: response.id
      }
    };
  }
};
