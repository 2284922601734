import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import axios from 'axios';
import { AgentPersona } from '../context/AgentContext';
import { toast } from 'react-toastify';

interface ApiNode {
  id: string;
  name: string;
  type: string;
}

interface Node {
  id: string;
  name: string;
  group: string;
  val: number;
  collapsed: boolean;
  parentId?: string;
}

interface Link {
  source: string;
  target: string;
}

interface GraphData {
  nodes: Node[];
  links: Link[];
}

interface KnowledgeGraphExplorerProps {
  width: number;
  height: number;
  selectedPersona: AgentPersona;
}

// Add new cache utility
const CACHE_KEY_PREFIX = 'graph_cache_';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

interface CacheEntry {
  data: any;
  timestamp: number;
}

const getFromCache = (agentId: string): GraphData | null => {
  const cached = localStorage.getItem(`${CACHE_KEY_PREFIX}${agentId}`);
  if (!cached) return null;

  try {
    const entry: CacheEntry = JSON.parse(cached);
    if (Date.now() - entry.timestamp > CACHE_DURATION) {
      localStorage.removeItem(`${CACHE_KEY_PREFIX}${agentId}`);
      return null;
    }
    return entry.data;
  } catch (e) {
    console.warn('Failed to parse cache:', e);
    return null;
  }
};

const setToCache = (agentId: string, data: GraphData) => {
  try {
    const entry: CacheEntry = {
      data,
      timestamp: Date.now()
    };
    localStorage.setItem(`${CACHE_KEY_PREFIX}${agentId}`, JSON.stringify(entry));
  } catch (e) {
    console.warn('Failed to cache data:', e);
  }
};

const LoadingSpinner: React.FC<{ message?: string }> = ({ message = 'Loading...' }) => (
  <div className="flex flex-col items-center justify-center p-8">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mb-2" />
    <p className="text-sm text-gray-500">{message}</p>
  </div>
);

const ErrorMessage: React.FC<{ message: string; onRetry?: () => void }> = ({ message, onRetry }) => (
  <div className="bg-red-50 text-red-500 p-4 rounded-lg flex flex-col items-center">
    <p className="text-sm mb-2">{message}</p>
    {onRetry && (
      <button 
        onClick={onRetry}
        className="text-sm bg-red-100 hover:bg-red-200 text-red-700 px-3 py-1 rounded"
      >
        Retry
      </button>
    )}
  </div>
);

interface Neo4jNode {
  element_id: string;
  labels: string[];
  properties: {
    name?: string;
    agent_id?: string;
    type?: string;
    content?: string;
    [key: string]: any;
  };
}

const KnowledgeGraphExplorer: React.FC<KnowledgeGraphExplorerProps> = ({ width, height, selectedPersona }) => {
  // Add memoized state to prevent unnecessary rerenders
  const [graphData, setGraphData] = useState<GraphData>(() => ({
    nodes: [],
    links: []
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const graphRef = useRef<any>();

  // Memoize the node processing function
  const processNodes = useCallback((apiData: any) => {
    const mainNode: Node = { 
      id: apiData.node.id || selectedPersona.id, 
      name: selectedPersona.name,
      group: 'agent', 
      val: 30,
      collapsed: false
    };
    
    const nodes: Node[] = [mainNode];
    const links: Link[] = [];
    const processedNodes = new Set<string>();

    // Create category nodes first
    const categories = [
      'project', 'goals', 'system_prompt', 'research_interests', 'user_intents', 
      'tools', 'agent_scratchpad', 'workspace_directory', 'sentiment', 
      'impliedintent', 'knowledge_base', 'mailbox', 'other'
    ];

    const categoryNodes = categories.map(category => ({
      id: `category_${category}_${selectedPersona.id}`,
      name: category.replace('_', ' ').charAt(0).toUpperCase() + category.replace('_', ' ').slice(1),
      group: category,
      val: 20,
      collapsed: true
    }));
    
    nodes.push(...categoryNodes);
    categoryNodes.forEach(categoryNode => {
      links.push({ source: mainNode.id, target: categoryNode.id });
    });

    // Process connected nodes
    apiData.connected_nodes?.forEach((node: any) => {
      if (!node?.id && !node?.element_id) return;

      const nodeId = node.id || node.element_id;
      if (processedNodes.has(nodeId)) return;
      processedNodes.add(nodeId);

      const nodeType = node.type || 
                      (Array.isArray(node.labels) && node.labels[0]) || 
                      'other';
      
      let category = 'other';
      switch (nodeType.toLowerCase()) {
        case 'goal': category = 'goals'; break;
        case 'project': category = 'project'; break;
        case 'systemprompt': category = 'system_prompt'; break;
        case 'researchinterest': category = 'research_interests'; break;
        case 'userintent': category = 'user_intents'; break;
        case 'tool': category = 'tools'; break;
        case 'agentscratchpad': category = 'agent_scratchpad'; break;
        case 'workspacedirectory': category = 'workspace_directory'; break;
        case 'sentiment': category = 'sentiment'; break;
        case 'impliedintent': category = 'impliedintent'; break;
        case 'knowledgebase': category = 'knowledge_base'; break;
        case 'mailbox': category = 'mailbox'; break;
      }

      const childNode: Node = {
        id: nodeId,
        name: node.name || node.properties?.name || 'Unnamed Node',
        group: category,
        val: 15,
        collapsed: true,
        parentId: `category_${category}_${selectedPersona.id}`
      };

      nodes.push(childNode);
      links.push({ 
        source: `category_${category}_${selectedPersona.id}`, 
        target: nodeId 
      });
    });

    return { nodes, links };
  }, [selectedPersona.id, selectedPersona.name]);

  // Memoize the fetch function
  const fetchAgentData = useCallback(async () => {
    if (!selectedPersona?.id) return;
    
    setIsLoading(true);
    try {
      // Check cache first
      const cachedData = getFromCache(selectedPersona.id);
      if (cachedData) {
        setGraphData(cachedData);
        setIsLoading(false);
        return;
      }

      const response = await axios.get(`https://graph.brainchain.cloud/api/v1/node/Agent`, {
        params: {
          id: selectedPersona.id,
          connected: true
        }
      });
      
      const apiData = response.data;
      if (!apiData?.node) {
        throw new Error('Invalid API response structure');
      }

      const processedData = processNodes(apiData);
      setGraphData(processedData);
      setToCache(selectedPersona.id, processedData);

    } catch (error) {
      console.error('Error fetching graph data:', error);
      setError(error instanceof Error ? error.message : 'Failed to load graph data');
    } finally {
      setIsLoading(false);
    }
  }, [selectedPersona.id, processNodes]);

  // Only fetch when component mounts or selectedPersona changes
  useEffect(() => {
    fetchAgentData();
  }, [fetchAgentData]);

  // Memoize the node click handler
  const handleNodeClick = useCallback((node: Node) => {
    setGraphData(prevData => {
      const newNodes = prevData.nodes.map(n => 
        n.id === node.id ? { ...n, collapsed: !n.collapsed } : n
      );
      return { ...prevData, nodes: newNodes };
    });
  }, []);

  const getNodeColor = (group: string) => {
    switch (group) {
      case 'agent':
        return '#004DB5';  // Blue
      case 'project':
        return '#00A86B';  // Green
      case 'goals':
        return '#FFA500';  // Orange
      case 'system_prompt':
        return '#8A2BE2';  // Purple
      case 'research_interests':
        return '#FF4500';  // OrangeRed
      case 'user_intents':
        return '#1E90FF';  // DodgerBlue
      case 'tools':
        return '#FF6347';  // Tomato
      case 'agent_scratchpad':
        return '#20B2AA';  // Light Sea Green
      case 'workspace_directory':
        return '#DAA520';  // Goldenrod
      case 'sentiment':
        return '#FF1493';  // Deep Pink
      case 'impliedintent':
        return '#00CED1';  // Dark Turquoise
      case 'knowledge_base':
        return '#32CD32';  // Lime Green
      case 'mailbox':
        return '#BA55D3';  // Medium Orchid
      default:
        return '#7D7D7D';  // Grey for other types
    }
  };

  const wrapText = (context: CanvasRenderingContext2D, text: string, maxWidth: number) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = context.measureText(currentLine + " " + word).width;
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.d3Force('link').distance(200);
      graphRef.current.d3Force('charge').strength(-300);
    }
  }, [graphData]);

  const handleRetry = useCallback(() => {
    fetchAgentData();
  }, [fetchAgentData]);

  return (
    <div style={{ width, height }} className="relative">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
        </div>
      )}
      
      {error && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-red-50 text-red-500 p-4 rounded-lg">
            {error}
          </div>
        </div>
      )}

      {!isLoading && !error && graphData.nodes.length > 0 && (
        <ForceGraph2D
          ref={graphRef}
          graphData={graphData}
          nodeLabel="name"
          nodeColor={(node: Node) => getNodeColor(node.group)}
          nodeCanvasObject={(node: any, ctx: CanvasRenderingContext2D, globalScale: number) => {
            const label = node.name;
            const fontSize = 12 / globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;

            const maxWidth = 100 / globalScale;
            const lineHeight = fontSize * 1.2;
            const lines = wrapText(ctx, label, maxWidth);

            const nodeSize = Math.max(30, Math.sqrt(node.val) * 5, lines.length * lineHeight + 10);

            ctx.fillStyle = getNodeColor(node.group);
            ctx.beginPath();
            ctx.arc(node.x || 0, node.y || 0, nodeSize / 2, 0, 2 * Math.PI);
            ctx.fill();

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#ffffff';
            
            lines.forEach((line, i) => {
              const y = (node.y || 0) - (lines.length - 1) * lineHeight / 2 + i * lineHeight;
              ctx.fillText(line, node.x || 0, y);
            });
          }}
          nodeCanvasObjectMode={() => 'replace'}
          onNodeClick={handleNodeClick}
          linkColor={() => 'rgba(0,0,0,0.2)'}
          linkWidth={1}
          cooldownTicks={100}
          onEngineStop={() => {
            graphRef.current?.zoomToFit(400, 50);
          }}
        />
      )}

      {!isLoading && !error && graphData.nodes.length === 0 && (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-500">No graph data available</p>
        </div>
      )}
    </div>
  );
};

export default KnowledgeGraphExplorer;