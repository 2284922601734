import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Database, FileText, X, Target, Compass, Map, MessageSquare, Loader, ChevronDown, ChevronRight, Home } from 'lucide-react';
import KnowledgeGraphExplorer from './KnowledgeGraphExplorer';
import axios from 'axios';
import { useAgentContext } from '../context/AgentContext';
import toast from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';

// Add interface for Neo4j node structure
interface Neo4jNode {
  element_id: string;
  labels: string[];
  properties: {
    name?: string;
    agent_id?: string;
    type?: string;
    content?: string;
    [key: string]: any;  // Allow for other properties
  };
}

// Update AgentData interface
interface AgentData {
  node: {
    agent_id: string;
    system_prompt: string;
  };
  connected_nodes: Neo4jNode[];
  connected_relationships: any[];
}

interface LoadingStates {
  initial: boolean;
  graph: boolean;
}

// Add LoadingSpinner component
const LoadingSpinner: React.FC<{ message?: string }> = ({ message = 'Loading...' }) => (
  <div className="flex flex-col items-center justify-center p-8">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mb-2" />
    <p className="text-sm text-gray-500">{message}</p>
  </div>
);

// Add ErrorMessage component
const ErrorMessage: React.FC<{ message: string; onRetry?: () => void }> = ({ message, onRetry }) => (
  <div className="bg-red-50 text-red-500 p-4 rounded-lg flex flex-col items-center">
    <p className="text-sm mb-2">{message}</p>
    {onRetry && (
      <button 
        onClick={onRetry}
        className="text-sm bg-red-100 hover:bg-red-200 text-red-700 px-3 py-1 rounded"
      >
        Retry
      </button>
    )}
  </div>
);

// Add cache utilities (same as in KnowledgeGraphExplorer)
const CACHE_KEY_PREFIX = 'agent_overview_cache_';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

interface CacheEntry {
  data: AgentData;
  timestamp: number;
}

// Update the cache handling
const getFromCache = (agentId: string): AgentData | null => {
  const cached = localStorage.getItem(`${CACHE_KEY_PREFIX}${agentId}`);
  if (!cached) return null;

  try {
    const entry: CacheEntry = JSON.parse(cached);
    if (Date.now() - entry.timestamp > CACHE_DURATION) {
      localStorage.removeItem(`${CACHE_KEY_PREFIX}${agentId}`);
      return null;
    }
    return entry.data;
  } catch (e) {
    console.warn('Failed to parse cache:', e);
    return null;
  }
};

const setToCache = (agentId: string, data: AgentData) => {
  try {
    const entry: CacheEntry = {
      data,
      timestamp: Date.now()
    };
    localStorage.setItem(`${CACHE_KEY_PREFIX}${agentId}`, JSON.stringify(entry));
  } catch (e) {
    console.warn('Failed to cache data:', e);
  }
};

// Add after existing interfaces
interface GraphData {
  nodes: Node[];
  links: Link[];
}

interface Node {
  id: string;
  name: string;
  group: string;
  val: number;
  collapsed: boolean;
  parentId?: string;
}

interface Link {
  source: string;
  target: string;
}

// Update the determineNodeCategory function with more specific mappings
const determineNodeCategory = (labels: string[]): string => {
  // Log the incoming labels for debugging
  console.log('🏷️ Processing labels:', labels);

  // Handle case where labels might be undefined
  if (!labels || labels.length === 0) {
    return 'other';
  }

  // Get all labels in lowercase for easier matching
  const labelTypes = labels.map(l => l.toLowerCase());
  
  // Check for specific label patterns
  if (labelTypes.some(l => l.includes('research') || l.includes('interest'))) {
    return 'research_interests';
  }
  if (labelTypes.some(l => l.includes('goal') || l.includes('objective'))) {
    return 'goals';
  }
  if (labelTypes.some(l => l.includes('project') || l.includes('milestone'))) {
    return 'project';
  }
  if (labelTypes.some(l => l.includes('knowledge'))) {
    return 'knowledge';
  }
  if (labelTypes.some(l => l.includes('document'))) {
    return 'documents';
  }
  if (labelTypes.some(l => l.includes('update'))) {
    return 'updates';
  }
  if (labelTypes.some(l => l.includes('intent'))) {
    return 'intents';
  }
  if (labelTypes.some(l => l.includes('task'))) {
    return 'tasks';
  }
  if (labelTypes.some(l => l.includes('skill'))) {
    return 'skills';
  }
  if (labelTypes.some(l => l.includes('domain'))) {
    return 'domains';
  }

  // Log the categorization result
  console.log(`🔍 Labels "${labels.join(', ')}" mapped to category "other"`);
  return 'other';
};

interface StatsCardProps {
  icon: React.ReactNode;
  title: string;
  value: number;
  subtitle: string;
  action?: {
    label: string;
    onClick: () => void;
  };
}

// Add this component before the main AgentOverview component
const StatsCard: React.FC<StatsCardProps> = ({ icon, title, value, subtitle, action }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-200"
  >
    <div className="flex justify-between items-start mb-4">
      <div className="flex items-center">
        <div className="p-2 bg-blue-50 rounded-lg mr-3">
          {icon}
        </div>
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      </div>
      {action && (
        <button
          onClick={action.onClick}
          className="text-sm font-medium text-blue-600 hover:text-blue-700 flex items-center"
        >
          {action.label}
          <ChevronRight className="w-4 h-4 ml-1" />
        </button>
      )}
    </div>
    <div className="space-y-1">
      <div className="text-3xl font-bold text-gray-900">
        {value.toLocaleString()}
      </div>
      <p className="text-sm text-gray-500">{subtitle}</p>
    </div>
  </motion.div>
);

// Add these new interfaces after the existing ones
interface InfoCardProps {
  icon: React.ReactNode;
  title: string;
  loading?: boolean;
  isEmpty?: boolean;
  emptyMessage?: string;
  children: React.ReactNode;
}

interface InfoItemProps {
  name: string;
  status?: 'active' | 'completed' | 'pending';
}

// Add these new components before the main AgentOverview component
const InfoCard: React.FC<InfoCardProps> = ({
  icon,
  title,
  loading,
  isEmpty,
  emptyMessage = 'No items available',
  children
}) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-200"
  >
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <div className="p-2 bg-blue-50 rounded-lg mr-3">
          {icon}
        </div>
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      </div>
    </div>
    
    {loading ? (
      <div className="flex items-center justify-center py-8">
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500" />
      </div>
    ) : isEmpty ? (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex flex-col items-center justify-center py-8 text-gray-400"
      >
        <p className="text-sm">{emptyMessage}</p>
      </motion.div>
    ) : (
      children
    )}
  </motion.div>
);

const InfoItem: React.FC<InfoItemProps> = ({ name, status = 'active' }) => (
  <motion.div 
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    className="flex items-center space-x-3 p-3 hover:bg-gray-50 rounded-lg transition-colors duration-200"
  >
    <span 
      className={`w-2 h-2 rounded-full flex-shrink-0 ${
        status === 'completed' ? 'bg-green-500' :
        status === 'pending' ? 'bg-yellow-500' :
        'bg-blue-500'
      }`}
    />
    <span className="text-sm text-gray-700">{name}</span>
  </motion.div>
);

// First, let's add a new interface for section headers
interface SectionHeaderProps {
  title: string;
  subtitle?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subtitle }) => (
  <div className="mb-6">
    <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
    {subtitle && (
      <p className="text-sm text-gray-500 mt-1">{subtitle}</p>
    )}
  </div>
);

const AgentOverview: React.FC = () => {
  // Move the useState declarations inside the component
  const [isExplorerOpen, setIsExplorerOpen] = useState(false);
  const { selectedAgent, setSelectedAgent, liveAgents } = useAgentContext();
  const [agentData, setAgentData] = useState<AgentData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<LoadingStates>({
    initial: true,
    graph: false
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Add loading feedback
  useEffect(() => {
    if (loading.initial) {
      console.log('🔄 Loading agent data...');
    }
  }, [loading.initial]);

  // Add data validation
  useEffect(() => {
    if (agentData) {
      console.log('📊 Agent Data Stats:', {
        hasNode: !!agentData.node,
        connectedNodesCount: agentData.connected_nodes?.length || 0,
        relationshipsCount: agentData.connected_relationships?.length || 0
      });
    }
  }, [agentData]);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Move fetchData outside of useEffect and make it a callback
  const fetchData = useCallback(async (agentId: string) => {
    setLoading(prev => ({ ...prev, initial: true }));
    setError(null);

    try {
      // Check cache first
      const cachedData = getFromCache(agentId);
      if (cachedData) {
        console.log('📦 Using cached data for agent:', agentId);
        setAgentData(cachedData);
        setLoading(prev => ({ ...prev, initial: false }));
        return;
      }

      console.log('📡 Making API request for agent:', agentId);
      const response = await axios.get(`https://graph.brainchain.cloud/api/v1/node/Agent`, {
        params: {
          id: agentId,
          connected: true
        }
      });

      if (!response.data?.node) {
        throw new Error('Invalid API response structure');
      }

      const transformedData = {
        node: response.data.node,
        connected_nodes: response.data.connected_nodes?.map((node: any) => ({
          element_id: node.id || node.element_id,
          labels: Array.isArray(node.labels) ? node.labels : 
                 typeof node.labels === 'string' ? [node.labels] : 
                 node.type ? [node.type] : ['unknown'],
          properties: {
            name: node.name || node.properties?.name || 'Unnamed Node',
            type: node.type || node.properties?.type,
            content: node.content || node.properties?.content,
            ...node.properties
          }
        })) || [],
        connected_relationships: response.data.connected_relationships || []
      };

      setAgentData(transformedData);
      setToCache(agentId, transformedData);

    } catch (error: any) {
      console.error('❌ Error fetching agent data:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to fetch agent data';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(prev => ({ ...prev, initial: false }));
    }
  }, []); // Empty dependency array since it only uses setState functions

  // Update the useEffect to use the callback
  useEffect(() => {
    if (selectedAgent?.id) {
      fetchData(selectedAgent.id);
    }
  }, [selectedAgent?.id, fetchData]);

  // Now handleRetry can properly use fetchData
  const handleRetry = useCallback(() => {
    if (selectedAgent?.id) {
      fetchData(selectedAgent.id);
    }
  }, [selectedAgent?.id, fetchData]);

  // Add cache cleanup
  useEffect(() => {
    return () => {
      // Clean up expired cache entries on component unmount
      Object.keys(localStorage)
        .filter(key => key.startsWith(CACHE_KEY_PREFIX))
        .forEach(key => {
          try {
            const entry: CacheEntry = JSON.parse(localStorage.getItem(key) || '');
            if (Date.now() - entry.timestamp > CACHE_DURATION) {
              localStorage.removeItem(key);
            }
          } catch (e) {
            localStorage.removeItem(key);
          }
        });
    };
  }, []);

  // Update the getNodesByType function to be more robust
  const getNodesByType = useCallback((type: string) => {
    if (!agentData?.connected_nodes) {
      console.log('❌ No connected nodes available');
      return [];
    }

    console.group(`🔍 Filtering nodes for type: ${type}`);
    
    // First, log the raw data we're working with
    console.log('Connected Nodes Sample:', {
      total: agentData.connected_nodes.length,
      firstNode: agentData.connected_nodes[0],
      labels: agentData.connected_nodes.map(n => n.labels),
    });

    const nodes = agentData.connected_nodes.filter((node: Neo4jNode) => {
      // Enhanced label matching
      const nodeLabels = node.labels?.map(l => l.toLowerCase()) || [];
      const nodeProps = node.properties || {};
      
      // Log each node's data for debugging
      console.log('Processing Node:', {
        id: node.element_id,
        labels: nodeLabels,
        properties: nodeProps
      });

      // Check for research interests
      if (type === 'research') {
        return nodeLabels.some(l => 
          l.includes('research') || 
          l.includes('interest') || 
          l.includes('topic') ||
          nodeProps.type === 'research_interest'
        );
      }

      // Check for goals
      if (type === 'goal') {
        return nodeLabels.some(l => 
          l.includes('goal') || 
          l.includes('objective') ||
          nodeProps.type === 'goal'
        );
      }

      // Check for projects/roadmap
      if (type === 'project') {
        return nodeLabels.some(l => 
          l.includes('project') || 
          l.includes('milestone') ||
          l.includes('roadmap') ||
          nodeProps.type === 'project'
        );
      }

      return false;
    });

    console.log(`Found ${nodes.length} ${type} nodes`);
    console.groupEnd();

    return nodes.map(node => ({
      id: node.element_id,
      name: node.properties?.name || node.properties?.title || 'Unnamed Node',
      description: node.properties?.description || node.properties?.content || '',
      type: node.labels?.[0] || 'Unknown',
      metadata: node.properties
    }));
  }, [agentData]);

  // Get node statistics using labels
  const getNodeStats = useCallback(() => {
    if (!agentData?.connected_nodes) return null;

    try {
      // Get unique node types from labels
      const nodeTypes = agentData.connected_nodes
        .map(node => node.labels?.[0] || 'unknown')
        .filter((type, index, self) => 
          type && self.indexOf(type) === index
        );

      // Count nodes by their primary label
      const typeCounts = agentData.connected_nodes.reduce((acc: Record<string, number>, node) => {
        const type = node.labels?.[0] || 'unknown';
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {});

      console.log('Node Types:', nodeTypes);
      console.log('Type Counts:', typeCounts);

      return {
        types: nodeTypes,
        counts: typeCounts,
        total: agentData.connected_nodes.length
      };
    } catch (error) {
      console.error('Error calculating node stats:', error);
      return null;
    }
  }, [agentData]);

  // Get different node types with memoization
  const researchInterests = useMemo(() => getNodesByType('research'), [getNodesByType]);
  const goals = useMemo(() => getNodesByType('goal'), [getNodesByType]);
  const roadmap = useMemo(() => getNodesByType('project'), [getNodesByType]);

  // Calculate stats
  const stats = useMemo(() => {
    const nodeStats = getNodeStats();
    return {
      total: nodeStats?.total || 0,
      knowledge: nodeStats?.counts?.['Knowledge'] || 0,
      updates: nodeStats?.counts?.['Update'] || 0,
      documents: nodeStats?.counts?.['Document'] || 0
    };
  }, [getNodeStats]);

  // Update loading state management
  const handleExplorerOpen = useCallback(() => {
    setLoading(prev => ({ ...prev, graph: true }));
    setIsExplorerOpen(true);
    // Reset graph loading state after a delay
    setTimeout(() => {
      setLoading(prev => ({ ...prev, graph: false }));
    }, 1000);
  }, []);

  // Add data validation effect
  useEffect(() => {
    if (agentData) {
      console.group('📊 Data Validation');
      console.log('Connected Nodes:', {
        total: agentData.connected_nodes.length,
        withLabels: agentData.connected_nodes.filter(n => n.labels?.length > 0).length,
        withProperties: agentData.connected_nodes.filter(n => Object.keys(n.properties || {}).length > 0).length
      });
      console.groupEnd();
    }
  }, [agentData]);

  if (!selectedAgent) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center justify-center min-h-[600px]">
        <div className="max-w-xl w-full text-center">
          {/* Welcome Header */}
          <div className="mb-12">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              Choose your AI Agent
            </h1>
            <p className="text-lg text-gray-600">
              Select an AI agent to begin exploring their knowledge and capabilities
            </p>
          </div>

          {/* Agent Selector Dropdown */}
          <div className="relative w-full max-w-md mx-auto" ref={dropdownRef}>
            <div className="relative">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="w-full bg-white border border-gray-200 rounded-lg px-4 py-3 text-left shadow-sm hover:border-blue-300 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <div className="flex items-center justify-between">
                  <span className="block truncate text-gray-700">
                    {liveAgents.length > 0 ? 'Choose an Agent' : 'Loading Agents...'}
                  </span>
                  <ChevronDown
                    className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${
                      isDropdownOpen ? 'transform rotate-180' : ''
                    }`}
                  />
                </div>
              </button>

              {/* Dropdown Menu */}
              {isDropdownOpen && (
                <div className="absolute mt-2 w-full bg-white rounded-lg shadow-lg border border-gray-100 z-10 overflow-hidden">
                  <div className="max-h-[400px] overflow-y-auto">
                    {[...liveAgents]
                      .sort((a, b) => {
                        if (!a.name && !b.name) return 0;
                        if (!a.name) return 1;
                        if (!b.name) return -1;
                        return a.name.localeCompare(b.name);
                      })
                      .map((agent) => (
                        <button
                          key={agent.id}
                          onClick={() => {
                            setSelectedAgent(agent);
                            setIsDropdownOpen(false);
                          }}
                          className="w-full px-4 py-3 text-left hover:bg-gray-50 flex flex-col border-b border-gray-100 last:border-0"
                        >
                          <div className="flex items-center space-x-2">
                            <span className="w-2 h-2 rounded-full bg-green-500 flex-shrink-0" />
                            <span className="font-medium text-gray-900">{agent.name}</span>
                          </div>
                          {agent.description && (
                            <p className="mt-1 text-sm text-gray-500 pl-4">
                              {agent.description}
                            </p>
                          )}
                        </button>
                      ))}

                    {liveAgents.length === 0 && (
                      <div className="px-4 py-8 text-center">
                        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-50 mb-4">
                          <Loader className="w-6 h-6 text-blue-500 animate-spin" />
                        </div>
                        <p className="text-sm font-medium text-gray-900">
                          Initializing Agents
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Please wait while we connect to available agents
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Helper Text */}
            <p className="mt-4 text-sm text-gray-500 text-center">
              Each agent is specialized in different domains and capabilities
            </p>
          </div>

          {/* Features Preview */}
          <div className="grid grid-cols-3 gap-6 mt-16">
            <div className="p-4">
              <Database className="w-8 h-8 text-blue-500 mb-3 mx-auto" />
              <h3 className="text-sm font-semibold text-gray-900 mb-1">Knowledge Graph</h3>
              <p className="text-sm text-gray-500">Explore agent's structured knowledge</p>
            </div>
            <div className="p-4">
              <MessageSquare className="w-8 h-8 text-blue-500 mb-3 mx-auto" />
              <h3 className="text-sm font-semibold text-gray-900 mb-1">Interactive Chat</h3>
              <p className="text-sm text-gray-500">Engage in intelligent conversations</p>
            </div>
            <div className="p-4">
              <Target className="w-8 h-8 text-blue-500 mb-3 mx-auto" />
              <h3 className="text-sm font-semibold text-gray-900 mb-1">Learning Goals</h3>
              <p className="text-sm text-gray-500">Track agent's learning progress</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const totalNodes = agentData?.connected_nodes.length || 0;

  // Create an adapter function to convert LiveAgent to AgentPersona
  const adaptAgentToPersona = (agent: any): any => ({
    id: agent.id,
    name: agent.name,
    description: agent.description || '',
    nodes: agentData?.connected_nodes || [],
    knowledgeDomains: [],
    totalNodes: agentData?.connected_nodes?.length || 0,
    recentUpdates: getNodesByType('Update').length,
    customDocuments: getNodesByType('Document').length
  });

  return (
    <div className="flex-1 overflow-y-auto bg-gray-50 px-6 py-8">
      {loading.initial ? (
        <LoadingSpinner message="Loading agent data..." />
      ) : error ? (
        <ErrorMessage message={error} onRetry={handleRetry} />
      ) : !selectedAgent ? (
        <div className="text-center p-8 text-gray-500">
          Please select an agent to view details
        </div>
      ) : (
        <>
          {/* Header Section - Simplified */}
          <div className="mb-8">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-3xl font-bold text-gray-900 mb-2">
                  {selectedAgent.name}
                </h1>
                <p className="text-gray-500">
                  {selectedAgent.description || 'AI Agent Overview'}
                </p>
              </div>
              <div className="flex items-center space-x-3">
                <motion.div
                  className="w-3 h-3 rounded-full bg-green-500"
                  animate={{
                    scale: [1, 1.2, 1],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                  }}
                />
                <span className="text-sm font-medium text-gray-600">Active</span>
              </div>
            </div>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <StatsCard
              icon={<Database className="w-5 h-5 text-blue-600" />}
              title="Knowledge Graph"
              value={totalNodes}
              subtitle="total connected nodes"
              action={{
                label: "Explore Graph",
                onClick: handleExplorerOpen
              }}
            />
            <StatsCard
              icon={<FileText className="w-5 h-5 text-blue-600" />}
              title="Documents"
              value={stats.documents}
              subtitle="uploaded documents"
            />
          </div>

          {/* Agent Details Section */}
          <div className="space-y-8">
            <SectionHeader 
              title="Agent Intelligence"
              subtitle="Overview of agent's knowledge and capabilities"
            />
            
            {/* Information Grid with responsive improvements */}
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
              <InfoCard
                icon={<Compass className="w-5 h-5 text-blue-600" />}
                title="Research Interests"
                loading={loading.graph}
                isEmpty={researchInterests.length === 0}
                emptyMessage="No research interests defined yet"
              >
                <div className="space-y-1 max-h-[300px] overflow-y-auto" 
                     style={{
                       scrollbarWidth: 'thin',
                       scrollbarColor: '#ccc #f1f1f1',
                     }}>
                  {researchInterests.map((interest) => (
                    <InfoItem
                      key={interest.id}
                      name={interest.name}
                    />
                  ))}
                </div>
              </InfoCard>

              <InfoCard
                icon={<Target className="w-5 h-5 text-blue-600" />}
                title="Current Goals"
                isEmpty={goals.length === 0}
                emptyMessage="No goals set yet"
              >
                <div className="space-y-1 max-h-[300px] overflow-y-auto"
                     style={{
                       scrollbarWidth: 'thin',
                       scrollbarColor: '#ccc #f1f1f1',
                     }}>
                  {goals.map((goal) => (
                    <InfoItem
                      key={goal.id}
                      name={goal.name}
                      status={goal.metadata?.status || 'active'}
                    />
                  ))}
                </div>
              </InfoCard>

              <InfoCard
                icon={<Map className="w-5 h-5 text-blue-600" />}
                title="Learning Roadmap"
                isEmpty={roadmap.length === 0}
                emptyMessage="No roadmap items defined"
              >
                <div className="space-y-1 max-h-[300px] overflow-y-auto"
                     style={{
                       scrollbarWidth: 'thin',
                       scrollbarColor: '#ccc #f1f1f1',
                     }}>
                  {roadmap.map((item) => (
                    <InfoItem
                      key={item.id}
                      name={item.name}
                      status={item.metadata?.status || 'pending'}
                    />
                  ))}
                </div>
              </InfoCard>
            </div>

            {/* Responsive Modal */}
            <AnimatePresence>
              {isExplorerOpen && selectedAgent && (
                <div className="fixed inset-0 z-[9999]">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-black/50 backdrop-blur-sm"
                  />
                  <div className="fixed inset-0 flex items-center justify-center p-4">
                    <motion.div
                      initial={{ scale: 0.95, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.95, opacity: 0 }}
                      className="bg-white rounded-xl w-full max-w-7xl h-[90vh] flex flex-col shadow-2xl relative overflow-hidden"
                    >
                      <div className="flex items-center justify-between p-4 md:p-6 border-b">
                        <div>
                          <h2 className="text-xl md:text-2xl font-bold text-gray-900">
                            Knowledge Graph Explorer
                          </h2>
                          <p className="text-sm text-gray-500 mt-1">
                            Visualizing {selectedAgent.name}'s knowledge network
                          </p>
                        </div>
                        <button
                          onClick={() => setIsExplorerOpen(false)}
                          className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                          aria-label="Close modal"
                        >
                          <X className="w-6 h-6 text-gray-500" />
                        </button>
                      </div>
                      <div className="flex-grow relative">
                        {loading.graph ? (
                          <LoadingSpinner message="Loading knowledge graph..." />
                        ) : (
                          <KnowledgeGraphExplorer 
                            selectedPersona={adaptAgentToPersona(selectedAgent)}
                            width={window.innerWidth * 0.9}
                            height={window.innerHeight * 0.8}
                          />
                        )}
                      </div>
                    </motion.div>
                  </div>
                </div>
              )}
            </AnimatePresence>
          </div>
        </>
      )}
    </div>
  );
};

export default AgentOverview;
