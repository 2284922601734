import React, { useState } from 'react';
import { Clock, CheckCircle, AlertCircle, Pause } from 'lucide-react';

interface Task {
  id: string;
  title: string;
  status: 'ongoing' | 'scheduled' | 'completed';
  progress?: number;
  dueDate?: Date;
  priority: 'high' | 'medium' | 'low';
  type: string;
}

const AgentTaskMonitor: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([
    {
      id: '1',
      title: 'Analyze market trends',
      status: 'ongoing',
      progress: 60,
      dueDate: new Date('2024-09-28'),
      priority: 'high',
      type: 'Analysis',
    },
    {
      id: '2',
      title: 'Generate quarterly report',
      status: 'scheduled',
      dueDate: new Date('2024-10-01'),
      priority: 'medium',
      type: 'Report',
    },
    {
      id: '3',
      title: 'Update customer database',
      status: 'completed',
      priority: 'low',
      type: 'Data Management',
    },
    {
      id: '4',
      title: 'Optimize supply chain',
      status: 'ongoing',
      progress: 30,
      dueDate: new Date('2024-09-30'),
      priority: 'high',
      type: 'Optimization',
    },
    {
      id: '5',
      title: 'Refine knowledge graph nodes',
      status: 'ongoing',
      progress: 45,
      priority: 'medium',
      type: 'Knowledge Graph',
    },
    {
      id: '6',
      title: 'Review procurement policies',
      status: 'scheduled',
      dueDate: new Date('2024-10-05'),
      priority: 'low',
      type: 'Procurement',
    },
    {
      id: '7',
      title: 'Customs compliance check',
      status: 'scheduled',
      dueDate: new Date('2024-10-03'),
      priority: 'high',
      type: 'Customs',
    },
  ]);

  const getStatusIcon = (status: Task['status']) => {
    switch (status) {
      case 'ongoing':
        return <Clock className="text-[#004DB5]" />;
      case 'scheduled':
        return <AlertCircle className="text-[#004DB5]" />;
      case 'completed':
        return <CheckCircle className="text-[#004DB5]" />;
    }
  };

  const handleInterrupt = (id: string) => {
    alert(`Task ${id} interrupted.`);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-4" style={{ color: '#004DB5' }}>
        Agent Task Monitor
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {tasks.map((task) => (
          <div key={task.id} className="bg-[#F5F5F5] rounded-lg p-4 shadow">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-semibold" style={{ color: '#555555' }}>
                {task.title}
              </h3>
              {getStatusIcon(task.status)}
            </div>
            <p className="text-sm" style={{ color: '#7D7D7D' }}>
              Status: {task.status}
            </p>
            <p className="text-sm" style={{ color: '#7D7D7D' }}>
              Type: {task.type}
            </p>
            <p className="text-sm" style={{ color: '#7D7D7D' }}>
              Priority: {task.priority}
            </p>
            {task.progress !== undefined && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                <div
                  className="bg-[#004DB5] h-2.5 rounded-full"
                  style={{ width: `${task.progress}%` }}
                ></div>
              </div>
            )}
            {task.dueDate && (
              <p className="text-sm" style={{ color: '#7D7D7D' }}>
                Due: {task.dueDate.toLocaleDateString()}
              </p>
            )}
            {task.status === 'ongoing' && (
              <button
                onClick={() => handleInterrupt(task.id)}
                className="flex items-center mt-4 px-3 py-1 bg-[#004DB5] text-white rounded-lg"
              >
                <Pause size={16} className="mr-1" /> Interrupt
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgentTaskMonitor;
