const AUTOBOT_AUTH0_DOMAIN_DEV = "dev-s2fu3yfjlgz57one.us.auth0.com";
const AUTOBOT_AUTH0_CLIENT_ID_DEV = "on7yemmTbkEmCK8jygyNlLXh34LhAmvx";

const AUTOBOT_AUTH0_DOMAIN_TEST = "test-brainchain.us.auth0.com";
const AUTOBOT_AUTH0_CLIENT_ID_TEST = "ya0rqWG1rT5rshIICNdteRsjo6T5CI1K";
const APP_DOMAIN_TEST = "https://autobot-ui-test.brainchain.cloud";

const AUTOBOT_AUTH0_DOMAIN_PROD = "brainchain.us.auth0.com";
const AUTOBOT_AUTH0_CLIENT_ID_PROD = "2mmYdNVPSsh6A9Kf8pjmPIPM3nwnAHO7";
const APP_DOMAIN_PROD = "https://autobot-ui.brainchain.cloud";

export const getAuth0DomainName = () => {
  if (window.location.origin === APP_DOMAIN_PROD)
    return AUTOBOT_AUTH0_DOMAIN_PROD;
  if (window.location.origin === APP_DOMAIN_TEST)
    return AUTOBOT_AUTH0_DOMAIN_TEST;
  return AUTOBOT_AUTH0_DOMAIN_DEV;
};

export const getAuth0ClientId = () => {
  if (window.location.origin === APP_DOMAIN_PROD)
    return AUTOBOT_AUTH0_CLIENT_ID_PROD;
  if (window.location.origin === APP_DOMAIN_TEST)
    return AUTOBOT_AUTH0_CLIENT_ID_TEST;
  return AUTOBOT_AUTH0_CLIENT_ID_DEV;
};
