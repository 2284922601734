import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { MessageProps } from '../../types/chat';
import { messageTypeStyles } from '../../types/chat';

// Add timestamp formatting function directly in the component
const formatTimestamp = (timestamp: string): string => {
  return new Date(timestamp).toLocaleTimeString();
};

// Add this helper function at the top
const formatMessageType = (type: string): string => {
  return type
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

interface ToolIndicatorProps {
  tool: {
    name: string;
    input?: string;
    output?: string;
    status?: string;
  };
}

// Memoize the ToolIndicator component
const ToolIndicator = memo(({ tool }: ToolIndicatorProps) => (
  <div className="bg-gray-100 rounded p-2 text-xs">
    <div className="font-medium text-gray-700 mb-1">{tool.name}</div>
    {tool.input && tool.input !== '{}' && (
      <div className="text-gray-600 mb-1">
        <span className="font-medium">Input:</span> 
        <pre className="mt-1 whitespace-pre-wrap">{tool.input}</pre>
      </div>
    )}
    {tool.output && tool.output !== '{}' && (
      <div className="text-gray-600">
        <span className="font-medium">Output:</span>
        <pre className="mt-1 whitespace-pre-wrap">{tool.output}</pre>
      </div>
    )}
    {tool.status && (
      <div className="text-gray-600 mt-1">
        <span className="font-medium">Status:</span> {tool.status}
      </div>
    )}
  </div>
));

// Update the cleanMarkdownContent function to preserve news content
const cleanMarkdownContent = (content: string): string => {
  // Keep all content intact, including images and links
  return content;
};

// Memoize the ImageRenderer component
const ImageRenderer = memo(({ src, alt }: { src: string; alt: string }) => (
  <img 
    src={src} 
    alt={alt} 
    className="max-w-full h-auto rounded-lg shadow-sm my-2"
    loading="lazy"
    onError={(e) => {
      console.warn('Failed to load image:', src);
      e.currentTarget.style.display = 'none';
    }}
  />
));

// Memoize the Message component
const Message = memo(({ message }: MessageProps) => {
  const getMessageStyle = () => {
    switch (message.type) {
      case 'goal':
        return messageTypeStyles.GOAL;
      case 'task':
        return messageTypeStyles.TASK;
      case 'command':
        return messageTypeStyles.COMMAND;
      case 'run':
        return messageTypeStyles.RUN;
      default:
        return messageTypeStyles.CHAT;
    }
  };

  return (
    <div className={`flex flex-col ${message.role === 'assistant' ? 'items-start' : 'items-end'}`}>
      <div className={`flex items-start gap-2 max-w-[80%] ${message.role === 'assistant' ? 'flex-row' : 'flex-row-reverse'}`}>
        <div className={`rounded-lg p-3 ${
          message.role === 'assistant' 
            ? 'bg-gray-50 border border-gray-200'
            : getMessageStyle()
        }`}>
          {/* Command Type Indicator */}
          {message.type && message.type !== 'TEXT' && (
            <div className="text-xs text-gray-500 mb-1">
              {formatMessageType(message.type)}
            </div>
          )}

          {/* Message Content with Markdown and Image Support */}
          <div className="prose max-w-none">
            <ReactMarkdown
              components={{
                img: ({ src, alt }) => (
                  <ImageRenderer src={src || ''} alt={alt || ''} />
                ),
                p: ({ children }) => (
                  <div className="mb-4">{children}</div>
                ),
                a: ({ href, children }) => (
                  <a 
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    {children}
                  </a>
                )
              }}
            >
              {cleanMarkdownContent(message.content)}
            </ReactMarkdown>
          </div>
          
          {/* Tool Usage Section */}
          {message.toolUsage && message.toolUsage.length > 0 && (
            <div className="mt-2 border-t border-gray-200 pt-2">
              <div className="text-xs font-medium text-gray-500 mb-2">
                {message.type === 'TOOL_LIST' ? 'Available Tools:' : 'Tools Used:'}
              </div>
              <div className="space-y-1">
                {message.toolUsage.map((tool, index) => (
                  <ToolIndicator key={`${tool.name}-${index}`} tool={tool} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="text-xs text-gray-500 mt-1">
        {formatTimestamp(message.timestamp)}
      </div>
    </div>
  );
});

export default Message;
