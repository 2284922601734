import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Upload, BarChart2, CheckCircle, AlertCircle } from 'lucide-react';

// Mock agents for the dropdown (these would align with your project's agents)
const agents = [
  { id: '1', name: 'Customs Broker' },
  { id: '2', name: 'Procurement Analyst' },
  { id: '3', name: 'Logistics Manager' },
];

// Mock data for recent test results
interface TestResult {
  id: string;
  name: string;
  score: number;
  date: string;
  agent: string;
}

const initialTestResults: TestResult[] = [
  { id: '1', name: 'Customs Broker Exam', score: 85, date: '2024-09-15', agent: 'Customs Broker' },
  { id: '2', name: 'Procurement Assessment', score: 78, date: '2024-09-16', agent: 'Procurement Analyst' },
  { id: '3', name: 'Logistics Efficiency Test', score: 92, date: '2024-09-17', agent: 'Logistics Manager' },
  { id: '4', name: 'Data Analysis Task', score: 88, date: '2024-09-18', agent: 'Customs Broker' },
  { id: '5', name: 'Customs Compliance Quiz', score: 76, date: '2024-09-19', agent: 'Customs Broker' },
];

const EvaluationDashboard: React.FC = () => {
  const [testResults, setTestResults] = useState<TestResult[]>(initialTestResults);
  const [newTestName, setNewTestName] = useState('');
  const [selectedAgent, setSelectedAgent] = useState(agents[0].id);
  const [testDescription, setTestDescription] = useState('');

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // In a real application, you would process the uploaded file here
    console.log('File uploaded:', event.target.files?.[0]?.name);
  };

  const addNewTest = () => {
    if (newTestName && selectedAgent) {
      const newTest: TestResult = {
        id: (testResults.length + 1).toString(),
        name: newTestName,
        score: Math.floor(Math.random() * 30) + 70, // Random score between 70 and 100
        date: new Date().toISOString().split('T')[0],
        agent: agents.find(agent => agent.id === selectedAgent)?.name || 'Unknown Agent',
      };
      setTestResults([...testResults, newTest]);
      setNewTestName('');
      setTestDescription('');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-4" style={{ color: '#004DB5' }}>
        Performance Evaluation Dashboard
      </h2>
      
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2" style={{ color: '#555555' }}>
          Performance Overview
        </h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={testResults}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="score" stroke="#004DB5" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2" style={{ color: '#555555' }}>
          Recent Test Results
        </h3>
        <div className="space-y-2">
          {testResults.slice(-5).reverse().map((test) => (
            <div key={test.id} className="flex items-center justify-between bg-[#F5F5F5] p-3 rounded-lg">
              <div>
                <span className="font-medium">{test.name}</span>
                <span className="text-sm text-gray-500 ml-2">({test.date})</span>
                <span className="text-sm text-gray-500 ml-2"> - {test.agent}</span>
              </div>
              <div className="flex items-center">
                <span className="font-bold mr-2" style={{ color: '#004DB5' }}>{test.score}%</span>
                {test.score >= 80 ? (
                  <CheckCircle className="text-[#004DB5]" size={20} />
                ) : (
                  <AlertCircle className="text-[#B2B2B2]" size={20} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2" style={{ color: '#555555' }}>
          Upload New Test
        </h3>
        <div className="flex flex-col space-y-4">
          {/* Agent selection dropdown */}
          <select
            value={selectedAgent}
            onChange={(e) => setSelectedAgent(e.target.value)}
            className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {agents.map(agent => (
              <option key={agent.id} value={agent.id}>
                {agent.name}
              </option>
            ))}
          </select>

          {/* Test name input */}
          <input
            type="text"
            placeholder="Enter test name"
            value={newTestName}
            onChange={(e) => setNewTestName(e.target.value)}
            className="flex-1 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          {/* Test description input */}
          <textarea
            placeholder="Enter test description"
            value={testDescription}
            onChange={(e) => setTestDescription(e.target.value)}
            className="flex-1 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          {/* File upload and add button */}
          <div className="flex items-center space-x-2">
            <label className="cursor-pointer bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600">
              <Upload size={20} className="inline mr-2" />
              Upload Test
              <input type="file" className="hidden" onChange={handleFileUpload} />
            </label>
            <span className="text-sm text-gray-500">or</span>
            <button
              onClick={addNewTest}
              className="bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Add Test
            </button>
          </div>
        </div>
      </div>
      
      <div>
        <h3 className="text-xl font-semibold mb-2" style={{ color: '#555555' }}>
          Benchmark Comparison
        </h3>
        <div className="bg-[#F5F5F5] p-4 rounded-lg">
          <p className="mb-2">
            <span className="font-medium">Average Score:</span> {(testResults.reduce((sum, test) => sum + test.score, 0) / testResults.length).toFixed(1)}%
          </p>
          <p>
            <span className="font-medium">Benchmark (ChatGPT):</span> 82%
          </p>
        </div>
      </div>
    </div>
  );
};

export default EvaluationDashboard;
