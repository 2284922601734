import React, { useState, useCallback, useEffect, useRef } from 'react';
import ForceGraph2D, { ForceGraphMethods } from 'react-force-graph-2d';
import { useAgentContext } from '../../context/AgentContext';
import axios from 'axios';
import * as d3 from 'd3';

// Define interfaces for the data structures used in the component
interface GraphNode {
  id: string;
  name: string;
  group: string;
  val: number;
  agentId: string;
  parentId?: string;
}

interface Link {
  source: string | GraphNode;
  target: string | GraphNode;
}

interface GraphData {
  nodes: GraphNode[];
  links: Link[];
}

// Extend GraphNode with D3 simulation properties
type ExtendedGraphNode = GraphNode & d3.SimulationNodeDatum;

// API endpoint for fetching agent data
const API_BASE_URL = 'https://graph-test.brainchain.cloud/api/v1';

// Add interfaces for saved state
interface SavedGraphState {
  selectedNodeTypes: string[];
  camera: {
    x: number;
    y: number;
    zoom: number;
  };
  selectedNode: GraphNode | null;
  lastUpdated: string;
}

// Add storage keys
const STORAGE_KEYS = {
  graphData: 'knowledge_graph_data',
  filters: 'knowledge_graph_filters',
  lastAgent: 'knowledge_graph_last_agent'
};

const KnowledgeGraph: React.FC = () => {
  // Get personas from AgentContext
  const { selectedAgent, liveAgents } = useAgentContext();
  
  // Initialize state from localStorage
  const [graphData, setGraphData] = useState<GraphData>(() => {
    const savedData = localStorage.getItem(STORAGE_KEYS.graphData);
    const savedAgent = localStorage.getItem(STORAGE_KEYS.lastAgent);
    
    // Only restore if it's the same agent
    if (savedData && savedAgent === selectedAgent?.id) {
      try {
        return JSON.parse(savedData);
      } catch (error) {
        console.error('Failed to parse saved graph data:', error);
      }
    }
    return { nodes: [], links: [] };
  });

  const [selectedNodeTypes, setSelectedNodeTypes] = useState<string[]>(() => {
    const savedFilters = localStorage.getItem(STORAGE_KEYS.filters);
    if (savedFilters) {
      try {
        return JSON.parse(savedFilters);
      } catch (error) {
        console.error('Failed to parse saved filters:', error);
      }
    }
    return ['agent'];
  });

  const [fullGraphData, setFullGraphData] = useState<GraphData>({ nodes: [], links: [] });
  const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  
  // Add a new state to track if initial data loading is complete
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  
  // Ref for the ForceGraph2D component
  const graphRef = useRef<ForceGraphMethods<GraphNode, Link>>();

  // Function to fetch agent data from the API
  const fetchAgentData = useCallback(async (id: string) => {
    try {
      console.log(`Fetching data for agent: ${id}`);
      const response = await axios.get(`${API_BASE_URL}/node/Agent?id=${id}&connected=true`);
      console.log(`Data received for agent ${id}:`, response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching agent data:', error);
      return null;
    }
  }, []);

  // Function to prepare graph data based on live agents
  const prepareGraphData = useCallback(async () => {
    console.log('Preparing graph data for live agents:', liveAgents);
    setIsLoading(true);
    const nodes: GraphNode[] = [];
    const links: Link[] = [];

    // Process all live agents
    for (const agent of liveAgents) {
      // Fetch data for each agent
      const agentData = await fetchAgentData(agent.id);
      if (!agentData || !agentData.node || !agentData.connected_nodes) {
        console.warn('Invalid API response structure for agent:', agent.id);
        continue;
      }

      // Create agent node
      const agentNode: GraphNode = {
        id: agentData.node.id || agent.id,
        name: agent.name,
        group: 'agent',
        val: 30,
        agentId: agent.id
      };
      nodes.push(agentNode);

      // Create category nodes and connect them to agent
      const categories = [
        'project', 'goals', 'system_prompt', 'research_interests', 'user_intents', 
        'tools', 'agent_scratchpad', 'workspace_directory', 'sentiment', 
        'impliedintent', 'knowledge_base', 'mailbox', 'other'
      ];
      
      // Create category nodes first
      const categoryNodes = categories.map(category => ({
        id: `${agent.id}_category_${category}`,
        name: category.replace('_', ' ').charAt(0).toUpperCase() + category.replace('_', ' ').slice(1),
        group: category,
        val: 20,
        agentId: agent.id,
        parentId: agentNode.id
      }));
      nodes.push(...categoryNodes);
      categoryNodes.forEach(categoryNode => {
        links.push({ source: agentNode.id, target: categoryNode.id });
      });

      // Process connected nodes from Neo4j
      const processedNodes = new Set(); // Track processed nodes to avoid duplicates
      agentData.connected_nodes.forEach((connectedNode: any) => {
        if (!connectedNode || !connectedNode.element_id || !connectedNode.labels) {
          console.warn('Invalid connected node:', connectedNode);
          return;
        }

        // Skip if we've already processed this node
        const nodeId = `${agent.id}_${connectedNode.element_id}`;
        if (processedNodes.has(nodeId)) return;
        processedNodes.add(nodeId);

        // Determine category based on node labels
        let category = 'other';
        const nodeType = connectedNode.labels[0]?.toLowerCase();
        
        switch (nodeType) {
          case 'goal':
          case 'goals': 
            category = 'goals'; 
            break;
          case 'project':
          case 'projects': 
            category = 'project'; 
            break;
          case 'systemprompt':
          case 'system_prompt': 
            category = 'system_prompt'; 
            break;
          case 'researchinterest':
          case 'research_interest':
          case 'research_interests': 
            category = 'research_interests'; 
            break;
          case 'userintent':
          case 'user_intent':
          case 'user_intents': 
            category = 'user_intents'; 
            break;
          case 'tool':
          case 'tools': 
            category = 'tools'; 
            break;
          case 'agentscratchpad':
          case 'agent_scratchpad': 
            category = 'agent_scratchpad'; 
            break;
          case 'workspacedirectory':
          case 'workspace_directory': 
            category = 'workspace_directory'; 
            break;
          case 'sentiment': 
            category = 'sentiment'; 
            break;
          case 'impliedintent':
          case 'implied_intent': 
            category = 'impliedintent'; 
            break;
          case 'knowledgebase':
          case 'knowledge_base': 
            category = 'knowledge_base'; 
            break;
          case 'mailbox': 
            category = 'mailbox'; 
            break;
          default:
            console.debug('Unknown node type:', nodeType);
            category = 'other';
        }

        // Create node and link to its category
        const node: GraphNode = {
          id: nodeId,
          name: connectedNode.properties?.name || 'Unnamed Node',
          group: category,
          val: 15,
          agentId: agent.id,
          parentId: `${agent.id}_category_${category}`
        };
        nodes.push(node);
        links.push({ source: `${agent.id}_category_${category}`, target: node.id });
      });
    }

    console.log('Prepared graph data:', { 
      nodes: nodes.length, 
      links: links.length,
      nodeGroups: Array.from(new Set(nodes.map(n => n.group))),
      timestamp: new Date().toISOString()
    });
    
    setFullGraphData({ nodes, links });
    setInitialLoadComplete(true);
    setIsLoading(false);
  }, [liveAgents, fetchAgentData]);

  // Function to update visible nodes based on selected node types
  const updateVisibleNodes = useCallback((selectedTypes: string[]) => {
    console.log('Updating visible nodes for types:', selectedTypes);
    
    if (!fullGraphData.nodes || !fullGraphData.links) {
      console.log('No graph data available yet');
      return;
    }

    // Get all agent nodes
    const agentNodes = fullGraphData.nodes.filter(node => node.group === 'agent');
    
    // Get category nodes for selected types
    const categoryNodes = fullGraphData.nodes.filter(node => 
      selectedTypes.includes(node.group) && node.parentId?.includes('category')
    );
    
    // Get child nodes for selected categories
    const childNodes = fullGraphData.nodes.filter(node => 
      selectedTypes.includes(node.group) && !node.parentId?.includes('category')
    );

    // Combine all visible nodes
    const visibleNodes = [...agentNodes, ...categoryNodes, ...childNodes];
    const visibleNodeIds = new Set(visibleNodes.map(node => node.id));

    // Filter links to maintain hierarchy
    const visibleLinks = fullGraphData.links.filter(link => {
      if (!link || !link.source || !link.target) return false;

      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      
      if (!sourceId || !targetId) return false;

      // Keep links between visible nodes
      return visibleNodeIds.has(sourceId) && visibleNodeIds.has(targetId);
    });

    console.log('Updated graph data:', { 
      nodes: visibleNodes.length, 
      links: visibleLinks.length,
      agentCount: agentNodes.length,
      categoryCount: categoryNodes.length,
      childCount: childNodes.length
    });
    
    setGraphData({ nodes: visibleNodes, links: visibleLinks });
  }, [fullGraphData]);

  // Effect to update graph data when live agents change
  useEffect(() => {
    console.log('Live agents changed, preparing graph data');
    prepareGraphData();
  }, [prepareGraphData, liveAgents]);

  // Effect to update visible nodes when selected node types change
  useEffect(() => {
    console.log('Selected node types changed:', selectedNodeTypes);
    if (fullGraphData.nodes.length > 0) {
      updateVisibleNodes(selectedNodeTypes);
    }
  }, [updateVisibleNodes, selectedNodeTypes, fullGraphData.nodes.length]);

  // Handler for node click events
  const handleNodeClick = useCallback((node: GraphNode) => {
    console.log('Node clicked:', node);
    setSelectedNode(node);
  }, []);

  // Function to get color for each node group
  const getNodeColor = (group: string) => {
    switch (group) {
      case 'agent': return '#004DB5';
      case 'project': return '#00A86B';
      case 'goals': return '#FFA500';
      case 'system_prompt': return '#8A2BE2';
      case 'research_interests': return '#FF4500';
      case 'user_intents': return '#1E90FF';
      case 'tools': return '#FF6347';
      case 'agent_scratchpad': return '#20B2AA';
      case 'workspace_directory': return '#DAA520';
      case 'sentiment': return '#FF1493';
      case 'impliedintent': return '#00CED1';
      case 'knowledge_base': return '#32CD32';
      case 'mailbox': return '#BA55D3';
      default: return '#7D7D7D';
    }
  };

  // Function to wrap text for node labels
  const wrapText = (context: CanvasRenderingContext2D, text: string, maxWidth: number) => {
    if (!text) return [''];
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = context.measureText(currentLine + " " + word).width;
      if (width < maxWidth * 4.5) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  // Update the force simulation parameters
  useEffect(() => {
    if (graphRef.current && graphData.nodes.length > 0) {
      const fg = graphRef.current;
      const simulation = d3.forceSimulation<ExtendedGraphNode>(graphData.nodes as ExtendedGraphNode[]);

      // Create forces with better spacing
      const chargeForce = d3.forceManyBody()
        .strength((d) => {
          const node = d as ExtendedGraphNode;
          // Stronger repulsion for agent nodes
          if (node.group === 'agent') return -2000;
          // Medium repulsion for category nodes
          if (node.parentId?.includes('category')) return -500;
          // Light repulsion for leaf nodes
          return -200;
        });

      const linkForce = d3.forceLink<ExtendedGraphNode, d3.SimulationLinkDatum<ExtendedGraphNode>>(
        graphData.links.map(link => ({
          source: typeof link.source === 'string' ? link.source : link.source.id,
          target: typeof link.target === 'string' ? link.target : link.target.id
        }))
      )
        .id((d) => d.id)
        .distance((link) => {
          const source = link.source as ExtendedGraphNode;
          const target = link.target as ExtendedGraphNode;
          // Longer distance for agent-category connections
          if (source.group === 'agent' || target.group === 'agent') return 300;
          // Medium distance for category-leaf connections
          return 100;
        });

      simulation
        .force('charge', chargeForce)
        .force('link', linkForce)
        .force('center', d3.forceCenter().strength(0.05))
        .force('x', d3.forceX().strength(0.1))
        .force('y', d3.forceY().strength(0.1));

      simulation.alpha(1).restart();

      fg.d3Force('charge', chargeForce as any);
      fg.d3Force('link', linkForce as any);
      fg.d3Force('center', simulation.force('center') as any);
      fg.d3Force('x', simulation.force('x') as any);
      fg.d3Force('y', simulation.force('y') as any);
    }
  }, [graphData]);

  // Handler for node type filter changes
  const handleNodeTypeChange = (type: string) => {
    if (type === 'agent') return; // Prevent deselection of 'agent'
    console.log('Node type filter changed:', type);
    setSelectedNodeTypes(prev => {
      const newTypes = prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type];
      console.log('New selected node types:', newTypes);
      return newTypes;
    });
    setIsLoading(true);
  };

  // Loading Spinner Component
  const LoadingSpinner = () => (
    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
    </div>
  );

  // State for graph dimensions
  const [graphDimensions, setGraphDimensions] = useState({ width: 0, height: 0 });
  const graphContainerRef = useRef<HTMLDivElement>(null);

  // Effect to handle graph resizing
  useEffect(() => {
    const updateDimensions = () => {
      if (graphContainerRef.current) {
        setGraphDimensions({
          width: graphContainerRef.current.offsetWidth,
          height: graphContainerRef.current.offsetHeight,
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // Function to restart the force simulation
  const restartSimulation = useCallback(() => {
    console.log('Restarting force simulation');
    if (graphRef.current) {
      const fg = graphRef.current;
      fg.d3ReheatSimulation();
      fg.d3Force('link')?.distance((link: any) => {
        const source = link.source as ExtendedGraphNode;
        const target = link.target as ExtendedGraphNode;
        return source.group === 'agent' || target.group === 'agent' ? 200 : 50;
      });
    }
  }, []);

  // Effect to update visible nodes and restart simulation when filters change
  useEffect(() => {
    console.log('Updating visible nodes and restarting simulation');
    updateVisibleNodes(selectedNodeTypes);
    restartSimulation();
  }, [updateVisibleNodes, selectedNodeTypes, restartSimulation]);

  // Update the effect that handles data loading and filtering
  useEffect(() => {
    console.log('Graph data or node types changed:', {
      nodeCount: fullGraphData.nodes.length,
      selectedTypes: selectedNodeTypes,
      selectedAgent: selectedAgent?.name,
      timestamp: new Date().toISOString()
    });

    if (fullGraphData.nodes.length > 0) {
      // First, get all agent nodes
      const agentNodes = fullGraphData.nodes.filter(node => node.group === 'agent');
      
      // Then get category nodes for selected types
      const categoryNodes = fullGraphData.nodes.filter(node => 
        selectedNodeTypes.includes(node.group) && node.parentId?.includes('category')
      );
      
      // Finally get child nodes for selected categories
      const childNodes = fullGraphData.nodes.filter(node => 
        selectedNodeTypes.includes(node.group) && !node.parentId?.includes('category')
      );

      // Combine all visible nodes
      const visibleNodes = [...agentNodes, ...categoryNodes, ...childNodes];
      const visibleNodeIds = new Set(visibleNodes.map(node => node.id));

      // Update visible links
      const visibleLinks = fullGraphData.links.filter(link => {
        const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target.id;
        
        return visibleNodeIds.has(sourceId) && visibleNodeIds.has(targetId);
      });

      console.log('Updated visible graph data:', {
        agentNodes: agentNodes.length,
        categoryNodes: categoryNodes.length,
        childNodes: childNodes.length,
        totalNodes: visibleNodes.length,
        totalLinks: visibleLinks.length
      });

      setGraphData({ nodes: visibleNodes, links: visibleLinks });
      
      // Reset graph zoom after data changes
      if (graphRef.current) {
        setTimeout(() => {
          graphRef.current?.zoomToFit(400, 50);
        }, 500);
      }
    }
  }, [fullGraphData, selectedNodeTypes, selectedAgent]);

  // Update the effect that handles graph data persistence
  useEffect(() => {
    if (graphRef.current && graphData.nodes.length > 0) {
      const fg = graphRef.current;
      
      // Save current state
      const currentState: SavedGraphState = {
        selectedNodeTypes,
        camera: {
          x: 0,  // We'll use default position
          y: 0,  // We'll use default position
          zoom: fg.zoom()
        },
        selectedNode,
        lastUpdated: new Date().toISOString()
      };

      // Save to localStorage
      localStorage.setItem('graph_state', JSON.stringify(currentState));

      // Log state save
      console.log('Graph state saved:', {
        nodeCount: graphData.nodes.length,
        selectedTypes: selectedNodeTypes,
        timestamp: currentState.lastUpdated
      });
    }
  }, [graphData, selectedNodeTypes, selectedNode]);

  // Update the camera state handling effect
  useEffect(() => {
    if (graphRef.current && graphData.nodes.length > 0) {
      const fg = graphRef.current;
      const storageKey = `graph_camera_${selectedAgent?.id}`;
      
      try {
        // Try to restore saved camera position
        const savedCamera = localStorage.getItem(storageKey);
        if (savedCamera) {
          const { zoom } = JSON.parse(savedCamera);
          
          // Schedule zoom restoration after render
          setTimeout(() => {
            fg.zoom(zoom);
            fg.zoomToFit(400, 50); // This will center the graph
          }, 500);
        }
      } catch (error) {
        console.error('Failed to handle camera state:', error);
      }
    }
  }, [graphData.nodes.length, selectedAgent?.id]);

  // Save graph data when it changes
  useEffect(() => {
    if (graphData.nodes.length > 0 && selectedAgent?.id) {
      localStorage.setItem(STORAGE_KEYS.graphData, JSON.stringify(graphData));
      localStorage.setItem(STORAGE_KEYS.lastAgent, selectedAgent.id);
    }
  }, [graphData, selectedAgent?.id]);

  // Save filters when they change
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.filters, JSON.stringify(selectedNodeTypes));
  }, [selectedNodeTypes]);

  // Clear saved data when agent changes
  useEffect(() => {
    if (selectedAgent?.id) {
      const savedAgent = localStorage.getItem(STORAGE_KEYS.lastAgent);
      if (savedAgent !== selectedAgent.id) {
        localStorage.removeItem(STORAGE_KEYS.graphData);
        localStorage.setItem(STORAGE_KEYS.lastAgent, selectedAgent.id);
        setGraphData({ nodes: [], links: [] });
        setFullGraphData({ nodes: [], links: [] });
        prepareGraphData();
      }
    }
  }, [selectedAgent?.id]);

  return (
    <div className="w-full h-full relative">
      {/* Node type filter buttons */}
      <div className="mb-4 p-4 bg-white rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Node Type Filters</h3>
        <div className="flex flex-wrap gap-2">
          {['agent', 'project', 'goals', 'system_prompt', 'research_interests', 'user_intents', 
            'tools', 'agent_scratchpad', 'workspace_directory', 'sentiment', 
            'impliedintent', 'knowledge_base', 'mailbox', 'other'].map(type => (
            <button
              key={type}
              onClick={() => handleNodeTypeChange(type)}
              className={`px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
                type === 'agent' ? 'bg-[#004DB5] text-white cursor-default' :
                selectedNodeTypes.includes(type)
                  ? `bg-[${getNodeColor(type)}] text-white`
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
              style={{
                backgroundColor: type === 'agent' ? '#004DB5' : 
                  selectedNodeTypes.includes(type) ? getNodeColor(type) : undefined,
                color: type === 'agent' || selectedNodeTypes.includes(type) ? '#FFFFFF' : undefined,
              }}
              disabled={type === 'agent'}
            >
              {type.replace('_', ' ').charAt(0).toUpperCase() + type.replace('_', ' ').slice(1)}
            </button>
          ))}
        </div>
      </div>
      {/* Force-directed graph */}
      <div ref={graphContainerRef} className="w-full h-[700px] bg-[#d4d4d4] rounded-lg shadow-lg relative overflow-hidden">
        {(isLoading || !initialLoadComplete) && <LoadingSpinner />}
        <ForceGraph2D
          ref={graphRef}
          width={graphDimensions.width}
          height={graphDimensions.height}
          graphData={graphData}
          nodeLabel="name"
          nodeColor={(node) => getNodeColor(node.group)}
          nodeCanvasObject={(node, ctx, globalScale) => {
            // Custom node rendering
            const label = node.name || 'Unnamed Node';
            const fontSize = node.group === 'agent' ? 16 / globalScale : 12 / globalScale;
            ctx.font = `${node.group === 'agent' ? 'bold' : ''} ${fontSize}px Roboto, sans-serif`;

            const nodeSize = node.group === 'agent' ? 30 : (node.parentId?.includes('category') ? 15 : 10);
            ctx.beginPath();
            ctx.arc(node.x ?? 0, node.y ?? 0, nodeSize, 0, 2 * Math.PI);
            ctx.fillStyle = getNodeColor(node.group);
            ctx.fill();

            // Add a white halo effect for agent nodes
            if (node.group === 'agent') {
              ctx.strokeStyle = '#FFFFFF';
              ctx.lineWidth = 3 / globalScale;
              ctx.stroke();
            } else {
              ctx.strokeStyle = '#FFFFFF';
              ctx.lineWidth = 1.5 / globalScale;
              ctx.stroke();
            }

            // Text rendering with improved visibility for agent nodes
            if (node.group === 'agent') {
              // Draw text shadow/outline for better contrast
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = '#000000'; // Black outline
              const outlineWidth = 3;
              for (let i = -outlineWidth; i <= outlineWidth; i++) {
                for (let j = -outlineWidth; j <= outlineWidth; j++) {
                  ctx.fillText(label, (node.x ?? 0) + i, (node.y ?? 0) + j);
                }
              }
              // Draw the actual text in white
              ctx.fillStyle = '#FFFFFF';
            } else {
              // Regular node text
              ctx.fillStyle = '#FFFFFF';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
            }

            const maxWidth = node.group === 'agent' ? 120 / globalScale : 60 / globalScale;
            const lines = wrapText(ctx, label, maxWidth);

            const lineHeight = fontSize * 1.2;
            lines.forEach((line, i) => {
              const yOffset = (i - (lines.length - 1) / 2) * lineHeight;
              ctx.fillText(line, node.x ?? 0, (node.y ?? 0) + yOffset);
            });
          }}
          nodeCanvasObjectMode={() => 'replace'}
          onNodeClick={handleNodeClick}
          linkColor={() => 'rgba(0,0,0,0.2)'}
          linkWidth={1}
          d3VelocityDecay={0.3}
          cooldownTicks={100}
          onEngineStop={() => {
            if (graphRef.current) {
              const fg = graphRef.current;
              const currentZoom = fg.zoom();
              
              // Save camera state
              localStorage.setItem(`graph_camera_${selectedAgent?.id}`, JSON.stringify({
                x: 0,  // We'll use default position
                y: 0,  // We'll use default position
                zoom: currentZoom,
                timestamp: new Date().toISOString()
              }));
            }
            
            // Zoom to fit and set loading state
            graphRef.current?.zoomToFit(400, 50);
            setIsLoading(false);
          }}
        />
      </div>
      {/* Selected node information panel */}
      {selectedNode && (
        <div className="absolute top-4 right-4 bg-white p-4 rounded-lg shadow-lg max-w-md">
          <h3 className="text-lg font-semibold">{selectedNode.name}</h3>
          <p>Group: {selectedNode.group}</p>
          <p>Agent: {liveAgents.find(p => p.id === selectedNode.agentId)?.name}</p>
          <button 
            onClick={() => setSelectedNode(null)}
            className="mt-2 bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200"
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default KnowledgeGraph;