import React, { useState, useEffect } from 'react';
import { Plus, Check, X, Wrench, Search, Settings, AlertCircle } from 'lucide-react';
import toast from 'react-hot-toast';

// Define the tool item structure
interface ToolItem {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  version: string;
  type: 'javascript' | 'python' | 'http';
  lastUsed?: string;
  settings?: {
    apiKey?: string;
    endpoint?: string;
    config?: Record<string, any>;
  };
}

// Define the new tool form structure
interface NewToolForm {
  name: string;
  description: string;
  apiKey?: string;
  productName?: string;
  isComplex: boolean;
  type?: 'javascript' | 'python' | 'http';
}

// Add stats interface
interface ToolStats {
  total: number;
  active: number;
}

// Add new interface for settings modal
interface SettingsModalProps {
  tool: ToolItem | null;
  isOpen: boolean;
  onClose: () => void;
  onSave: (toolId: string, settings: any) => void;
}

// Add API base URL and client functions at the top of the file
const API_BASE_URL = 'https://tools-test.brainchain.cloud';

// API client functions
const toolsApi = {
  // Get all tools
  async listTools() {
    console.group('API - List Tools');
    try {
      const response = await fetch(`${API_BASE_URL}/tools`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });

      if (!response.ok) throw new Error('Failed to fetch tools');
      
      const data = await response.json();
      console.log('Tools fetched:', data);
      return data;
    } catch (error) {
      console.error('Error fetching tools:', error);
      throw error;
    } finally {
      console.groupEnd();
    }
  },

  // Create new tool
  async createTool(toolData: {
    name: string;
    description: string;
    input_schema: any;
    output_schema: any;
    action: {
      type: string;
      javascript?: { code: string; function_name: string };
      python?: { code: string; function_name: string };
      http?: { method: string; url: string; headers?: Record<string, string>; body?: any };
    };
    output: {
      type: string;
      content: string;
    };
    user_settings?: Array<{
      name: string;
      label: string;
      type: string;
      description?: string;
      required?: boolean;
      placeholder?: string;
      values?: string[];
    }>;
  }) {
    console.group('API - Create Tool');
    try {
      const response = await fetch(`${API_BASE_URL}/tools`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(toolData)
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.detail || 'Failed to create tool');
      }

      const data = await response.json();
      console.log('Tool created:', data);
      return data;
    } catch (error) {
      console.error('Error creating tool:', error);
      throw error;
    } finally {
      console.groupEnd();
    }
  },

  // Update tool status
  async updateTool(toolId: string, toolData: any) {
    console.group('API - Update Tool');
    try {
      const response = await fetch(`${API_BASE_URL}/tools/${toolId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(toolData)
      });

      if (!response.ok) throw new Error('Failed to update tool');
      
      const data = await response.json();
      console.log('Tool updated:', data);
      return data;
    } catch (error) {
      console.error('Error updating tool:', error);
      throw error;
    } finally {
      console.groupEnd();
    }
  },

  // Delete tool
  async deleteTool(toolId: string) {
    console.group('API - Delete Tool');
    try {
      const response = await fetch(`${API_BASE_URL}/tools/${toolId}`, {
        method: 'DELETE'
      });

      if (!response.ok) throw new Error('Failed to delete tool');
      
      console.log('Tool deleted:', toolId);
    } catch (error) {
      console.error('Error deleting tool:', error);
      throw error;
    } finally {
      console.groupEnd();
    }
  }
};

// Add loading states interface
interface LoadingStates {
  list: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
}

const ToolManagement: React.FC = () => {
  // Initial list of tools with mock integrations for user tools
  const [tools, setTools] = useState<ToolItem[]>([
    { id: '1', name: 'Google Sheets', description: 'Sync data with Google Sheets', isActive: true, version: '1.0', type: 'javascript' },
    { id: '2', name: 'Slack', description: 'Send notifications to Slack channels', isActive: true, version: '2.0', type: 'python' },
    { id: '3', name: 'Salesforce', description: 'Manage CRM data and operations', isActive: false, version: '3.0', type: 'http' },
    { id: '4', name: 'Code Editing', description: 'Modify and edit code snippets', isActive: true, version: '1.5', type: 'javascript' }, // Agent-only
    { id: '5', name: 'Jupyter Console', description: 'Run Python scripts in a Jupyter-like environment', isActive: true, version: '2.0', type: 'python' }, // Agent-only
    { id: '6', name: 'Terminal Emulator', description: 'Execute shell commands in a virtual terminal', isActive: false, version: '1.0', type: 'javascript' }, // Agent-only
    { id: '7', name: 'Web Browsing', description: 'Enable agent to browse the web autonomously', isActive: false, version: '1.0', type: 'javascript' }, // Agent-only
    { id: '8', name: 'Custom API', description: 'User-defined API integration', isActive: true, version: '1.0', type: 'http' },
  ]);

  // State for adding new tools
  const [newTool, setNewTool] = useState<NewToolForm>({
    name: '',
    description: '',
    isComplex: false,
    apiKey: '',
    productName: '',
  });

  // Toggle the active status of a tool
  const toggleToolStatus = async (id: string) => {
    setLoading(prev => ({ ...prev, update: true }));
    setError(null);

    const tool = tools.find(t => t.id === id);
    if (!tool) return;

    try {
      await toolsApi.updateTool(id, {
        ...tool,
        isActive: !tool.isActive
      });

      setTools(tools.map(tool =>
        tool.id === id ? { ...tool, isActive: !tool.isActive } : tool
      ));
    } catch (err) {
      console.error('Failed to update tool:', err);
      setError('Failed to update tool status. Please try again.');
    } finally {
      setLoading(prev => ({ ...prev, update: false }));
    }
  };

  // Add form validation
  const [formErrors, setFormErrors] = useState<{
    name?: string;
    description?: string;
    type?: string;
  }>({});

  // Enhance addNewTool with validation
  const addNewTool = async () => {
    const errors: typeof formErrors = {};
    
    if (!newTool.name) errors.name = 'Tool name is required';
    if (!newTool.description) errors.description = 'Description is required';
    if (!newTool.type) errors.type = 'Tool type is required';

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // Create settings object that matches the interface
    const toolSettings = newTool.isComplex ? {
      apiKey: newTool.apiKey,
      endpoint: '',  // Add required field
      config: {      // Add required field
        productName: newTool.productName
      }
    } : undefined;

    setLoading(prev => ({ ...prev, create: true }));
    setError(null);

    try {
      const toolData = {
        name: newTool.name,
        description: newTool.description,
        input_schema: {},  // You might want to add this to the form
        output_schema: {}, // You might want to add this to the form
        action: {
          type: newTool.type || 'javascript',
          [newTool.type || 'javascript']: {
            code: '',     // You might want to add code editor
            function_name: 'execute'
          }
        },
        output: {
          type: 'ai',
          content: ''
        },
        user_settings: newTool.isComplex ? [
          {
            name: 'apiKey',
            label: 'API Key',
            type: 'text',
            description: 'API Key for authentication',
            required: true
          },
          {
            name: 'productName',
            label: 'Product Name',
            type: 'text',
            description: 'Name of the product',
            required: true
          }
        ] : []
      };

      const createdTool = await toolsApi.createTool(toolData);
      
      // Add the new tool to state
      setTools(prev => [...prev, {
        id: createdTool.id,
        name: createdTool.name,
        description: createdTool.description,
        isActive: true,
        version: createdTool.version || '1.0',
        type: createdTool.action.type as 'javascript' | 'python' | 'http',
        settings: toolData.user_settings?.reduce((acc: any, setting: any) => {
          acc[setting.name] = '';
          return acc;
        }, {})
      }]);

      // Reset form
      setNewTool({
        name: '',
        description: '',
        isComplex: false,
        apiKey: '',
        productName: '',
        type: undefined
      });
      setFormErrors({});
    } catch (err) {
      console.error('Failed to create tool:', err);
      setError('Failed to create tool. Please try again.');
    } finally {
      setLoading(prev => ({ ...prev, create: false }));
    }
  };

  // Add search state
  const [searchTerm, setSearchTerm] = useState('');

  // Add filter state
  const [filters, setFilters] = useState({
    type: 'all',
    status: 'all'
  });

  // Add settings modal state
  const [selectedTool, setSelectedTool] = useState<ToolItem | null>(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // Filter tools based on search and filters
  const filteredTools = tools.filter(tool => {
    const matchesSearch = tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         tool.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = filters.type === 'all' || tool.type === filters.type;
    const matchesStatus = filters.status === 'all' || 
                         (filters.status === 'active' && tool.isActive) ||
                         (filters.status === 'inactive' && !tool.isActive);
    
    return matchesSearch && matchesType && matchesStatus;
  });

  // Add stats calculation
  const stats: ToolStats = {
    total: tools.length,
    active: tools.filter(tool => tool.isActive).length
  };

  // Add filter controls section after the stats overview
  const renderFilterControls = () => (
    <div className="mb-6 flex flex-wrap gap-4">
      {/* Type Filter */}
      <div className="flex-1 min-w-[200px]">
        <select
          value={filters.type}
          onChange={(e) => setFilters(prev => ({ ...prev, type: e.target.value }))}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Types</option>
          <option value="javascript">JavaScript</option>
          <option value="python">Python</option>
          <option value="http">HTTP</option>
        </select>
      </div>

      {/* Status Filter */}
      <div className="flex-1 min-w-[200px]">
        <select
          value={filters.status}
          onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Status</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
    </div>
  );

  // Add loading states
  const [loading, setLoading] = useState<LoadingStates>({
    list: false,
    create: false,
    update: false,
    delete: false
  });

  // Add error state
  const [error, setError] = useState<string | null>(null);

  // Fetch tools on mount
  useEffect(() => {
    const fetchTools = async () => {
      setLoading(prev => ({ ...prev, list: true }));
      setError(null);
      
      try {
        const toolsData = await toolsApi.listTools();
        setTools(toolsData.map((tool: any) => ({
          id: tool.id,
          name: tool.name,
          description: tool.description,
          isActive: true, // You might want to derive this from the API response
          version: tool.version || '1.0',
          type: tool.action.type as 'javascript' | 'python' | 'http',
          settings: tool.user_settings?.reduce((acc: any, setting: any) => {
            acc[setting.name] = setting.value;
            return acc;
          }, {})
        })));
      } catch (err) {
        console.error('Failed to fetch tools:', err);
        setError('Failed to load tools. Please try again.');
      } finally {
        setLoading(prev => ({ ...prev, list: false }));
      }
    };

    fetchTools();
  }, []);

  // Add loading indicators to the UI
  const renderLoadingOverlay = () => {
    if (!loading.list) return null;
    
    return (
      <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#004DB5]" />
      </div>
    );
  };

  // Add error display
  const renderError = () => {
    if (!error) return null;

    return (
      <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 flex items-center">
        <AlertCircle className="h-5 w-5 mr-2" />
        {error}
      </div>
    );
  };

  // Add a utility function to format names
  const formatToolName = (name: string) => {
    return name
      // Replace underscores with spaces
      .replace(/_/g, ' ')
      // Capitalize first letter of each word
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Render tool card with enhanced design
  const renderToolCard = (tool: ToolItem) => (
    <div key={tool.id} className="bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
      <div className="p-4">
        {/* Tool Header */}
        <div className="flex justify-between items-start mb-2">
          <div className="flex-1 min-w-0"> {/* Add min-w-0 to enable truncation */}
            <h3 className="text-lg font-semibold text-gray-900 truncate" title={formatToolName(tool.name)}>
              {formatToolName(tool.name)}
            </h3>
            <p className="text-sm text-gray-600 mt-1 line-clamp-2" title={tool.description}>
              {tool.description}
            </p>
          </div>
          <div className="flex items-center space-x-2 ml-2 flex-shrink-0">
            <span className={`px-2 py-1 text-xs rounded-full whitespace-nowrap ${
              tool.isActive 
                ? 'bg-green-100 text-green-800' 
                : 'bg-gray-100 text-gray-800'
            }`}>
              {tool.isActive ? 'Active' : 'Inactive'}
            </span>
          </div>
        </div>

        {/* Tool Metadata */}
        <div className="flex flex-wrap gap-2 mt-2">
          {tool.type && (
            <span className="px-2 py-1 text-xs bg-purple-100 text-purple-800 rounded-full whitespace-nowrap">
              {tool.type}
            </span>
          )}
          {tool.version && (
            <span className="px-2 py-1 text-xs bg-gray-100 text-gray-800 rounded-full whitespace-nowrap">
              v{tool.version}
            </span>
          )}
        </div>

        {/* Tool Actions */}
        <div className="flex justify-between items-center mt-4 pt-4 border-t">
          <button
            onClick={() => toggleToolStatus(tool.id)}
            disabled={loading.update}
            className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${
              tool.isActive 
                ? 'bg-red-50 text-red-600 hover:bg-red-100' 
                : 'bg-green-50 text-green-600 hover:bg-green-100'
            } disabled:opacity-50`}
          >
            {loading.update ? (
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-current" />
            ) : (
              tool.isActive ? 'Deactivate' : 'Activate'
            )}
          </button>
          <button
            onClick={() => {
              setSelectedTool(tool);
              setIsSettingsOpen(true);
            }}
            className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
          >
            <Settings size={16} />
          </button>
        </div>
      </div>
    </div>
  );

  // Add settings modal component
  const SettingsModal: React.FC<SettingsModalProps> = ({ tool, isOpen, onClose, onSave }) => {
    if (!isOpen || !tool) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h3 className="text-lg font-semibold mb-4">Tool Settings - {tool.name}</h3>
          
          <div className="space-y-4">
            {/* Tool Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Tool Type</label>
              <div className="text-sm">{tool.type}</div>
            </div>

            {/* Version */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Version</label>
              <div className="text-sm">{tool.version}</div>
            </div>

            {/* Settings Fields */}
            {tool.settings && Object.entries(tool.settings).map(([key, value]) => (
              <div key={key}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <input
                  type="text"
                  value={value as string}
                  onChange={(e) => {
                    const newSettings = { ...tool.settings, [key]: e.target.value };
                    onSave(tool.id, newSettings);
                  }}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
              </div>
            ))}
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg relative">
      {renderLoadingOverlay()}
      {renderError()}
      
      {/* Header Section */}
      <div className="mb-6 flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <h2 className="text-2xl font-bold" style={{ color: '#004DB5' }}>Tool Management</h2>
        
        <div className="w-full sm:w-auto flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-4 sm:mt-0">
          <div className="relative">
            <input
              type="text"
              placeholder="Search tools..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full sm:w-64 pl-10 pr-4 py-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          <button
            onClick={() => setNewTool(prev => ({ ...prev, isComplex: false }))}
            className="bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center justify-center"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Tool
          </button>
        </div>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-gray-50 p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Total Tools</div>
          <div className="text-2xl font-bold text-[#004DB5]">{stats.total}</div>
        </div>
        <div className="bg-gray-50 p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Active Tools</div>
          <div className="text-2xl font-bold text-green-600">{stats.active}</div>
        </div>
      </div>

      {/* Add Filter Controls */}
      {renderFilterControls()}

      {/* Update the tools sections */}
      <div className="space-y-6">
        {/* Tools Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredTools.map((tool) => renderToolCard(tool))}
        </div>
      </div>

      {/* Enhanced Add Tool form */}
      <div className="mt-6 bg-gray-50 p-6 rounded-lg">
        <h3 className="text-lg font-semibold mb-4" style={{ color: '#555555' }}>
          Add New Tool
        </h3>
        <div className="flex flex-col space-y-4">
          {/* Tool Type Selection */}
          <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
            <div className="w-full sm:w-1/3">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tool Type
              </label>
              <select
                value={newTool.type || ''}
                onChange={(e) => setNewTool({ ...newTool, type: e.target.value as any })}
                className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Type</option>
                <option value="javascript">JavaScript</option>
                <option value="python">Python</option>
                <option value="http">HTTP</option>
              </select>
              {formErrors.type && (
                <p className="text-red-500 text-xs mt-1">{formErrors.type}</p>
              )}
            </div>

            {/* Existing name and description inputs */}
            <div className="w-full sm:w-2/3">
              <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tool Name
                  </label>
                  <input
                    type="text"
                    placeholder="Tool Name"
                    value={newTool.name}
                    onChange={(e) => setNewTool({ ...newTool, name: e.target.value })}
                    className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {formErrors.name && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>
                  )}
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <input
                    type="text"
                    placeholder="Tool Description"
                    value={newTool.description}
                    onChange={(e) => setNewTool({ ...newTool, description: e.target.value })}
                    className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {formErrors.description && (
                    <p className="text-red-500 text-xs mt-1">{formErrors.description}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Checkbox for complex tools */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="isComplex"
              checked={newTool.isComplex}
              onChange={(e) => setNewTool({ ...newTool, isComplex: e.target.checked })}
              className="mr-2"
            />
            <label htmlFor="isComplex" className="text-sm text-gray-600">Is this a complex tool (requires API key)?</label>
          </div>

          {/* Display fields for complex tool integrations */}
          {newTool.isComplex && (
            <div className="flex flex-col space-y-2">
              <input
                type="text"
                placeholder="Product Name"
                value={newTool.productName}
                onChange={(e) => setNewTool({ ...newTool, productName: e.target.value })}
                className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                placeholder="API Key"
                value={newTool.apiKey}
                onChange={(e) => setNewTool({ ...newTool, apiKey: e.target.value })}
                className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          )}

          {/* Add button */}
          <button
            onClick={addNewTool}
            disabled={loading.create}
            className="self-start bg-[#004DB5] text-white rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 flex items-center"
          >
            {loading.create ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
            ) : (
              <Plus size={20} />
            )}
          </button>
        </div>
      </div>

      {/* Add Settings Modal */}
      <SettingsModal
        tool={selectedTool}
        isOpen={isSettingsOpen}
        onClose={() => {
          setIsSettingsOpen(false);
          setSelectedTool(null);
        }}
        onSave={(toolId, settings) => {
          setTools(tools.map(tool => 
            tool.id === toolId 
              ? { ...tool, settings } 
              : tool
          ));
        }}
      />
    </div>
  );
};

export default ToolManagement;
