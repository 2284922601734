import { GenerateRequest } from '../../types/chat';

// Keep old URL as fallback
const GENERATE_URL = 'https://brainchain--agent-app-fastapi-app.modal.run/generate';
const AGENT_GENERATE_URL = 'https://brainchain--agent-app-fastapi-app.modal.run/agent/generate';

// Add these constants at the top
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

// Add these at the top of the file
interface EndpointMetrics {
  attempts: number;
  successes: number;
  failures: number;
  lastFailureReason?: string;
}

const metrics: EndpointMetrics = {
  attempts: 0,
  successes: 0,
  failures: 0
};

// Add at the top of the file
const FEATURE_FLAGS = {
  useNewEndpoint: true  // Set to false to disable the new endpoint
};

export const generateApi = {
  // Keep old method temporarily for fallback
  async generate(request: GenerateRequest): Promise<ReadableStream> {
    const response = await fetch(GENERATE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    if (!response.ok) {
      throw new Error(`Generate request failed: ${response.statusText}`);
    }

    if (!response.body) {
      throw new Error('No response body received');
    }

    return response.body;
  },

  // New method using agent/generate endpoint
  async agentGenerate(request: GenerateRequest): Promise<ReadableStream> {
    if (!FEATURE_FLAGS.useNewEndpoint) {
      console.log('New endpoint disabled by feature flag, using old endpoint');
      return this.generate(request);
    }
    
    console.log('Using new agent/generate endpoint:', request);
    
    try {
      const response = await fetch(AGENT_GENERATE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/event-stream',
        },
        body: JSON.stringify(request),
      });

      if (!response.ok) {
        console.error('Agent generate error:', response.statusText);
        metrics.failures++;
        metrics.lastFailureReason = response.statusText;
        return this.generate(request);
      }

      if (!response.body) {
        throw new Error('No response body received');
      }

      metrics.successes++;
      console.log('Successfully connected to agent/generate endpoint');
      
      // Transform the stream to handle empty chunks
      const transformStream = new TransformStream({
        transform(chunk, controller) {
          if (chunk.length > 0) {
            controller.enqueue(chunk);
          }
        }
      });

      return response.body.pipeThrough(transformStream);

    } catch (error: unknown) {
      console.error('Error in agentGenerate:', error);
      metrics.failures++;
      
      // Type guard for Error objects
      if (error instanceof Error) {
        metrics.lastFailureReason = error.message;
      } else {
        metrics.lastFailureReason = 'Unknown error occurred';
      }
      
      return this.generate(request);
    }
  },

  // Add this function to the generateApi object
  async trackMetrics() {
    // You can send these metrics to your monitoring system
    console.log('Agent/Generate Endpoint Metrics:', {
      ...metrics,
      successRate: `${((metrics.successes / metrics.attempts) * 100).toFixed(2)}%`
    });
  }
}; 