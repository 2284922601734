import Tooltip from './ui/Tooltip';
import React, { useState } from 'react';
import { User, Bell, Lock, Globe, Database, Brain, Shield, Upload } from 'lucide-react';

interface UserSettings {
  name: string;
  email: string;
  role: string;
  language: string;
  twoFactorAuth: boolean;
  emailNotificationFrequency: 'daily' | 'weekly' | 'monthly';
  pushNotifications: boolean;
  dataRetentionPeriod: number;
  shareUsageData: boolean;
  automaticDocumentAnalysis: boolean;
  learningStyle: 'aggressive' | 'cautious';
  expertisePriorities: string[];
}

const initialSettings: UserSettings = {
  name: 'John Doe',
  email: 'john.doe@example.com',
  role: 'User',
  language: 'English',
  twoFactorAuth: false,
  emailNotificationFrequency: 'weekly',
  pushNotifications: true,
  dataRetentionPeriod: 90,
  shareUsageData: true,
  automaticDocumentAnalysis: true,
  learningStyle: 'cautious',
  expertisePriorities: [],
};

const Settings: React.FC = () => {
  const [settings, setSettings] = useState<UserSettings>(initialSettings);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSettings(prev => ({ ...prev, [name]: value }));
  };

  const handleToggle = (setting: keyof UserSettings) => {
    setSettings(prev => ({ ...prev, [setting]: !prev[setting as keyof UserSettings] }));
  };

  const handleExpertiseChange = (expertise: string) => {
    setSettings(prev => ({
      ...prev,
      expertisePriorities: prev.expertisePriorities.includes(expertise)
        ? prev.expertisePriorities.filter(e => e !== expertise)
        : [...prev.expertisePriorities, expertise],
    }));
  };

  const handlePasswordChange = (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      console.log('Password changed');
    } else {
      console.log('Passwords do not match');
    }
  };

  const handleProfilePictureUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log('Profile picture uploaded:', file.name);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6" style={{ color: '#004DB5' }}>Settings</h2>

      <div className="space-y-6">
        {/* User Profile */}
        <section className="border-b pb-6">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <User className="mr-2" /> User Profile
          </h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={settings.name}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={settings.email}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
              <input
                type="text"
                id="role"
                name="role"
                value={settings.role}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label htmlFor="profilePicture" className="block text-sm font-medium text-gray-700">Profile Picture</label>
              <input
                type="file"
                id="profilePicture"
                accept="image/*"
                onChange={handleProfilePictureUpload}
                className="mt-1 block w-full"
              />
            </div>
          </div>
        </section>

        {/* Account Settings */}
        <section className="border-b pb-6">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Lock className="mr-2" /> Account Security
          </h3>
          <form onSubmit={handlePasswordChange} className="space-y-4">
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">New Password</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <button type="submit" className="bg-[#004DB5] text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Change Password
            </button>
          </form>

          <div className="mt-4 flex items-center justify-between">
            <span>Two-Factor Authentication</span>
            <Tooltip content="Enhance your account security by enabling two-factor authentication.">
              <button
                onClick={() => handleToggle('twoFactorAuth')}
                className={`${settings.twoFactorAuth ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <span className={`${settings.twoFactorAuth ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`} />
              </button>
            </Tooltip>
          </div>
        </section>

        {/* Notification Preferences */}
        <section className="border-b pb-6">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Bell className="mr-2" /> Notification Preferences
          </h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="emailNotificationFrequency" className="block text-sm font-medium text-gray-700">Email Notification Frequency</label>
              <select
                id="emailNotificationFrequency"
                name="emailNotificationFrequency"
                value={settings.emailNotificationFrequency}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>

            <div className="flex items-center justify-between">
              <span>Push Notifications</span>
              <Tooltip content="Get notified instantly with push notifications.">
              <button
                onClick={() => handleToggle('pushNotifications')}
                className={`${settings.pushNotifications ? 'bg-[#004DB5]' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                >
                <span className={`${settings.pushNotifications ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`} />
                </button>

              </Tooltip>
            </div>
          </div>
        </section>

        {/* Privacy & Data */}
        <section className="border-b pb-6">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Database className="mr-2" /> Privacy & Data
          </h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="dataRetentionPeriod" className="block text-sm font-medium text-gray-700">Data Retention Period (days)</label>
              <input
                type="number"
                id="dataRetentionPeriod"
                name="dataRetentionPeriod"
                value={settings.dataRetentionPeriod}
                onChange={handleInputChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            
          </div>
        </section>

        {/* Document Handling */}
        <section className="border-b pb-6">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Upload className="mr-2" /> Document Handling
          </h3>
          <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="flex-grow">Automatic Document Analysis</span>
            <button
                onClick={() => handleToggle('automaticDocumentAnalysis')}
                className={`${settings.automaticDocumentAnalysis ? 'bg-[#004DB5]' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
                <span className={`${settings.automaticDocumentAnalysis ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`} />
            </button>
            </div>

          </div>
        </section>

        {/* Self-Learning Adjustments */}
        <section className="border-b pb-6">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Brain className="mr-2" /> Self-Learning Preferences
          </h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="learningStyle" className="block text-sm font-medium text-gray-700">Learning Style</label>
              <select
                id="learningStyle"
                name="learningStyle"
                value={settings.learningStyle}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="aggressive">Aggressive</option>
                <option value="cautious">Cautious</option>
              </select>
            </div>
            <div>
            <div className="mt-2 space-y-2">
            {['Supply Chain', 'Finance', 'Marketing', 'Human Resources', 'Technology'].map((expertise) => (
                <label key={expertise} className="flex items-center space-x-2">
                <input
                    type="checkbox"
                    checked={settings.expertisePriorities.includes(expertise)}
                    onChange={() => handleExpertiseChange(expertise)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="text-gray-700">{expertise}</span>
                </label>
            ))}
            </div>

            </div>
          </div>
        </section>

        {/* Security & Access Control */}
        <section>
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Shield className="mr-2" /> Security & Access Control
          </h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="userRole" className="block text-sm font-medium text-gray-700">User Role</label>
              <select
                id="userRole"
                name="role"
                value={settings.role}
                onChange={handleInputChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="User">User</option>
                <option value="Admin">Admin</option>
                <option value="Manager">Manager</option>
              </select>
            </div>
            <div className="border border-gray-300 rounded-md p-4">
              <p className="text-sm text-gray-500 mb-2">Configure permissions for different roles:</p>
              <button className="bg-[#004DB5] text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Configure Permissions
              </button>
            </div>
            <div className="border border-gray-300 rounded-md p-4">
              <p className="text-sm text-gray-500 mb-2">Manage access to sensitive features and data:</p>
              <button className="bg-[#004DB5] text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Manage Access Control
              </button>
            </div>
          </div>
        </section>
      </div>

      <div className="mt-8">
      <button className="bg-[#004DB5] text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
        Save Changes
        </button>

      </div>
    </div>
  );
};

export default Settings;
