import axios from 'axios';

const API_BASE_URL = 'https://am-test.brainchain.cloud';

export interface ChatMessage {
  id: string;
  content: string;
  role: 'user' | 'assistant';
  type: 'text' | 'file' | 'goal' | 'task' | 'command' | 'run';
  timestamp: string;
  metadata?: {
    command?: string;
    status?: string;
    goalId?: string;
    taskId?: string;
  };
}

export interface Conversation {
  id: string;
  agent_id: string;
  messages: ChatMessage[];
  created_at: string;
  updated_at: string;
}

export const chatApi = {
  // Conversation Management
  async startConversation(agentId: string): Promise<Conversation> {
    try {
      console.log('Starting conversation with agent:', agentId);
      const response = await axios.post(`${API_BASE_URL}/conversations/`, {
        agent_id: agentId
      });
      
      console.log('Raw conversation response:', response.data);

      // Return the conversation data directly from the response
      return {
        id: response.data.conversation_id || response.data.id, // Handle both possible response formats
        agent_id: agentId,
        messages: response.data.messages || [],
        created_at: response.data.created_at || new Date().toISOString(),
        updated_at: response.data.updated_at || new Date().toISOString()
      };
    } catch (error) {
      console.error('Failed to start conversation:', error);
      throw error;
    }
  },

  async sendMessage(conversationId: string, content: string): Promise<ChatMessage> {
    try {
      console.log('Sending message:', { conversationId, content });
      
      const payload = {
        user_input: content
      };

      const response = await axios.post(
        `${API_BASE_URL}/conversations/${conversationId}/messages/`, 
        payload
      );

      console.log('Message sent response:', response.data);
      
      // Check for OpenAI error in response
      if (response.data?.error && response.data.error.includes('openai')) {
        throw new Error('The agent is currently unavailable. Please try again later.');
      }

      // Return the message in our format
      return {
        id: response.data.id || Date.now().toString(),
        content: content,
        role: 'user',
        type: 'text',
        timestamp: new Date().toISOString(),
        metadata: {}
      };
    } catch (error: any) {
      // Check if it's the OpenAI version error
      if (error.response?.data?.error?.includes('openai')) {
        console.error('OpenAI version error:', error);
        throw new Error('The agent is currently unavailable. Please try again later.');
      }
      console.error('Failed to send message:', error);
      throw error;
    }
  },

  async getConversation(conversationId: string): Promise<Conversation> {
    try {
      const response = await axios.get(`${API_BASE_URL}/conversations/${conversationId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get conversation:', error);
      throw new Error('Failed to get conversation');
    }
  },

  // Command Handling
  async runCommand(agentId: string, input: string, goalId?: string): Promise<any> {
    try {
      const response = await axios.post(`${API_BASE_URL}/agents/${agentId}/run`, {
        input,
        goal_id: goalId,
        additional_input: {}
      });
      return response.data;
    } catch (error) {
      console.error('Failed to run command:', error);
      throw new Error('Failed to run command');
    }
  },

  async processTask(agentId: string, taskInput: string): Promise<any> {
    try {
      const response = await axios.post(`${API_BASE_URL}/agents/${agentId}/process_task`, {
        input: taskInput
      });
      return response.data;
    } catch (error) {
      console.error('Failed to process task:', error);
      throw new Error('Failed to process task');
    }
  },

  async processGoal(agentId: string, goalInput: string): Promise<any> {
    try {
      const response = await axios.post(`${API_BASE_URL}/agents/${agentId}/process_goal`, {
        input: goalInput
      });
      return response.data;
    } catch (error) {
      console.error('Failed to process goal:', error);
      throw new Error('Failed to process goal');
    }
  }
};
