import React, { useState, useEffect, useRef } from 'react';
import { Plus, Trash2, Play, Square, ChevronDown, Loader } from 'lucide-react';
import axios from 'axios';
import toast from 'react-hot-toast';

// Define interfaces
interface AgentDetails {
  id: string;
  name: string;
  status: 'idle' | 'active' | 'running' | 'stopped';
  created_at: string | null;
  updated_at: string | null;
  goals?: AgentGoal[];
  memories?: AgentMemory[];
}

interface AgentGoal {
  id: string;
  content: string;
  status: 'active' | 'completed';
}

interface AgentMemory {
  id: string;
  content: string;
  timestamp: string;
  type: string;
}

interface CreateAgentForm {
  name: string;
  description: string;
  initialGoals: string[];
}

// First, update the UiState interface to include showAgentDropdown
interface UiState {
  isLoading: boolean;
  isCreating: boolean;
  showCreatePanel: boolean;
  showAddGoalPanel: boolean;
  showAgentDropdown: boolean;
  error: string | null;
}

// API Client
const API_BASE_URL = 'https://am-test.brainchain.cloud';

// Export the API client
export const apiClient = {
  async listAgents() {
    try {
      const response = await axios.get(`${API_BASE_URL}/agents`);
      
      console.log('Raw API Response:', {
        count: response.data.length,
        firstFewAgents: response.data.slice(0, 3),
        statusCounts: response.data.reduce((acc: any, agent: any) => {
          acc[agent.status] = (acc[agent.status] || 0) + 1;
          return acc;
        }, {}),
        rawStatuses: response.data.map((a: any) => a.status)
      });

      // Create a map to track duplicate IDs
      const idCounts: { [key: string]: number } = {};

      // Transform the response and force all agents to be active
      const agents = response.data.map((agent: {
        id: string;
        name: string;
        status: string;
        description: string;
      }) => {
        // Handle duplicate IDs by appending a counter
        const baseId = agent.id;
        idCounts[baseId] = (idCounts[baseId] || 0) + 1;
        const uniqueId = idCounts[baseId] > 1 ? `${baseId}_${idCounts[baseId]}` : baseId;

        return {
          id: uniqueId, // Use the unique ID
          name: agent.name || agent.id.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' '),
          status: 'active', // Force all agents to be active
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          goals: [],
          description: agent.description || ''
        };
      });

      // Sort agents
      const sortedAgents = agents.sort((a: AgentDetails, b: AgentDetails) => {
        if (a.name && !b.name) return -1;
        if (!a.name && b.name) return 1;
        return a.id.localeCompare(b.id);
      });

      console.log('Transformed agents:', {
        timestamp: new Date().toISOString(),
        agentsCount: sortedAgents.length,
        agents: sortedAgents,
        liveAgents: sortedAgents.length // All agents are now live
      });

      return sortedAgents;
    } catch (error) {
      console.error('Failed to fetch agents:', error);
      return [];
    }
  },

  async createAgent(data: CreateAgentForm) {
    const response = await axios.post(`${API_BASE_URL}/agents`, {
      name: data.name,
      description: data.description,
      goals: data.initialGoals
    });
    return response.data;
  },

  async getAgentDetails(agentId: string): Promise<AgentDetails> {
    try {
      const details = await axios.get(`${API_BASE_URL}/agents/${agentId}`);
      return {
        id: agentId,
        name: details.data.name || 'Unknown',
        status: 'active', // Force status to active
        created_at: details.data.created_at || null,
        updated_at: details.data.updated_at || null,
        goals: details.data.goals || []
      };
    } catch (error) {
      return {
        id: agentId,
        name: 'Unknown',
        status: 'active', // Force status to active
        created_at: null,
        updated_at: null,
        goals: []
      };
    }
  },

  async deleteAgent(agentId: string) {
    await axios.delete(`${API_BASE_URL}/agents/${agentId}`);
  },

  async updateAgentStatus(agentId: string, action: 'start' | 'stop') {
    console.log(`Attempting to ${action} agent:`, {
        agentId,
        action,
        timestamp: new Date().toISOString()
    });

    try {
        if (action === 'start') {
            // Run the agent with num_steps as a query parameter
            console.log('Running agent...');
            const runResponse = await axios.post(
                `${API_BASE_URL}/agents/${agentId}/run?num_steps=10`, // Added as query parameter
                {}, // Empty body since all params are in query
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            console.log('Run response:', {
                status: runResponse.status,
                data: runResponse.data,
                timestamp: new Date().toISOString()
            });
        } else {
            // Stop the agent
            const response = await axios.post(
                `${API_BASE_URL}/agents/${agentId}/stop`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            console.log('Stop response:', {
                status: response.status,
                data: response.data,
                timestamp: new Date().toISOString()
            });
        }

        // Poll for status change
        let attempts = 0;
        let currentDelay = 1000;
        let latestAgentDetails: { data: { status: 'idle' | 'active' | 'running' | 'stopped' } } | null = null;

        while (attempts < 5) {
            const thisDelay = currentDelay;
            await new Promise(resolve => setTimeout(resolve, thisDelay));
            
            try {
                const response = await axios.get(`${API_BASE_URL}/agents/${agentId}`);
                latestAgentDetails = response;

                console.log(`Polling attempt ${attempts + 1}:`, {
                    status: latestAgentDetails?.data.status,
                    timestamp: new Date().toISOString()
                });

                if (action === 'start' && latestAgentDetails?.data.status !== 'idle') {
                    break;
                }
                if (action === 'stop' && latestAgentDetails?.data.status === 'idle') {
                    break;
                }

                currentDelay *= 2;
                attempts++;
            } catch (pollError) {
                console.error('Error polling agent status:', pollError);
                currentDelay *= 2;
                attempts++;
            }
        }

        if (!latestAgentDetails) {
            throw new Error('Failed to get updated agent status');
        }

        return latestAgentDetails.data;
    } catch (error: any) {
        console.error(`Failed to ${action} agent:`, {
            error,
            errorResponse: error.response?.data,
            errorDetails: {
                status: error.response?.status,
                statusText: error.response?.statusText,
                data: error.response?.data,
                message: error.message
            },
            timestamp: new Date().toISOString()
        });
        throw error;
    }
  },

  async addGoal(agentId: string, content: string) {
    console.log('Adding goal request:', {
      agentId,
      content,
      timestamp: new Date().toISOString()
    });

    try {
      const response = await axios.post(`${API_BASE_URL}/agents/${agentId}/goals`, {
        content: content
      });
      
      console.log('Goal added successfully:', response.data);
      return response.data;
    } catch (error: any) {
      console.error('Failed to add goal:', error.response?.data);
      throw error;
    }
  },

  async getAgentGoals(agentId: string) {
    try {
      const response = await axios.get(`${API_BASE_URL}/agents/${agentId}/goals`);
      return response.data;
    } catch (error: any) {
      // If it's a CORS error or 500, return empty goals array
      if (error.code === 'ERR_NETWORK' || error.response?.status === 500) {
        console.warn(`Failed to fetch goals for agent ${agentId}, returning empty array:`, error);
        return [];
      }
      throw error;
    }
  },

  async processGoals(agentId: string) {
    const response = await axios.post(`${API_BASE_URL}/agents/${agentId}/process_goals`);
    return response.data;
  },

  async deleteGoal(agentId: string, goalId: string) {
    await axios.delete(`${API_BASE_URL}/agents/${agentId}/goals/${goalId}`);
  }
};



// Add these types at the top with other interfaces
interface ApiError {
  message: string;
  response?: {
    data: any;
    status: number;
  };
}

// Add this utility function for error handling
const formatApiError = (error: unknown): ApiError => {
  if (error instanceof Error) {
    return {
      message: error.message,
      response: (error as any).response // Type assertion for axios error
    };
  }
  return {
    message: 'An unknown error occurred',
    response: undefined
  };
};

const AgentConfigurationInterface: React.FC = () => {
  // Move all state declarations together
  const [agentState, setAgentState] = useState<{
    agents: AgentDetails[];
    selectedId: string;
    details: AgentDetails | null;
  }>({
    agents: [],
    selectedId: '',
    details: null
  });

  const [uiState, setUiState] = useState<UiState>({
    isLoading: false,
    isCreating: false,
    showCreatePanel: false,
    showAddGoalPanel: false,
    showAgentDropdown: false,
    error: null
  });

  const [createForm, setCreateForm] = useState<CreateAgentForm>({
    name: '',
    description: '',
    initialGoals: []
  });

  const [newGoal, setNewGoal] = useState('');
  const [statusUpdating, setStatusUpdating] = useState<string | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setUiState(prev => ({ ...prev, showAgentDropdown: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add refreshAgentList function
  const refreshAgentList = async () => {
    console.log('Fetching Agents List');
    console.log('Request started:', new Date().toISOString());
    
    try {
      const agents = await apiClient.listAgents();
      setAgentState(prev => ({
        ...prev,
        agents,
        selectedId: prev.selectedId || (agents.length > 0 ? agents[0].id : ''),
      }));
      console.log('Response received:', {
        timestamp: new Date().toISOString(),
        agentsCount: agents.length,
        agents: agents
      });
    } catch (error) {
      console.error('Failed to fetch agents:', error);
      toast.error('Failed to fetch agents');
    }
  };

  // Add useEffect for initial fetch and polling
  useEffect(() => {
    // Initial fetch
    refreshAgentList();

    // Set up polling every minute (60000ms)
    const pollInterval = setInterval(() => {
      console.log('Polling agents list:', new Date().toISOString());
      refreshAgentList();
    }, 60000);

    // Cleanup
    return () => clearInterval(pollInterval);
  }, []);

  // Fetch agents on mount
  useEffect(() => {
    const fetchAgents = async () => {
      console.group('Fetching Agents List');
      console.log('Request started:', new Date().toISOString());
      setUiState(prev => ({ ...prev, isLoading: true, error: null }));
      
      try {
        const agents = await apiClient.listAgents();
        console.log('Response received:', {
          timestamp: new Date().toISOString(),
          agentsCount: agents.length,
          agents: agents
        });
        
        setAgentState(prev => ({
          ...prev,
          agents,
          selectedId: agents.length > 0 ? agents[0].id : ''
        }));
      } catch (err) {
        const error = formatApiError(err);
        console.error('Failed to fetch agents:', {
          timestamp: new Date().toISOString(),
          error: error,
          errorMessage: error.message,
          errorResponse: error.response?.data
        });
        setUiState(prev => ({ ...prev, error: 'Failed to load agents' }));
        toast.error('Failed to load agents');
      } finally {
        setUiState(prev => ({ ...prev, isLoading: false }));
        console.groupEnd();
      }
    };

    fetchAgents();
  }, []);

  // Fetch agent details when selection changes
  useEffect(() => {
    const fetchAgentDetails = async () => {
      if (!agentState.selectedId) return;

      try {
        const details = await apiClient.getAgentDetails(agentState.selectedId);
        // Now details will have all required AgentDetails properties
        setAgentState(prev => ({
          ...prev,
          details: details // This will now match the AgentDetails type
        }));
      } catch (err) {
        const error = formatApiError(err);
        console.error('Failed to fetch agent details:', {
          timestamp: new Date().toISOString(),
          error: error,
          errorMessage: error.message,
          errorResponse: error.response?.data,
          agentId: agentState.selectedId
        });
        toast.error('Failed to load agent details');
      }
    };

    fetchAgentDetails();
  }, [agentState.selectedId]);

  // Add effect to fetch goals when agent is selected
  useEffect(() => {
    const fetchAgentGoals = async () => {
      if (!agentState.selectedId) return;

      try {
        const goals = await apiClient.getAgentGoals(agentState.selectedId);
        // Only update state if we got valid goals
        if (Array.isArray(goals)) {
          setAgentState(prev => ({
            ...prev,
            details: prev.details ? {
              ...prev.details,
              goals: goals
            } : null
          }));
        }
      } catch (error) {
        // Log but don't show error to user since goals are optional
        console.error('Failed to fetch agent goals:', error);
      }
    };

    fetchAgentGoals();
  }, [agentState.selectedId]);

  // Handlers
  const handleCreateAgent = async () => {
    console.group('Creating New Agent');
    console.log('Create agent request:', {
      timestamp: new Date().toISOString(),
      formData: createForm
    });

    if (!createForm.name.trim()) {
      console.log('Validation failed: Agent name required');
      console.groupEnd();
      toast.error('Agent name is required');
      return;
    }

    setUiState(prev => ({ ...prev, isCreating: true }));
    try {
      const response = await apiClient.createAgent(createForm);
      console.log('Agent created successfully:', {
        timestamp: new Date().toISOString(),
        response: response
      });

      // Close the modal and reset the form
      setUiState(prev => ({ ...prev, showCreatePanel: false }));
      setCreateForm({ name: '', description: '', initialGoals: [] });

      // Refresh agents list
      const agents = await apiClient.listAgents();
      console.log('Agents list refreshed:', {
        timestamp: new Date().toISOString(),
        agentsCount: agents.length
      });

      setAgentState(prev => ({ ...prev, agents }));
      toast.success('Agent created successfully');
    } catch (err) {
      const error = formatApiError(err);
      console.error('Failed to create agent:', {
        timestamp: new Date().toISOString(),
        error: error,
        errorMessage: error.message,
        errorResponse: error.response?.data,
        requestData: createForm
      });
      toast.error('Failed to create agent');
    } finally {
      setUiState(prev => ({ ...prev, isCreating: false }));
      console.groupEnd();
    }
  };

  const handleDeleteAgent = async (agentId: string) => {
    console.group('Deleting Agent');
    console.log('Delete agent request:', {
      timestamp: new Date().toISOString(),
      agentId: agentId
    });

    if (!window.confirm('Are you sure you want to delete this agent?')) {
      console.log('Delete operation cancelled by user');
      console.groupEnd();
      return;
    }

    try {
      await apiClient.deleteAgent(agentId);
      console.log('Agent deleted successfully:', {
        timestamp: new Date().toISOString(),
        deletedAgentId: agentId
      });

      const agents = await apiClient.listAgents();
      console.log('Agents list refreshed after deletion:', {
        timestamp: new Date().toISOString(),
        remainingAgentsCount: agents.length
      });

      setAgentState(prev => ({
        ...prev,
        agents,
        selectedId: agents.length > 0 ? agents[0].id : '',
        details: null
      }));
      toast.success('Agent deleted successfully');
    } catch (err) {
      const error = formatApiError(err);
      console.error('Failed to delete agent:', {
        timestamp: new Date().toISOString(),
        error: error,
        errorMessage: error.message,
        errorResponse: error.response?.data,
        agentId: agentId
      });
      toast.error('Failed to delete agent');
    } finally {
      console.groupEnd();
    }
  };

  // Current status update is incomplete
  const handleAgentStatusUpdate = async (agentId: string, action: 'start' | 'stop') => {
    setStatusUpdating(agentId);
    try {
      const updatedAgent = await apiClient.updateAgentStatus(agentId, action);
      
      // Update local state with the actual agent status from the API
      setAgentState(prev => ({
        ...prev,
        agents: prev.agents.map(agent => 
          agent.id === agentId 
              ? { ...agent, status: updatedAgent.status as AgentDetails['status'] }
              : agent
        ),
        details: prev.details && prev.details.id === agentId 
          ? { ...prev.details, status: updatedAgent.status as AgentDetails['status'] }
          : prev.details
      }));

      if (updatedAgent.status === 'idle' && action === 'start') {
        toast.error('Agent failed to start - still in idle state');
      } else {
        toast.success(`Agent ${action === 'start' ? 'started' : 'stopped'} successfully`);
      }
      
      // Refresh agent list
      await refreshAgentList();
    } catch (error: any) {
      console.error(`Failed to ${action} agent:`, error);
      
      if (error.response?.status === 400 && error.response?.data?.detail) {
        toast.error(error.response.data.detail);
      } else {
        toast.error(`Failed to ${action} agent. Please try again.`);
      }
    } finally {
      setStatusUpdating(null);
    }
  };

  // Add these handlers inside the component
  const handleAddGoal = async () => {
    if (!newGoal.trim() || !agentState.selectedId) {
      toast.error('Please enter a goal description');
      return;
    }

    try {
      await apiClient.addGoal(agentState.selectedId, newGoal);
      // Refresh agent details to get updated goals
      const details = await apiClient.getAgentDetails(agentState.selectedId);
      setAgentState(prev => ({ ...prev, details }));
      setNewGoal('');
      setUiState(prev => ({ ...prev, showAddGoalPanel: false }));
      toast.success('Goal added successfully');
    } catch (err) {
      const error = formatApiError(err);
      console.error('Failed to add goal:', {
        timestamp: new Date().toISOString(),
        error: error,
        errorMessage: error.message,
        errorResponse: error.response?.data,
        agentId: agentState.selectedId,
        goalContent: newGoal
      });
      toast.error('Failed to add goal');
    }
  };

  const handleDeleteGoal = async (agentId: string, goalId: string) => {
    if (!window.confirm('Are you sure you want to delete this goal?')) return;

    try {
      await apiClient.deleteGoal(agentId, goalId);
      // Refresh agent details to get updated goals
      const details = await apiClient.getAgentDetails(agentId);
      setAgentState(prev => ({ ...prev, details }));
      toast.success('Goal deleted successfully');
    } catch (err) {
      const error = formatApiError(err);
      console.error('Failed to delete goal:', {
        timestamp: new Date().toISOString(),
        error: error,
        errorMessage: error.message,
        errorResponse: error.response?.data,
        agentId: agentId,
        goalId: goalId
      });
      toast.error('Failed to delete goal');
    }
  };

  const handleAgentSelect = (agentId: string) => {
    setAgentState(prev => ({ ...prev, selectedId: agentId }));
    setUiState(prev => ({ ...prev, showAgentDropdown: false }));
  };

  // Add the return statement with UI components
  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Quick Actions Bar */}
      <div className="flex items-center justify-between bg-white rounded-lg shadow px-6 py-4">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold" style={{ color: '#004DB5' }}>
            Agent Management
          </h1>
          <button
            onClick={refreshAgentList}
            className="text-gray-500 hover:text-blue-600 p-1 rounded-full"
            title="Refresh agent list"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
          </button>
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <span className="flex items-center">
              <span className="w-2 h-2 rounded-full bg-green-500 mr-1" />
              {agentState.agents.filter(a => a.status === 'active').length} Active
            </span>
            <span>/ {agentState.agents.length} Total</span>
          </div>
        </div>
        <button
          onClick={() => setUiState(prev => ({ ...prev, showCreatePanel: true }))}
          className="bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center"
        >
          <Plus className="w-4 h-4 mr-2" />
          New Agent
        </button>
      </div>

      {/* Main Content - Full Width */}
      <div className="space-y-6">
        {/* Agent Selection & Details Panel */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
            {/* Left side with title and dropdown */}
            <div className="flex-grow max-w-md">
              <h2 className="text-lg font-semibold mb-2" style={{ color: '#004DB5' }}>
                Agent Configuration
              </h2>
              <div className="relative">
                <button
                  onClick={() => setUiState(prev => ({ ...prev, showAgentDropdown: !prev.showAgentDropdown }))}
                  className="w-full flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <div className="flex items-center space-x-2 min-w-0">
                    <span className="truncate font-medium">
                      {agentState.selectedId 
                        ? agentState.agents.find(a => a.id === agentState.selectedId)?.name || 'Select Agent'
                        : 'Select Agent'
                      }
                    </span>
                  </div>
                  <ChevronDown className="w-4 h-4 flex-shrink-0" />
                </button>
                
                {/* Dropdown menu */}
                {uiState.showAgentDropdown && (
                  <div className="absolute left-0 mt-2 w-full bg-white border rounded-lg shadow-lg z-10 max-h-96 overflow-y-auto">
                    {agentState.agents.map((agent: AgentDetails) => (
                      <button
                        key={agent.id}
                        onClick={() => handleAgentSelect(agent.id)}
                        className="w-full flex items-center justify-between p-3 hover:bg-gray-50 transition-colors text-left"
                      >
                        <div className="flex-1 min-w-0 mr-4">
                          <div className="truncate font-medium">
                            {agent.name}
                          </div>
                        </div>
                        <span className={`w-2 h-2 rounded-full flex-shrink-0 ${
                          agent.status === 'active' ? 'bg-green-500' : 'bg-gray-300'
                        }`} />
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Right side with action buttons */}
            {agentState.selectedId && (
              <div className="flex items-center space-x-3">
                <button
                  onClick={() => handleAgentStatusUpdate(
                    agentState.selectedId,
                    agentState.details?.status === 'active' ? 'stop' : 'start'
                  )}
                  disabled={statusUpdating === agentState.selectedId}
                  className={`
                    flex items-center space-x-2 px-4 py-2 rounded-lg transition-all duration-200
                    ${statusUpdating === agentState.selectedId
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : agentState.details?.status === 'active'
                      ? 'bg-red-50 text-red-600 hover:bg-red-100 border border-red-200'
                      : 'bg-[#004DB5] text-white hover:bg-blue-600'
                    }
                  `}
                >
                  {statusUpdating === agentState.selectedId ? (
                    <>
                      <Loader className="w-4 h-4 animate-spin" />
                      <span>Processing...</span>
                    </>
                  ) : agentState.details?.status === 'active' ? (
                    <>
                      <Square className="w-4 h-4" />
                      <span>Stop Agent</span>
                    </>
                  ) : (
                    <>
                      <Play className="w-4 h-4" />
                      <span>Start Agent</span>
                    </>
                  )}
                </button>
                <button
                  onClick={() => handleDeleteAgent(agentState.selectedId)}
                  className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors border border-gray-200"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>Delete</span>
                </button>
              </div>
            )}
          </div>

          {/* Agent Details Section */}
          {agentState.selectedId && agentState.details && (
            <div className="mt-6">
              <div className="grid grid-cols-3 gap-6 mb-6">
                <div>
                  <label className="text-sm text-gray-500 block mb-1">Status</label>
                  <div className="flex items-center">
                    <span className={`w-2 h-2 rounded-full mr-2 ${
                      agentState.details.status === 'active' ? 'bg-green-500' : 'bg-gray-400'
                    }`} />
                    <span className="font-medium">
                      {agentState.details.status === 'active' ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                </div>
                <div>
                  <label className="text-sm text-gray-500 block mb-1">Created</label>
                  <div className="font-medium">
                    {new Date(agentState.details.created_at || '').toLocaleDateString()}
                  </div>
                </div>
                <div>
                  <label className="text-sm text-gray-500 block mb-1">Last Updated</label>
                  <div className="font-medium">
                    {new Date(agentState.details.updated_at || '').toLocaleDateString()}
                  </div>
                </div>
              </div>

              {/* Goals Section */}
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold" style={{ color: '#004DB5' }}>
                    Goals
                  </h3>
                  <button
                    onClick={() => setUiState(prev => ({ ...prev, showAddGoalPanel: true }))}
                    className="text-sm text-[#004DB5] hover:text-blue-600 flex items-center"
                  >
                    <Plus className="w-4 h-4 mr-1" />
                    Add Goal
                  </button>
                </div>
                <div className="space-y-2">
                  {agentState.details.goals?.map((goal) => (
                    <div 
                      key={goal.id}
                      className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                    >
                      <div className="flex items-center space-x-3 flex-grow">
                        <span className={`w-2 h-2 rounded-full ${
                          goal.status === 'completed' ? 'bg-green-500' : 'bg-blue-500'
                        }`} />
                        <span className="text-sm">{goal.content}</span>
                      </div>
                      <button
                        onClick={() => handleDeleteGoal(agentState.selectedId, goal.id)}
                        className="text-gray-400 hover:text-red-500 p-1"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                  {!agentState.details.goals?.length && (
                    <div className="text-center text-gray-500 py-4 bg-gray-50 rounded-lg">
                      No goals set
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Live Agents Section */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold mb-4" style={{ color: '#004DB5' }}>
            Live Agents
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {agentState.agents
                .filter((agent: AgentDetails) => agent.status !== 'idle')
                .map(agent => (
                    <div 
                        key={agent.id} 
                        className="p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                    >
                        <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center space-x-2">
                                <span className="w-2 h-2 rounded-full bg-green-500" />
                                <span className="font-medium">{agent.name}</span>
                            </div>
                            <button
                                onClick={() => handleAgentStatusUpdate(agent.id, 'stop')}
                                disabled={statusUpdating === agent.id}
                                className="text-red-600 hover:text-red-700 text-sm flex items-center"
                            >
                                {statusUpdating === agent.id ? (
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-red-600 mr-1" />
                                ) : (
                                    <Square className="w-4 h-4 mr-1" />
                                )}
                                Stop
                            </button>
                        </div>
                        <div className="text-xs text-gray-500">
                            Status: {agent.status}
                        </div>
                    </div>
                ))}
            {!agentState.agents.some((agent: AgentDetails) => agent.status !== 'idle') && (
                <div className="text-center text-gray-500 py-4 bg-gray-50 rounded-lg md:col-span-2">
                    No active agents
                </div>
            )}
          </div>
        </div>
      </div>

      {/* Create Agent Modal */}
      {uiState.showCreatePanel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" 
             style={{ top: 0 }}> {/* Force overlay to start from top */}
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold" style={{ color: '#004DB5' }}>Create New Agent</h3>
              <button
                onClick={() => setUiState(prev => ({ ...prev, showCreatePanel: false }))}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className="space-y-4">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  value={createForm.name}
                  onChange={(e) => setCreateForm(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter agent name"
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Description</label>
                <textarea
                  value={createForm.description}
                  onChange={(e) => setCreateForm(prev => ({ ...prev, description: e.target.value }))}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Describe the agent's purpose"
                  rows={3}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700">Initial Goals</label>
                <input
                  type="text"
                  value={createForm.initialGoals.join(', ')}
                  onChange={(e) => setCreateForm(prev => ({
                    ...prev,
                    initialGoals: e.target.value.split(',').map(g => g.trim())
                  }))}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter goals separated by commas"
                />
                <p className="mt-1 text-sm text-gray-500">Separate multiple goals with commas</p>
              </div>

              {/* Modal Footer */}
              <div className="flex justify-end space-x-3 mt-6">  {/* Removed border-t */}
                <button
                  onClick={() => setUiState(prev => ({ ...prev, showCreatePanel: false }))}
                  className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateAgent}
                  disabled={uiState.isCreating}
                  className="bg-[#004DB5] text-white px-6 py-2 rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center"
                >
                  {uiState.isCreating ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                      Creating...
                    </>
                  ) : (
                    'Create Agent'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add Goal Modal */}
      {uiState.showAddGoalPanel && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold" style={{ color: '#004DB5' }}>
                Add New Goal
              </h3>
              <button
                onClick={() => setUiState(prev => ({ ...prev, showAddGoalPanel: false }))}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
            <div className="space-y-4">
              <textarea
                value={newGoal}
                onChange={(e) => setNewGoal(e.target.value)}
                placeholder="Enter goal description"
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                rows={3}
              />
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setUiState(prev => ({ ...prev, showAddGoalPanel: false }))}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddGoal}
                  className="bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  Add Goal
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentConfigurationInterface;
