import { StreamResponse, ToolUsage } from '../types/chat';

export class StreamProcessor {
  private textDecoder: TextDecoder;
  private buffer: string;
  private partialMessage: string;

  constructor() {
    this.textDecoder = new TextDecoder();
    this.buffer = '';
    this.partialMessage = '';
  }

  processChunk(chunk: Uint8Array): StreamResponse[] {
    // Decode the chunk and add to buffer
    const text = this.textDecoder.decode(chunk);
    this.buffer += text;

    // Split by newlines and process each line
    const lines = this.buffer.split('\n');
    // Keep the last (potentially incomplete) line in the buffer
    this.buffer = lines.pop() || '';

    return lines
      .filter(line => line.trim())
      .map(line => {
        try {
          // Handle partial JSON messages
          if (this.partialMessage) {
            line = this.partialMessage + line;
            this.partialMessage = '';
          }

          // Remove 'data: ' prefix if present
          const jsonStr = line.startsWith('data: ') ? line.slice(6) : line;
          if (jsonStr === '[DONE]') return null;

          try {
            return JSON.parse(jsonStr) as StreamResponse;
          } catch (e) {
            // If JSON parsing fails, it might be a partial message
            this.partialMessage = line;
            return null;
          }
        } catch (e) {
          console.warn('Failed to parse stream line:', line);
          return null;
        }
      })
      .filter((response): response is StreamResponse => response !== null);
  }

  // Helper method to handle tool responses
  processToolResponse(response: StreamResponse): ToolUsage | null {
    if (!response.tools?.length) return null;

    return {
      name: response.tools[0],
      status: 'active',
      startTime: Date.now(),
      metadata: {
        source: 'stream',
        queryTime: 0,
        function_call: response.function_call
      }
    };
  }

  // Helper method to extract weather data
  extractWeatherData(response: StreamResponse): any {
    try {
      if (response.type === 'function' && response.name === 'get_weather') {
        const data = JSON.parse(response.output_data);
        return {
          current: data.current,
          forecast: data.forecast,
          location: data.location
        };
      }
      return null;
    } catch (e) {
      console.warn('Failed to extract weather data:', e);
      return null;
    }
  }

  // Clean up method
  reset() {
    this.buffer = '';
    this.partialMessage = '';
  }
} 