import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ArrowUp, Brain, Database, Wrench } from 'lucide-react';

// Dummy data for the chart
const data = [
  { name: '7 days ago', knowledge: 65, tools: 4, taskEfficiency: 70 },
  { name: '6 days ago', knowledge: 68, tools: 4, taskEfficiency: 72 },
  { name: '5 days ago', knowledge: 75, tools: 5, taskEfficiency: 75 },
  { name: '4 days ago', knowledge: 78, tools: 5, taskEfficiency: 78 },
  { name: '3 days ago', knowledge: 82, tools: 6, taskEfficiency: 80 },
  { name: '2 days ago', knowledge: 87, tools: 6, taskEfficiency: 83 },
  { name: 'Yesterday', knowledge: 90, tools: 7, taskEfficiency: 85 },
  { name: 'Today', knowledge: 95, tools: 8, taskEfficiency: 88 },
];

const SelfImprovementTracker: React.FC = () => {
  const latestData = data[data.length - 1];
  const previousData = data[data.length - 2];

  const calculateImprovement = (current: number, previous: number) => {
    return ((current - previous) / previous * 100).toFixed(1);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-4" style={{ color: '#004DB5' }}>
        Self-Improvement Tracker
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-[#D8E9FF] p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Brain className="text-[#004DB5] mr-2" size={24} />
              <h3 className="text-lg font-semibold" style={{ color: '#555555' }}>Knowledge</h3>
            </div>
            <span className="text-2xl font-bold" style={{ color: '#004DB5' }}>{latestData.knowledge}%</span>
          </div>
          <p className="text-sm mt-2" style={{ color: '#004DB5' }}>
            <ArrowUp size={16} className="inline mr-1" />
            {calculateImprovement(latestData.knowledge, previousData.knowledge)}% increase
          </p>
        </div>
        
        <div className="bg-[#D8E9FF] p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Wrench className="text-[#004DB5] mr-2" size={24} />
              <h3 className="text-lg font-semibold" style={{ color: '#555555' }}>Tools</h3>
            </div>
            <span className="text-2xl font-bold" style={{ color: '#004DB5' }}>{latestData.tools}</span>
          </div>
          <p className="text-sm mt-2" style={{ color: '#004DB5' }}>
            <ArrowUp size={16} className="inline mr-1" />
            {calculateImprovement(latestData.tools, previousData.tools)}% increase
          </p>
        </div>
        
        <div className="bg-[#D8E9FF] p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Database className="text-[#004DB5] mr-2" size={24} />
              <h3 className="text-lg font-semibold" style={{ color: '#555555' }}>Task Efficiency</h3>
            </div>
            <span className="text-2xl font-bold" style={{ color: '#004DB5' }}>{latestData.taskEfficiency}%</span>
          </div>
          <p className="text-sm mt-2" style={{ color: '#004DB5' }}>
            <ArrowUp size={16} className="inline mr-1" />
            {calculateImprovement(latestData.taskEfficiency, previousData.taskEfficiency)}% increase
          </p>
        </div>
      </div>
      
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="knowledge" fill="#004DB5" name="Knowledge" />
            <Bar dataKey="tools" fill="#555555" name="Tools" />
            <Bar dataKey="taskEfficiency" fill="#B2B2B2" name="Task Efficiency" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SelfImprovementTracker;
