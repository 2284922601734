import React from "react";
import { 
  FileText, 
  FileImage, 
  FileArchive, 
  FileSpreadsheet, 
  File as FileIcon, 
  Eye, 
  Edit, 
  Trash2 
} from 'lucide-react';
import { Document } from '../../types/document';

interface DocumentListProps {
  documents: Document[];
  onView: (doc: Document) => void;
  onUpdate: (id: string, metadata: any) => void;
  onDelete: (id: string) => void;
}

export const DocumentList: React.FC<DocumentListProps> = ({ 
  documents, 
  onView, 
  onUpdate, 
  onDelete 
}) => {
  const formatFileSize = (bytes: number | undefined): string => {
    if (!bytes || bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const getFileIcon = (type: string | undefined) => {
    switch (type?.toLowerCase()) {
      case "pdf":
        return <FileText className="h-5 w-5 text-[#004DB5]" />;
      case "docx":
      case "doc":
        return <FileText className="h-5 w-5 text-[#7D7D7D]" />;
      case "xlsx":
      case "xls":
      case "csv":
        return <FileSpreadsheet className="h-5 w-5 text-[#004DB5]" />;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        return <FileImage className="h-5 w-5 text-[#7D7D7D]" />;
      case "zip":
      case "rar":
        return <FileArchive className="h-5 w-5 text-[#004DB5]" />;
      default:
        return <FileIcon className="h-5 w-5 text-gray-500" />;
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Uploaded By
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Size
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {documents.map((doc) => (
            <tr key={doc.id}>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  {getFileIcon(doc.metadata.type)}
                  <span className="ml-2 truncate max-w-xs" title={doc.title}>
                    {doc.title}
                  </span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {doc.metadata.uploaded_by}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {doc.metadata.created_at ? 
                  new Date(doc.metadata.created_at).toLocaleDateString() : 
                  'Unknown date'
                }
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {formatFileSize(doc.metadata.size)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center space-x-2">
                  <div className="relative group">
                    <button
                      onClick={() => onView(doc)}
                      className="text-[#004DB5] hover:text-blue-700 p-1 rounded hover:bg-blue-50"
                      title="View document"
                    >
                      <Eye className="h-5 w-5" />
                    </button>
                    <div className="absolute top-full left-0 mt-2 max-w-xs bg-gray-800 text-white text-xs rounded p-2 invisible group-hover:visible z-50 shadow-lg">
                      <div className="whitespace-normal">
                        View document details
                      </div>
                    </div>
                  </div>
                  <div className="relative group">
                    <button
                      onClick={() => onUpdate(doc.id, { title: `${doc.title} (Edited)` })}
                      className="text-[#004DB5] hover:text-blue-700"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <div className="absolute top-full left-0 mt-2 max-w-xs bg-gray-800 text-white text-xs rounded p-2 invisible group-hover:visible z-50 shadow-lg">
                      <div className="whitespace-normal">
                        Edit document metadata
                      </div>
                    </div>
                  </div>
                  <div className="relative group">
                    <button
                      onClick={() => onDelete(doc.id)}
                      className="text-[#E53E3E] hover:text-red-700"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                    <div className="absolute top-full left-0 mt-2 max-w-xs bg-gray-800 text-white text-xs rounded p-2 invisible group-hover:visible z-50 shadow-lg">
                      <div className="whitespace-normal">
                        Permanently delete this document
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
