import { CommandType, ParsedCommand, CommandDefinition } from '../types/chat';
import { commandHandlers } from './commandHandlers';

// Command definitions with their handlers
const commandRegistry: Record<CommandType, CommandDefinition> = {
  '@run': {
    type: '@run',
    description: 'Execute a direct command with the agent',
    usage: '@run <command> [params]',
    examples: ['@run analyze_document', '@run process_data --format=json'],
    handler: commandHandlers.handleRunCommand
  },
  '@task': {
    type: '@task',
    description: 'Create and execute a specific task',
    usage: '@task <task_description>',
    examples: ['@task analyze market trends', '@task generate report'],
    handler: commandHandlers.handleTaskCommand
  },
  '@goal': {
    type: '@goal',
    description: 'Set a new goal for the agent',
    usage: '@goal <goal_description>',
    examples: ['@goal improve response accuracy', '@goal learn new dataset'],
    handler: commandHandlers.handleGoalCommand
  },
  '@command': {
    type: '@command',
    description: 'Execute a specific command',
    usage: '@command <command_name> [params]',
    examples: ['@command restart', '@command update --force'],
    handler: commandHandlers.handleRunCommand
  }
};

export class CommandParser {
  static isCommand(input: string): boolean {
    return input.trim().startsWith('@');
  }

  static parseCommand(input: string): ParsedCommand | null {
    const trimmed = input.trim();
    if (!this.isCommand(trimmed)) return null;

    // Split into command and content
    const parts = trimmed.split(' ');
    const commandType = parts[0] as CommandType;
    
    if (!commandRegistry[commandType]) return null;

    // Extract parameters if they exist (--param=value format)
    const content = parts.slice(1).filter(p => !p.startsWith('--')).join(' ');
    const paramParts = parts.filter(p => p.startsWith('--'));
    const params: Record<string, string> = {};

    paramParts.forEach(param => {
      const [key, value] = param.slice(2).split('=');
      if (key && value) params[key] = value;
    });

    return {
      type: commandType,
      content,
      params: Object.keys(params).length > 0 ? params : undefined
    };
  }

  static async executeCommand(parsedCommand: ParsedCommand, agentId: string): Promise<any> {
    const definition = commandRegistry[parsedCommand.type];
    if (!definition) {
      throw new Error(`Unknown command type: ${parsedCommand.type}`);
    }

    return definition.handler(parsedCommand, agentId);
  }

  static getCommandHelp(type: CommandType): CommandDefinition | null {
    return commandRegistry[type] || null;
  }

  static getAllCommands(): CommandDefinition[] {
    return Object.values(commandRegistry);
  }
}
