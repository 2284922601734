import React from 'react';
import { ChevronDown } from 'lucide-react';
import { useAgentContext } from '../../context/AgentContext';
import { chatApi } from '../../services/api/chatApi';
import toast from 'react-hot-toast';

interface AgentPersonaSelectorProps {
  isOpen: boolean;
  onToggle: () => void;
}

const AgentPersonaSelector: React.FC<AgentPersonaSelectorProps> = ({ isOpen, onToggle }) => {
  const { selectedAgent, setSelectedAgent, liveAgents } = useAgentContext();

  const handleAgentSelect = async (agentId: string) => {
    console.group('Agent Selection - Nav');
    console.log('Attempting to select agent:', {
      agentId,
      timestamp: new Date().toISOString()
    });

    try {
      const newAgent = liveAgents.find(agent => agent.id === agentId);
      
      if (newAgent) {
        console.log('Found agent:', {
          agent: newAgent,
          currentSelection: selectedAgent,
          timestamp: new Date().toISOString()
        });
        
        // Update global agent selection
        setSelectedAgent(newAgent);
        console.log('Agent selection updated successfully');

        // Start new conversation
        console.log('Starting new conversation with agent:', agentId);
        const conversation = await chatApi.startConversation(agentId);
        console.log('Conversation started:', conversation);

        // Dispatch a custom event to notify ChatInterface
        const event = new CustomEvent('agentConversationStarted', { 
          detail: { 
            agentId,
            conversationId: conversation.id,
            messages: conversation.messages || []
          }
        });
        window.dispatchEvent(event);

      } else {
        console.warn('Agent not found in live agents:', {
          searchId: agentId,
          availableAgents: liveAgents,
          timestamp: new Date().toISOString()
        });
        toast.error('Failed to select agent');
      }
    } catch (error) {
      console.error('Failed to initialize agent conversation:', error);
      toast.error('Failed to start conversation with agent');
    } finally {
      console.log('Closing dropdown');
      onToggle();
      console.groupEnd();
    }
  };

  console.log('AgentPersonaSelector Render:', {
    isOpen,
    selectedAgent,
    liveAgentsCount: liveAgents.length,
    timestamp: new Date().toISOString()
  });

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={onToggle}
        className="flex items-center space-x-3 px-4 py-2.5 text-sm bg-white border border-gray-200 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#004DB5] focus:ring-offset-2 transition-colors"
      >
        {selectedAgent ? (
          <>
            <div className="flex items-center space-x-2">
              <span className="w-2 h-2 rounded-full bg-green-500" />
              <span className="font-medium text-gray-900">{selectedAgent.name}</span>
            </div>
          </>
        ) : (
          <span className="text-gray-500">Choose an Agent</span>
        )}
        <ChevronDown className={`w-4 h-4 text-gray-400 transition-transform duration-200 ${
          isOpen ? 'transform rotate-180' : ''
        }`} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 border border-gray-100 z-10 overflow-hidden">
          <div className="max-h-[400px] overflow-y-auto">
            <div className="px-3 py-2 border-b border-gray-100">
              <h3 className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Available Agents
              </h3>
            </div>
            
            {[...liveAgents]
              .sort((a, b) => {
                if (!a.name && !b.name) return 0;
                if (!a.name) return 1;
                if (!b.name) return -1;
                return a.name.localeCompare(b.name);
              })
              .map((agent) => (
                <button
                  key={agent.id}
                  onClick={() => handleAgentSelect(agent.id)}
                  className={`w-full flex items-center px-4 py-3 hover:bg-gray-50 transition-colors ${
                    selectedAgent?.id === agent.id ? 'bg-blue-50' : ''
                  }`}
                >
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center space-x-2">
                      <span className={`w-2 h-2 rounded-full ${
                        selectedAgent?.id === agent.id ? 'bg-blue-500' : 'bg-green-500'
                      }`} />
                      <span className={`font-medium truncate ${
                        selectedAgent?.id === agent.id ? 'text-blue-700' : 'text-gray-900'
                      }`}>
                        {agent.name}
                      </span>
                    </div>
                    {agent.description && (
                      <p className="mt-0.5 text-xs text-gray-500 truncate">
                        {agent.description}
                      </p>
                    )}
                  </div>
                  {selectedAgent?.id === agent.id && (
                    <span className="ml-3 text-blue-600">
                      <span className="sr-only">Selected</span>
                      •
                    </span>
                  )}
                </button>
              ))}
            
            {liveAgents.length === 0 && (
              <div className="px-4 py-6 text-center">
                <div className="text-sm font-medium text-gray-500">
                  No Active Agents
                </div>
                <p className="mt-1 text-xs text-gray-400">
                  Please wait for agents to become available
                </p>
              </div>
            )}
            
            {selectedAgent && (
              <>
                <div className="border-t border-gray-100 my-1" />
                <button
                  onClick={() => {
                    setSelectedAgent(null);
                    onToggle();
                  }}
                  className="w-full px-4 py-3 text-left hover:bg-gray-50 flex items-center text-gray-600 hover:text-gray-900 transition-colors"
                >
                  <span className="text-sm">Reset Agent Selection</span>
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentPersonaSelector;
