import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { apiClient } from '../components/agent/AgentConfigurationInterface';

export interface Node {
  id: string;
  name: string;
  group: 'agent' | 'domain' | 'user-added' | 'predefined';
  val: number;
}

export interface AgentPersona {
  id: string;
  name: string;
  description: string;
  nodes: Node[];
  knowledgeDomains: string[];
  totalNodes: number;
  recentUpdates: number;
  customDocuments: number;
}

// Update Agent interface to be more specific
export interface LiveAgent {
  id: string;
  name: string;
  status: 'active' | 'inactive';
  description?: string;
  created_at?: string | null;
  updated_at?: string | null;
}

// Procurement persona
const procurementPersona: AgentPersona = {
  id: 'procurement_analyst_001',
  name: 'Procurement Analyst',
  description: 'Specialized in supply chain and procurement',
  nodes: [],
  knowledgeDomains: ['Supply Chain', 'Vendor Management'],
  totalNodes: 1500,
  recentUpdates: 75,
  customDocuments: 3,
};

// LLM Expert persona
const llmExpertPersona: AgentPersona = {
  id: 'llm_expert_001',
  name: 'LLM Expert',
  description: 'Specialized in large language models and AI',
  nodes: [],
  knowledgeDomains: ['Natural Language Processing', 'Machine Learning'],
  totalNodes: 2000,
  recentUpdates: 100,
  customDocuments: 5,
};

// Hedge Fund Research Analyst persona
const hedgeFundAnalystPersona: AgentPersona = {
  id: 'hedge_fund_research_analyst_long_short_event_driven_quantamental_USA_stat_arb_001',
  name: 'Hedge Fund Research Analyst',
  description: 'Specialized in long/short, event-driven, quantamental, and statistical arbitrage strategies',
  nodes: [],
  knowledgeDomains: ['Financial Markets', 'Quantitative Analysis', 'Event-Driven Strategies'],
  totalNodes: 2500,
  recentUpdates: 150,
  customDocuments: 10,
};

// Licensed Custom Broker persona
const customBrokerPersona: AgentPersona = {
  id: 'licensed_custom_broker_agent_that_passed_the_test_with_a_perfect_score_001',
  name: 'Licensed Custom Broker',
  description: 'Expert in customs regulations and international trade',
  nodes: [],
  knowledgeDomains: ['Customs Regulations', 'International Trade'],
  totalNodes: 1800,
  recentUpdates: 90,
  customDocuments: 7,
};

// Macroeconomic Expert persona
const macroeconomicExpertPersona: AgentPersona = {
  id: 'macroeconomic_expert_001',
  name: 'Macroeconomic Expert',
  description: 'Specialized in global economic trends and analysis',
  nodes: [],
  knowledgeDomains: ['Global Economics', 'Monetary Policy', 'Economic Forecasting'],
  totalNodes: 2200,
  recentUpdates: 120,
  customDocuments: 8,
};

// Export personas for usage
export const personas = [
  procurementPersona,
  llmExpertPersona,
  hedgeFundAnalystPersona,
  customBrokerPersona,
  macroeconomicExpertPersona
];

interface AgentContextType {
  selectedPersona: AgentPersona | null;
  setSelectedPersona: React.Dispatch<React.SetStateAction<AgentPersona | null>>;
  personas: AgentPersona[];
  
  liveAgents: LiveAgent[];
  selectedAgent: LiveAgent | null;
  setSelectedAgent: React.Dispatch<React.SetStateAction<LiveAgent | null>>;
  refreshLiveAgents: () => Promise<void>;
}

const AgentContext = createContext<AgentContextType | undefined>(undefined);

export const AgentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedPersona, setSelectedPersona] = useState<AgentPersona | null>(null);
  const [liveAgents, setLiveAgents] = useState<LiveAgent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<LiveAgent | null>(() => {
    // Try to load the previously selected agent from localStorage
    const savedAgent = localStorage.getItem('selectedAgent');
    return savedAgent ? JSON.parse(savedAgent) : null;
  });
  
  // Add refresh function for live agents
  const refreshLiveAgents = async () => {
    try {
      const agents = await apiClient.listAgents();
      const activeAgents = agents.filter((agent: LiveAgent) => 
        agent && agent.id && agent.status === 'active'
      );
      
      setLiveAgents(prev => {
        const hasChanged = JSON.stringify(prev) !== JSON.stringify(activeAgents);
        if (hasChanged) {
          // If we have a selected agent, make sure it's still in the active agents list
          if (selectedAgent && selectedAgent.id) {
            const agentStillActive = activeAgents.some(
              (agent: LiveAgent) => agent && agent.id === selectedAgent.id
            );
            if (!agentStillActive) {
              setSelectedAgent(null);
              localStorage.removeItem('selectedAgent');
            }
          }
          return activeAgents;
        }
        return prev;
      });
    } catch (error) {
      console.error('Failed to fetch live agents:', error);
    }
  };

  // Add effect to persist selected agent
  useEffect(() => {
    if (selectedAgent) {
      localStorage.setItem('selectedAgent', JSON.stringify(selectedAgent));
    } else {
      localStorage.removeItem('selectedAgent');
    }
  }, [selectedAgent]);

  // Initial fetch of live agents
  useEffect(() => {
    refreshLiveAgents();
    // Set up polling interval
    const interval = setInterval(refreshLiveAgents, 3000); // Poll every 3 seconds
    
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AgentContext.Provider value={{ 
      selectedPersona, 
      setSelectedPersona, 
      personas,
      liveAgents,
      selectedAgent,
      setSelectedAgent,
      refreshLiveAgents
    }}>
      {children}
    </AgentContext.Provider>
  );
};

export const useAgentContext = () => {
  const context = useContext(AgentContext);
  if (context === undefined) {
    throw new Error('useAgentContext must be used within an AgentProvider');
  }
  return context;
};
