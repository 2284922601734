export type CommandType = '@run' | '@task' | '@goal' | '@command';

export interface ParsedCommand {
  type: CommandType;
  content: string;
  params?: Record<string, string>;
}

export interface CommandDefinition {
  type: CommandType;
  description: string;
  usage: string;
  examples: string[];
  handler: (command: ParsedCommand, agentId: string) => Promise<any>;
}

export interface MessageTypeStyles {
  CHAT: string;
  GOAL: string;
  TASK: string;
  COMMAND: string;
  RUN: string;
  TEXT: string;
  FILE: string;
}

// Color scheme based on our style guide
export const messageTypeStyles: MessageTypeStyles = {
  CHAT: 'bg-[#E6F0FF]',    // Lightest blue
  GOAL: 'bg-[#CCE0FF]',    // Light blue
  TASK: 'bg-[#B3D1FF]',    // Medium blue
  COMMAND: 'bg-[#99C2FF]', // Darker blue
  RUN: 'bg-[#80B3FF]',     // Darkest blue
  TEXT: 'bg-[#E6F0FF]',    // Same as CHAT
  FILE: 'bg-[#E6F0FF]'     // Same as CHAT
};

export interface GenerateRequest {
  messages: Array<{
    role: "system" | "user" | "assistant" | "function";
    content: string;
  }>;
  model: string;
  provider: string;
  agent_id: string;
  max_tokens?: number;
  logprobs?: boolean;
  top_logprobs?: number;
  n?: number;
  stream: boolean;
  temperature?: number;
  top_p?: number;
  presence_penalty?: number;
  frequency_penalty?: number;
  stop?: string;
  tools?: any[];
  tool_choice?: string;
  response_format?: any;
  conversation_id?: string;
}

export type StreamStage = 'thinking' | 'processing' | 'generating' | 'complete';

export interface StreamProcessingState {
  stage: StreamStage;
  progress: number;
  toolCalls: ToolUsage[];
  activeTools: ToolUsage[];
  startTime: number;
  currentAction?: string;
}

export interface ToolUsage {
  name: string;
  input?: string;
  output?: string;
  status?: 'active' | 'complete' | 'error' | 'available';
  startTime?: number;
  endTime?: number;
  error?: string;
  metadata?: {
    source?: string;
    queryTime?: number;
    function_call?: {
      name: string;
      arguments: string;
    };
  };
}

export interface ApiChatMessage {
  id: string;
  role: 'user' | 'assistant' | 'system' | 'tool';
  content: string;
  timestamp: string;
  type?: string;
  toolUsage?: ToolUsage[];
  metadata?: {
    command?: CommandType;
    status?: string;
    [key: string]: any;
  };
}

export interface SavedChat {
  id: string;
  title: string;
  agent_id: string;
  messages: ApiChatMessage[];
  created_at: string;
  updated_at: string;
}

export interface StreamResponse {
  type: string;
  content?: string;
  messages?: string;
  tools?: string[];
  error?: string;
  function?: string;
  result?: {
    input?: any;
    output?: any;
  };
  function_call?: {
    name: string;
    arguments: string;
  };
  name?: string;
  output_data?: any;
}

export interface MessageProps {
  message: ApiChatMessage;
}
