export class CommandStatusTracker {
  private checkInterval: number;
  private timeoutDuration: number;
  private intervalId: NodeJS.Timeout | null = null;

  constructor(
    private commandId: string,
    private onStatusUpdate: (status: string, result?: any) => void,
    interval: number = 2000,
    timeout: number = 30000
  ) {
    this.checkInterval = interval;
    this.timeoutDuration = timeout;
  }

  async start() {
    const startTime = Date.now();

    this.intervalId = setInterval(async () => {
      try {
        const status = await this.checkCommandStatus();
        
        if (status.completed || Date.now() - startTime > this.timeoutDuration) {
          this.stop();
          this.onStatusUpdate(status.status, status.result);
        } else {
          this.onStatusUpdate(status.status);
        }
      } catch (error) {
        console.error('Command status check failed:', error);
        this.stop();
        this.onStatusUpdate('error');
      }
    }, this.checkInterval);
  }

  private async checkCommandStatus(): Promise<CommandStatus> {
    // Implementation depends on your API
    return { status: 'pending', completed: false, result: null };
  }

  stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}

interface CommandStatus {
  status: string;
  completed: boolean;
  result?: any;
}
