/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Send, Paperclip, Plus, Menu, ChevronDown, X, Bug } from 'lucide-react';
import { chatApi } from '../../services/api/chatApi';
import { CommandParser } from '../../services/commandParser';
import { MessagePoller } from '../../services/messagePoller';
import { CommandStatusTracker } from '../../services/commandStatusTracker';
import { useAgentContext } from '../../context/AgentContext';
import Message from './Message';
import CommandPalette from './CommandPalette';
import toast from 'react-hot-toast';
import { generateApi } from '../../services/api/generateApi';
import { 
  GenerateRequest,
  StreamProcessingState, 
  ToolUsage, 
  StreamStage,
  ApiChatMessage
} from '../../types/chat';
import { StreamProcessor } from '../../services/streamProcessor';

// Remove duplicate SavedChat interface since it's now in types/chat.ts

// Add TextDecoder instance
const textDecoder = new TextDecoder();

// Update interfaces
interface SavedChat {
  id: string;
  title: string;
  agent_id: string;
  messages: ApiChatMessage[];
  created_at: string;
  updated_at: string;
}

interface ChatState {
  currentAgentId: string | null;
  conversationId: string | null;
  messages: ApiChatMessage[];
  isLoading: boolean;
  error: string | null;
  savedChats: SavedChat[];
  currentChatTitle: string;
  conversations: SavedChat[];
  debugMode: boolean;
  streamState?: StreamProcessingState;
}

interface ChatUIState {
  isSidebarOpen: boolean;
  isCommandPaletteOpen: boolean;
  input: string;
  isProcessing: boolean;
}

// Add these types at the top of the file
interface StreamResponse {
  type: string;
  content?: string;
  messages?: string;
  tools?: string[];
  error?: string;
}

// Add this utility function at the top of the file
const generateMessageId = () => {
  return `msg_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
};

// Add this utility function
const parseStreamChunk = (chunk: string) => {
  try {
    // Split by newlines and filter empty lines
    const lines = chunk.split('\n').filter(line => line.trim());

    // Process each line
    return lines.map(line => {
      try {
        return JSON.parse(line);
      } catch (e) {
        console.warn('Failed to parse line:', line);
        return null;
      }
    }).filter(Boolean);
  } catch (e) {
    console.warn('Failed to process chunk:', chunk);
    return [];
  }
};

// Add conversation management functions at the top of the component
const loadConversations = (agentId: string) => {
  try {
    const savedConvos = JSON.parse(localStorage.getItem(`conversations_${agentId}`) || '[]');
    return savedConvos.sort((a: any, b: any) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    );
  } catch (error) {
    console.error('Failed to load conversations:', error);
    return [];
  }
};

// Add interface for conversations
interface Conversation {
  id: string;
  title: string;
  agent_id: string;
  messages: ApiChatMessage[];
  created_at: string;
  updated_at: string;
}

// Update saveConversation function
const saveConversation = (conversation: SavedChat): SavedChat[] => {
  try {
    // Get existing conversations for this agent
    const conversations = JSON.parse(
      localStorage.getItem(`conversations_${conversation.agent_id}`) || '[]'
    ) as SavedChat[];

    // Update or add the conversation
    const existingIndex = conversations.findIndex(c => c.id === conversation.id);

    if (existingIndex >= 0) {
      conversations[existingIndex] = {
        ...conversation,
        updated_at: new Date().toISOString()
      };
    } else {
      conversations.unshift({
        ...conversation,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      });
    }

    // Save back to localStorage
    localStorage.setItem(
      `conversations_${conversation.agent_id}`,
      JSON.stringify(conversations)
    );

    return conversations;
  } catch (error) {
    console.error('Failed to save conversation:', error);
    return [];
  }
};

// Add this helper function at the top
const generateConversationTitle = (messages: ApiChatMessage[]): string => {
  const firstUserMessage = messages.find(msg => msg.role === 'user');
  return firstUserMessage 
    ? firstUserMessage.content.slice(0, 30) + (firstUserMessage.content.length > 30 ? '...' : '')
    : 'New Conversation';
};

// Update the formatDate function
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  // Format time in 12-hour format
  const timeString = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });

  // If it's today, show "Today at [time]"
  if (date.toDateString() === now.toDateString()) {
    return `Today at ${timeString}`;
  }
  // If it's yesterday, show "Yesterday at [time]"
  else if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday at ${timeString}`;
  }
  // If it's this year, show "[Month Day] at [time]"
  else if (date.getFullYear() === now.getFullYear()) {
    const dateStr = date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
    return `${dateStr} at ${timeString}`;
  }
  // Otherwise show full date and time
  const dateStr = date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
  return `${dateStr} at ${timeString}`;
};

// Update the ConversationListItem component
const ConversationListItem: React.FC<{
  conversation: SavedChat;
  isActive: boolean;
  onSelect: () => void;
  onDelete: () => void;
  agentName?: string;
}> = ({ conversation, isActive, onSelect, onDelete, agentName }) => (
  <div 
    className={`flex flex-col p-3 cursor-pointer border-b border-gray-200 ${
      isActive ? 'bg-blue-50' : 'hover:bg-gray-50'
    }`}
    onClick={onSelect}
  >
    <div className="flex justify-between items-start">
      <div className="flex-1 min-w-0">
        <h3 className="text-sm font-medium text-gray-900 truncate">
          {conversation.title || 'New Conversation'}
        </h3>
        <div className="flex items-center mt-1">
          {agentName && (
            <span className="flex items-center text-xs text-gray-500">
              <span className="w-2 h-2 bg-green-400 rounded-full mr-1"></span>
              {agentName}
            </span>
          )}
          <span className="text-xs text-gray-400 ml-2">
            {new Date(conversation.updated_at).toLocaleString()}
          </span>
        </div>
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
        className="p-1 text-gray-400 hover:text-red-500"
      >
        <X size={16} />
      </button>
    </div>
    {conversation.messages.length > 0 && (
      <p className="text-xs text-gray-500 mt-1 truncate">
        {conversation.messages[conversation.messages.length - 1].content}
      </p>
    )}
  </div>
);

const DebugPanel: React.FC<{
  streamState?: StreamProcessingState;
  onClose: () => void;
}> = ({ streamState, onClose }) => {
  if (!streamState) return null;

  const duration = Date.now() - streamState.startTime;

  return (
    <div className="fixed bottom-4 right-4 w-80 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden">
      <div className="flex items-center justify-between p-3 bg-gray-50 border-b">
        <div className="flex items-center space-x-2">
          <Bug className="w-4 h-4 text-gray-600" />
          <span className="text-sm font-medium text-gray-700">Debug Info</span>
        </div>
        <button 
          onClick={onClose}
          className="text-gray-400 hover:text-gray-600 transition-colors"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
      
      <div className="p-4 space-y-3 text-sm">
        <div className="space-y-1">
          <div className="text-gray-500">Current Stage</div>
          <div className="font-mono text-gray-700">{streamState.stage}</div>
        </div>
        
        <div className="space-y-1">
          <div className="text-gray-500">Progress</div>
          <div className="font-mono text-gray-700">{streamState.progress}%</div>
        </div>
        
        <div className="space-y-1">
          <div className="text-gray-500">Duration</div>
          <div className="font-mono text-gray-700">{duration}ms</div>
        </div>
        
        <div className="space-y-1">
          <div className="text-gray-500">Active Tools</div>
          <div className="font-mono text-gray-700 space-y-1">
            {streamState.activeTools.map((tool: ToolUsage, index: number) => (
              <div key={index} className="flex justify-between">
                <span>{tool.name}</span>
                <span>{tool.status}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this near the top with other constants
const streamProcessor = new StreamProcessor();

const ChatInterface: React.FC = () => {
  // Replace availableAgents state with context
  const { liveAgents, selectedAgent, setSelectedAgent } = useAgentContext();

  // Remove this effect since we're using context now
  // useEffect(() => {
  //   const fetchLiveAgents = async () => {...}
  //   ...
  // }, []);

  // Initialize chatState with empty arrays
  const [chatState, setChatState] = useState<ChatState>({
    currentAgentId: selectedAgent?.id || null,  // Use selectedAgent from context
    conversationId: null,
    messages: [],
    isLoading: false,
    error: null,
    savedChats: [],
    currentChatTitle: '',
    conversations: [],
    debugMode: false,
    streamState: undefined
  });

  // Add ref for messages container
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Add scroll to bottom function
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Add effect to scroll on new messages
  useEffect(() => {
    scrollToBottom();
  }, [chatState.messages]); // Scroll whenever messages change

  // Add effect to sync chatState with selectedAgent
  useEffect(() => {
    if (selectedAgent) {
      console.log('Syncing chat state with selected agent:', selectedAgent);

      // Load existing conversations first
      const savedConvos = loadConversations(selectedAgent.id);

      // If there are existing conversations, use the most recent one
      if (savedConvos.length > 0) {
        const mostRecentConvo = savedConvos[0]; // Assuming they're sorted by date
        console.log('Using existing conversation:', mostRecentConvo);

        setChatState(prev => ({
          ...prev,
          currentAgentId: selectedAgent.id,
          conversationId: mostRecentConvo.id,
          messages: mostRecentConvo.messages,
          conversations: savedConvos
        }));
      } else {
        // If no existing conversations, start a new one
        console.log('Starting new conversation for agent:', selectedAgent.id);
        chatApi.startConversation(selectedAgent.id)
          .then(conversation => {
            console.log('New conversation started:', conversation);
            setChatState(prev => ({
              ...prev,
              currentAgentId: selectedAgent.id,
              conversationId: conversation.id,
              messages: [],
              conversations: [{
                id: conversation.id,
                title: 'New Conversation',
                agent_id: selectedAgent.id,
                messages: [],
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString()
              }]
            }));
          })
          .catch(error => {
            console.error('Failed to start conversation:', error);
            toast.error('Failed to start conversation');
          });
      }
    }
  }, [selectedAgent]);

  // Add proper types to refs
  const messagePollerRef = useRef<MessagePoller | null>(null);
  const commandTrackerRef = useRef<CommandStatusTracker | null>(null);

  // Add proper types to handlers
  const handleSelectConversation = async (conversationId: string) => {
    setLoadingStates(prev => ({ ...prev, conversationLoading: true }));

    try {
      console.log('Selecting conversation:', conversationId);
      const conversation = chatState.conversations.find(c => c.id === conversationId);

      if (conversation) {
        console.log('Found conversation:', conversation);
        setChatState(prev => ({
          ...prev,
          conversationId: conversation.id,
          messages: conversation.messages || [],
          currentChatTitle: conversation.title || 'New Conversation',
          error: null
        }));
      }
    } catch (error) {
      console.error('Failed to select conversation:', error);
      toast.error('Failed to load conversation');
      setChatState(prev => ({ ...prev, error: 'Failed to load conversation' }));
    } finally {
      setLoadingStates(prev => ({ ...prev, conversationLoading: false }));
    }
  };

  // Command detection and handling
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUiState(prev => ({
      ...prev,
      input: value,
      isCommandPaletteOpen: value.startsWith('@')
    }));
  };

  // Message sending logic
  const handleSend = async () => {
    if (!uiState.input.trim() || !chatState.conversationId) return;

    const input = uiState.input.trim();
    setUiState(prev => ({ ...prev, input: '', isProcessing: true }));

    try {
      if (CommandParser.isCommand(input)) {
        await handleCommand(input);
      } else {
        await handleRegularMessage(input);
      }
    } catch (error) {
      console.error('Failed to send message:', error);
      toast.error('Failed to send message');
    } finally {
      setUiState(prev => ({ ...prev, isProcessing: false }));
    }
  };

  // Command handling
  const handleCommand = async (input: string) => {
    const parsedCommand = CommandParser.parseCommand(input);
    if (!parsedCommand || !chatState.currentAgentId) return;

    try {
      // Add user command message
      const userCommandMessage: ApiChatMessage = {
        id: Date.now().toString(),
        content: input,
        role: 'user',
        type: parsedCommand.type.slice(1) as any,
        timestamp: new Date().toISOString(),
        metadata: {
          command: parsedCommand.type,
          ...parsedCommand.params
        }
      };

      setChatState(prev => ({
        ...prev,
        messages: [...prev.messages, userCommandMessage]
      }));

      // Execute command and start tracking - Pass the agentId
      const result = await CommandParser.executeCommand(parsedCommand, chatState.currentAgentId);

      if (commandTrackerRef.current) {
        commandTrackerRef.current.stop();
      }
      commandTrackerRef.current = new CommandStatusTracker(
        result.id,
        (status, finalResult) => {
          if (finalResult) {
            // Add final response message
            const responseMessage: ApiChatMessage = {
              id: Date.now().toString(),
              content: finalResult,
              role: 'assistant',
              type: parsedCommand.type.slice(1) as any,
              timestamp: new Date().toISOString(),
              metadata: {
                command: parsedCommand.type,
                status: 'completed'
              }
            };
            setChatState(prev => ({
              ...prev,
              messages: [...prev.messages, responseMessage]
            }));
          } else {
            // Update status in UI
            setUiState(prev => ({
              ...prev,
              commandStatus: status
            }));
          }
        }
      );
      commandTrackerRef.current.start();

    } catch (error) {
      console.error('Command execution failed:', error);
      toast.error('Failed to execute command');

      // Add error message to chat
      const errorMessage: ApiChatMessage = {
        id: Date.now().toString(),
        content: 'Failed to execute command. Please try again.',
        role: 'assistant',
        type: 'TEXT',
        timestamp: new Date().toISOString(),
        metadata: {
          command: parsedCommand.type,
          status: 'error'
        }
      };

      setChatState(prev => ({
        ...prev,
        messages: [...prev.messages, errorMessage]
      }));
    }
  };

  // Update the handleStreamUpdate function
  const handleStreamUpdate = (
    content: string,
    messageId: string,
    isFirstChunk: boolean,
    previousMessages: ApiChatMessage[]
  ) => {
    console.log('Updating stream with content:', content);

    setChatState(prev => {
      // Create the new message
      const assistantMessage: ApiChatMessage = {
        id: messageId,
        content: content.trim(),
        role: 'assistant',
        type: 'TEXT',
        timestamp: new Date().toISOString()
      };

      // If this is the first chunk, add new message
      // Otherwise, update existing message
      const updatedMessages = isFirstChunk
        ? [...previousMessages, assistantMessage]
        : prev.messages.map(msg =>
            msg.id === messageId ? assistantMessage : msg
          );

      // Create updated conversation object
      const updatedConversation: SavedChat = {
        id: prev.conversationId!,
        title: prev.currentChatTitle,
        agent_id: prev.currentAgentId!,
        messages: updatedMessages,
        created_at: prev.conversations.find(c => c.id === prev.conversationId)?.created_at || new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      // Save conversation
      const updatedConvos = saveConversation(updatedConversation);

      return {
        ...prev,
        messages: updatedMessages,
        conversations: updatedConvos,
        isLoading: false
      };
    });

    // Force scroll after state update
    setTimeout(() => {
      if (scrollToBottom) {
        scrollToBottom();
      }
    }, 0);
  };

  // Update handleRegularMessage to use the moved handleStreamUpdate
  const handleRegularMessage = async (input: string) => {
    if (!chatState.currentAgentId || !chatState.conversationId) return;

    try {
      setIsAgentTyping(true);
      const timestamp = new Date().toISOString();
      const userMessageId = generateMessageId();
      const assistantMessageId = generateMessageId();

      // Create user message
      const userMessage: ApiChatMessage = {
        id: userMessageId,
        content: input,
        role: 'user',
        type: 'TEXT',
        timestamp
      };

      // Update UI with user message and save conversation
      setChatState(prev => {
        const updatedMessages = [...prev.messages, userMessage];
        
        // Only generate title for new conversations
        const title = prev.messages.length === 0 
          ? generateConversationTitle([userMessage])
          : prev.currentChatTitle || 'New Conversation';

        const updatedConversation: SavedChat = {
          id: prev.conversationId!,
          title,
          agent_id: prev.currentAgentId!,
          messages: updatedMessages,
          created_at: prev.conversations.find(c => c.id === prev.conversationId)?.created_at || timestamp,
          updated_at: timestamp
        };

        const updatedConvos = saveConversation(updatedConversation);

        return {
          ...prev,
          messages: updatedMessages,
          conversations: updatedConvos,
          currentChatTitle: title,
          isLoading: true,
          streamState: {
            stage: 'thinking' as StreamStage,
            progress: 0,
            toolCalls: [],
            activeTools: [],
            startTime: Date.now()
          }
        };
      });

      // Prepare generate request
      const generateRequest: GenerateRequest = {
        messages: [{
          role: "user",
          content: input
        }],
        model: "gpt-4o",
        provider: "openai",
        agent_id: chatState.currentAgentId,
        max_tokens: 500,
        stream: true,
        temperature: 1,
        tools: [],
        tool_choice: "auto",
        response_format: { type: "text" }
      };

      // Call generate endpoint
      console.log('Calling agent/generate with request:', generateRequest);
      const stream = await generateApi.agentGenerate(generateRequest);
      const reader = stream.getReader();
      let assistantContent = '';

      try {
        streamProcessor.reset();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const responses = streamProcessor.processChunk(value);
          
          for (const response of responses) {
            console.log('Stream response:', response);

            if (response.type === 'error') {
              handleStreamError(response.error, chatState.streamState?.activeTools || []);
              break;
            }

            // Handle tools found
            if (response.type === 'tools_found' && response.tools) {
              const toolsMessage: ApiChatMessage = {
                id: generateMessageId(),
                content: 'Available tools:',
                role: 'assistant',
                type: 'TOOL_LIST',
                timestamp: new Date().toISOString(),
                toolUsage: response.tools.map(tool => ({
                  name: formatToolName(tool),
                  status: 'available',
                  startTime: Date.now()
                }))
              };

              setChatState(prev => ({
                ...prev,
                messages: [...prev.messages, toolsMessage]
              }));
            }

            // Handle function results
            if (response.type === 'function_result') {
              const toolName = formatToolName(response.function || 'unknown');
              const result = response.result;
              
              // Create tool result message
              const toolResultMessage: ApiChatMessage = {
                id: generateMessageId(),
                content: result?.output || `Results from ${toolName}:`,
                role: 'assistant',
                type: 'TOOL_RESULT',
                timestamp: new Date().toISOString(),
                toolUsage: [{
                  name: toolName,
                  status: 'complete',
                  startTime: Date.now(),
                  endTime: Date.now(),
                  input: JSON.stringify(result?.input || {}, null, 2),
                  output: JSON.stringify(result?.output || {}, null, 2)
                }]
              };

              setChatState(prev => ({
                ...prev,
                messages: [...prev.messages, toolResultMessage]
              }));
            }

            // Handle assistant messages
            if (response.type === 'assistant_message' && response.content !== undefined) {
              console.log('Assistant message:', response.content);
              assistantContent += response.content;

              setChatState(prev => {
                const assistantMessage: ApiChatMessage = {
                  id: assistantMessageId,
                  content: assistantContent.trim(),
                  role: 'assistant',
                  type: 'TEXT',
                  timestamp: new Date().toISOString()
                };

                const existingMessageIndex = prev.messages.findIndex(msg => msg.id === assistantMessageId);
                const updatedMessages = existingMessageIndex === -1
                  ? [...prev.messages, assistantMessage]
                  : prev.messages.map((msg, i) => i === existingMessageIndex ? assistantMessage : msg);

                // Save conversation with updated messages while preserving the title
                const updatedConversation: SavedChat = {
                  id: prev.conversationId!,
                  title: prev.currentChatTitle || generateConversationTitle([...prev.messages, assistantMessage]),
                  agent_id: prev.currentAgentId!,
                  messages: updatedMessages,
                  created_at: prev.conversations.find(c => c.id === prev.conversationId)?.created_at || timestamp,
                  updated_at: new Date().toISOString()
                };

                const updatedConvos = saveConversation(updatedConversation);

                return {
                  ...prev,
                  messages: updatedMessages,
                  conversations: updatedConvos,
                  currentChatTitle: updatedConversation.title
                };
              });
            }
          }
        }
      } finally {
        reader.releaseLock();
        streamProcessor.reset();
        setIsAgentTyping(false);
        setChatState(prev => ({
          ...prev,
          isLoading: false,
          streamState: {
            ...prev.streamState!,
            progress: 100,
            stage: 'complete'
          }
        }));
      }
    } catch (error) {
      console.error('Failed to send message:', error);
      toast.error('Failed to send message');
    }
  };

  // Add this helper function for error handling
  const handleStreamError = (error: string | undefined, currentTools: ToolUsage[]) => {
    console.error('Error from API:', error);
    
    const errorTool: ToolUsage = {
      name: currentTools.length > 0 ? currentTools[currentTools.length - 1].name : 'unknown',
      status: 'error',
      startTime: Date.now(),
      endTime: Date.now(),
      error: error
    };
    
    updateStreamState(
      'processing',
      'Error occurred while processing',
      errorTool
    );

    const errorMessage: ApiChatMessage = {
      id: generateMessageId(),
      content: 'I encountered an error while trying to get that information. Please try again later.',
      role: 'assistant',
      type: 'TEXT',
      timestamp: new Date().toISOString(),
      toolUsage: [...currentTools, errorTool]
    };

    setChatState(prev => ({
      ...prev,
      messages: [...prev.messages, errorMessage]
    }));

    toast.error('Unable to fetch weather information at the moment');
  };

  // Clean up polling on unmount
  useEffect(() => {
    return () => {
      // Copy refs to variables inside effect
      const currentMessagePoller = messagePollerRef.current;
      const currentCommandTracker = commandTrackerRef.current;

      if (currentMessagePoller) {
        currentMessagePoller.stop();
      }
      if (currentCommandTracker) {
        currentCommandTracker.stop();
      }
    };
  }, []);

  // Add this effect near the top of the component
  useEffect(() => {
    const handleConversationStarted = (event: CustomEvent) => {
      const { agentId, conversationId, messages } = event.detail;
      console.log('Received conversation started event:', { agentId, conversationId, messages });

      setChatState(prev => ({
        ...prev,
        currentAgentId: agentId,
        conversationId: conversationId,
        messages: messages
      }));
    };

    window.addEventListener('agentConversationStarted', handleConversationStarted as EventListener);

    return () => {
      window.removeEventListener('agentConversationStarted', handleConversationStarted as EventListener);
    };
  }, []);

  // Add this state for typing indicator
  const [isAgentTyping, setIsAgentTyping] = useState(false);

  // Add this effect to load saved chats
  useEffect(() => {
    const loadSavedChats = () => {
      try {
        const savedChats = JSON.parse(localStorage.getItem('savedChats') || '[]');
        setChatState(prev => ({
          ...prev,
          savedChats
        }));
      } catch (error) {
        console.error('Failed to load saved chats:', error);
      }
    };

    loadSavedChats();
  }, []);

  // Add effect to load conversations when agent changes
  useEffect(() => {
    if (selectedAgent?.id) {
      const savedConvos = loadConversations(selectedAgent.id);
      setChatState(prev => ({
        ...prev,
        conversations: savedConvos
      }));
    }
  }, [selectedAgent?.id]);

  // Add new conversation handler
  const handleNewChat = async () => {
    if (!chatState.currentAgentId) return;

    try {
      console.log('Starting new conversation');
      const conversation = await chatApi.startConversation(chatState.currentAgentId);

      const newChat: SavedChat = {
        id: conversation.id,
        title: 'New Conversation',
        agent_id: chatState.currentAgentId,
        messages: [],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      // Update state with new conversation
      setChatState(prev => ({
        ...prev,
        conversationId: newChat.id,
        messages: [],
        currentChatTitle: newChat.title,
        conversations: [newChat, ...prev.conversations]
      }));

      // Save to localStorage
      saveConversation(newChat);

      console.log('New conversation created:', newChat);
    } catch (error) {
      console.error('Failed to create new conversation:', error);
      toast.error('Failed to create new conversation');
    }
  };

  // Add cleanup function for old conversations
  const cleanupOldConversations = (conversations: SavedChat[], maxConversations = 50) => {
    if (conversations.length <= maxConversations) return conversations;

    // Sort by updated_at and keep only the most recent ones
    return conversations
      .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
      .slice(0, maxConversations);
  };

  // Add error recovery function
  const handleErrorRecovery = async () => {
    if (!chatState.currentAgentId || !chatState.conversationId) return;

    try {
      // Attempt to reload the current conversation
      const conversations = loadConversations(chatState.currentAgentId);
      const currentConversation = conversations.find((c: SavedChat) => c.id === chatState.conversationId);

      if (currentConversation) {
        setChatState(prev => ({
          ...prev,
          messages: currentConversation.messages,
          conversations,
          error: null
        }));
      } else {
        // If conversation not found, create a new one
        await handleNewChat();
      }
    } catch (error) {
      console.error('Error recovery failed:', error);
      toast.error('Failed to recover conversation state');
    }
  };

  // Add loading state management
  const [loadingStates, setLoadingStates] = useState({
    conversationLoading: false,
    messageSending: false,
    agentSelecting: false
  });

  // Fix the conversations list mapping
  const renderConversationsList = () => (
    <div className="p-4 space-y-2">
      {(chatState.conversations || [])
        // Only show conversations that have messages
        .filter((conversation: SavedChat) =>
          conversation.messages && conversation.messages.length > 0
        )
        .map((conversation: SavedChat) => {
          // Find the agent for this conversation
          const agent = liveAgents.find(a => a.id === conversation.agent_id);

          return (
            <ConversationListItem
              key={conversation.id}
              conversation={conversation}
              isActive={conversation.id === chatState.conversationId}
              onSelect={() => handleSelectConversation(conversation.id)}
              onDelete={() => handleDeleteConversation(conversation.id)}
              agentName={agent?.name}
            />
          );
        })}
    </div>
  );

  // Fix the unsafe reference to assistantContent in the loop
  const handleStreamResponse = useCallback((
    content: string,
    messageId: string,
    messages: ApiChatMessage[]
  ) => {
    setChatState(prev => ({
      ...prev,
      messages: prev.messages.map(msg =>
        msg.id === messageId ? { ...msg, content } : msg
      )
    }));
  }, []);

  // Use loadingStates in the UI
  const renderLoadingIndicator = () => {
    if (loadingStates.conversationLoading) {
      return <div className="text-gray-500">Loading conversation...</div>;
    }
    if (loadingStates.messageSending) {
      return <div className="text-gray-500">Sending message...</div>;
    }
    return null;
  };

  // Use cleanupOldConversations when saving conversations
  useEffect(() => {
    if (chatState.conversations.length > 0) {
      const cleanedConversations = cleanupOldConversations(chatState.conversations);
      if (cleanedConversations.length !== chatState.conversations.length) {
        setChatState(prev => ({
          ...prev,
          conversations: cleanedConversations
        }));
      }
    }
  }, [chatState.conversations]);

  // Use handleErrorRecovery for error states
  useEffect(() => {
    if (chatState.error) {
      handleErrorRecovery();
    }
  }, [chatState.error]);

  // Add handleDeleteConversation inside the component
  const handleDeleteConversation = useCallback(async (conversationId: string) => {
    if (!chatState.currentAgentId) return;

    try {
      console.log('Deleting conversation:', conversationId);

      const updatedConversations = chatState.conversations.filter((c: SavedChat) => c.id !== conversationId);
      localStorage.setItem(
        `conversations_${chatState.currentAgentId}`,
        JSON.stringify(updatedConversations)
      );

      setChatState((prev: ChatState) => ({
        ...prev,
        conversations: updatedConversations,
        ...(prev.conversationId === conversationId ? {
          conversationId: null,
          messages: [],
          currentChatTitle: ''
        } : {})
      }));

      if (chatState.conversationId === conversationId) {
        await handleNewChat();
      }

      toast.success('Conversation deleted');
    } catch (error) {
      console.error('Failed to delete conversation:', error);
      toast.error('Failed to delete conversation');
    }
  }, [chatState.currentAgentId, chatState.conversationId, handleNewChat]);

  // Add uiState declaration
  const [uiState, setUiState] = useState<ChatUIState>({
    isSidebarOpen: false,
    isCommandPaletteOpen: false,
    input: '',
    isProcessing: false
  });

  // Add this new function after the existing state declarations
  const updateStreamState = useCallback((
    stage: StreamStage,
    action?: string,
    toolUpdate?: ToolUsage
  ) => {
    setChatState(prev => {
      const currentStream = prev.streamState || {
        stage: 'thinking' as StreamStage,
        progress: 0,
        toolCalls: [],
        activeTools: [],
        startTime: Date.now(),
        currentAction: ''
      };

      const stageProgress: Record<StreamStage, number> = {
        thinking: 25,
        processing: 50,
        generating: 75,
        complete: 100
      };

      return {
        ...prev,
        streamState: {
          ...currentStream,
          stage,
          progress: stageProgress[stage],
          currentAction: action || currentStream.currentAction,
          toolCalls: currentStream.toolCalls || [],
          activeTools: toolUpdate 
            ? updateTools(currentStream.activeTools, toolUpdate)
            : currentStream.activeTools
        }
      };
    });
  }, []);

  // Add helper function for updating tools
  const updateTools = (currentTools: ToolUsage[], newTool: ToolUsage): ToolUsage[] => {
    const toolIndex = currentTools.findIndex(t => t.name === newTool.name);
    if (toolIndex >= 0) {
      return currentTools.map((t, i) => i === toolIndex ? newTool : t);
    }
    return [...currentTools, newTool];
  };

  // Add the formatToolName helper function
  const formatToolName = (name: string): string => {
    return name
      // Remove get_ prefix
      .replace(/^get_/, '')
      // Replace underscores with spaces
      .replace(/_/g, ' ')
      // Capitalize first letter of each word
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="flex h-full bg-white rounded-lg shadow-lg">
      {/* Conversations Sidebar - Fixed height with proper scrolling */}
      <div className={`w-64 border-r bg-gray-50 flex flex-col h-full ${
        uiState.isSidebarOpen ? 'block' : 'hidden'
      } lg:block`}>
        {/* New Chat Button - Fixed at top */}
        <div className="p-4 border-b bg-gray-50 sticky top-0 z-10">
          <button
            onClick={() => {
              setUiState(prev => ({ ...prev, isCommandPaletteOpen: false }));
              if (selectedAgent?.id) {
                handleNewChat();
              } else {
                toast.error('Please select an agent first');
              }
            }}
            disabled={!selectedAgent}
            className={`w-full px-4 py-2 rounded-lg flex items-center justify-center transition-all duration-200 ${
              selectedAgent
                ? 'bg-[#004DB5] text-white hover:bg-blue-600'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
            title={selectedAgent ? 'Start a new chat' : 'Please select an agent first'}
          >
            <Plus className={`w-4 h-4 mr-2 ${selectedAgent ? '' : 'opacity-50'}`} />
            {selectedAgent ? 'New Chat' : 'Select an Agent to Chat'}
          </button>
        </div>

        {/* Conversations List - Scrollable with proper containment */}
        <div className="flex-1 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 180px)' }}>
          {renderConversationsList()}
        </div>
      </div>

      {/* Main Chat Area - Fixed layout */}
      <div className="flex-1 flex flex-col h-full overflow-hidden">
        {/* Chat Header - Fixed */}
        <div className="px-6 py-4 border-b bg-white flex items-center shadow-sm">
          <button
            onClick={() => setUiState(prev => ({ ...prev, isSidebarOpen: !prev.isSidebarOpen }))}
            className="lg:hidden text-gray-500 hover:text-gray-700 mr-4"
          >
            <Menu className="w-6 h-6" />
          </button>

          <div className="flex items-center">
            <div className="flex flex-col">
              <div className="text-xs text-gray-400 font-medium">
                Active Agent
              </div>
              <div className="flex items-center">
                {selectedAgent ? (
                  <>
                    <span className="w-1.5 h-1.5 rounded-full bg-green-500 mr-2" />
                    <span className="text-sm font-medium text-gray-700">{selectedAgent.name}</span>
                  </>
                ) : (
                  <span className="text-sm text-gray-400">No Agent Selected</span>
                )}
              </div>
            </div>
          </div>

          <div className="ml-auto flex items-center space-x-2">
            <button
              onClick={() => setChatState(prev => ({ ...prev, debugMode: !prev.debugMode }))}
              className={`p-2 rounded-lg transition-colors ${
                chatState.debugMode 
                  ? 'bg-blue-50 text-blue-600' 
                  : 'text-gray-400 hover:text-gray-600'
              }`}
              title="Toggle Debug Mode"
            >
              <Bug className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Messages Area - Scrollable */}
        <div className="flex-1 overflow-y-auto px-6 py-4 space-y-6">
          {chatState.messages?.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-gray-500">
              {selectedAgent ? (
                <>
                  <div className="text-lg mb-2">No messages yet</div>
                  <div className="text-sm">Start a conversation with the agent</div>
                </>
              ) : (
                <>
                  <div className="text-lg font-medium text-gray-600 mb-3">
                    Select an Agent to Begin
                  </div>
                  <div className="text-sm text-gray-500 text-center max-w-md">
                    Choose an AI agent from the dropdown above to start a conversation.
                    Each agent has unique capabilities and knowledge domains.
                  </div>
                </>
              )}
            </div>
          ) : (
            chatState.messages?.map((message) => (
              <Message key={message.id} message={message} />
            ))
          )}

          {/* Typing Indicator */}
          {isAgentTyping && (
            <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow-sm">
              <div className="flex space-x-1">
                <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce"
                     style={{ animationDelay: '0ms' }} />
                <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce"
                     style={{ animationDelay: '150ms' }} />
                <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce"
                     style={{ animationDelay: '300ms' }} />
              </div>
              <span className="text-sm text-gray-600">Agent is thinking...</span>
            </div>
          )}

          {/* Scroll anchor */}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area - Fixed at bottom */}
        <div className="p-6 border-t bg-white shadow-lg">
          <CommandPalette
            isOpen={uiState.isCommandPaletteOpen}
            inputValue={uiState.input}
            onSelectCommand={(commandType) => {
              setUiState(prev => ({
                ...prev,
                input: `${commandType} `,
                isCommandPaletteOpen: false
              }));
            }}
          />

          <div className="flex items-center space-x-3 bg-gray-50 p-1 rounded-lg">
            <label
              htmlFor="file-upload"
              className="p-2 hover:bg-gray-100 rounded-lg cursor-pointer transition-colors"
            >
              <Paperclip className="text-gray-400 hover:text-blue-600 w-5 h-5" />
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={(e) => {
                  console.log('File selected:', e.target.files?.[0]);
                }}
              />
            </label>

            <input
              type="text"
              value={uiState.input}
              onChange={handleInputChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
              placeholder={chatState.conversationId
                ? "Type a message or use @ for commands..."
                : "Select an agent to start chatting"}
              className="flex-1 p-2 bg-transparent focus:outline-none text-gray-700 placeholder-gray-400"
              disabled={!chatState.conversationId}
            />

            <button
              onClick={handleSend}
              disabled={!chatState.conversationId || !uiState.input.trim()}
              className={`p-2 rounded-lg transition-colors ${
                !chatState.conversationId || !uiState.input.trim()
                  ? 'bg-gray-100 text-gray-400'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              }`}
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>

      {chatState.debugMode && (
        <DebugPanel 
          streamState={chatState.streamState}
          onClose={() => setChatState(prev => ({ ...prev, debugMode: false }))}
        />
      )}
    </div>
  );
};

export default ChatInterface;
