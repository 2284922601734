import React, { useState, useEffect } from 'react';
import { CheckCircle, AlertCircle, Info, X } from 'lucide-react';

interface Notification {
  id: string;
  type: 'success' | 'error' | 'info';
  message: string;
  timestamp: Date;
  read: boolean;
}

interface NotificationsProps {
  onClose: () => void;
}

const mockNotifications: Notification[] = [
  { id: '1', type: 'success', message: 'Knowledge Graph updated with new insights', timestamp: new Date(Date.now() - 1000 * 60 * 5), read: false },
  { id: '2', type: 'error', message: 'Task "Self-Improvement Tracker" failed due to invalid input', timestamp: new Date(Date.now() - 1000 * 60 * 30), read: false },
  { id: '3', type: 'info', message: 'New tool added to Tool Management: "Agent Configuration Assistant"', timestamp: new Date(Date.now() - 1000 * 60 * 60 * 2), read: true },
  { id: '4', type: 'success', message: 'Document Library updated with new resource: "Supply Chain Report"', timestamp: new Date(Date.now() - 1000 * 60 * 60 * 24), read: true },
  { id: '5', type: 'info', message: 'Evaluation Dashboard report generated successfully', timestamp: new Date(Date.now() - 1000 * 60 * 10), read: false },
];

const Notifications: React.FC<NotificationsProps> = ({ onClose }) => {
  const [notifications, setNotifications] = useState<Notification[]>(mockNotifications);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newNotification: Notification = {
        id: Date.now().toString(),
        type: ['success', 'error', 'info'][Math.floor(Math.random() * 3)] as 'success' | 'error' | 'info',
        message: `New notification at ${new Date().toLocaleTimeString()}`,
        timestamp: new Date(),
        read: false,
      };
      setNotifications(prev => [newNotification, ...prev]);
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleMarkAsRead = (id: string) => {
    setNotifications(notifications.map(notif =>
      notif.id === id ? { ...notif, read: true } : notif
    ));
  };

  const handleDeleteNotification = (id: string) => {
    setNotifications(notifications.filter(notif => notif.id !== id));
  };

  const handleMarkAllAsRead = () => {
    setNotifications(notifications.map(notif => ({ ...notif, read: true })));
  };

  const handleClearAllNotifications = () => {
    setNotifications([]);
  };

  const getIcon = (type: 'success' | 'error' | 'info') => {
    switch (type) {
      case 'success': return <CheckCircle className="text-[#004DB5]" />;
      case 'error': return <AlertCircle className="text-[#004DB5]" />;
      case 'info': return <Info className="text-[#004DB5]" />;
    }
  };

  const unreadCount = notifications.filter(notif => !notif.read).length;

  return (
    <div className="bg-white rounded-md shadow-lg overflow-hidden w-full sm:w-80 max-w-sm">
      <div className="py-2 px-4 bg-gray-100 text-gray-800 flex justify-between items-center sticky top-0">
        <h3 className="text-lg font-semibold">Notifications ({unreadCount})</h3>
        <div>
          <button
            onClick={handleMarkAllAsRead}
            className="text-sm text-[#004DB5] hover:text-blue-700 mr-4"
          >
            Mark all as read
          </button>
          <button
            onClick={handleClearAllNotifications}
            className="text-sm text-[#004DB5] hover:text-blue-700"
          >
            Clear all
          </button>
        </div>
      </div>
      <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
        {notifications.length === 0 ? (
          <p className="py-4 px-4 text-gray-500 text-center">No notifications</p>
        ) : (
          notifications.map(notification => (
            <div
              key={notification.id}
              className={`flex items-start py-3 px-4 border-b ${notification.read ? 'bg-gray-50' : 'bg-white'}`}
            >
              <div className="flex-shrink-0 mr-3">
                {getIcon(notification.type)}
              </div>
              <div className="flex-grow">
                <p className={`text-sm ${notification.read ? 'text-gray-600' : 'text-gray-800 font-semibold'}`}>
                  {notification.message}
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  {notification.timestamp.toLocaleString()}
                </p>
              </div>
              <div className="flex-shrink-0 ml-2">
                {!notification.read && (
                  <button
                    onClick={() => handleMarkAsRead(notification.id)}
                    className="text-xs text-[#004DB5] hover:text-blue-700"
                  >
                    Mark as read
                  </button>
                )}
                <button
                  onClick={() => handleDeleteNotification(notification.id)}
                  className="ml-2 text-gray-400 hover:text-gray-600"
                >
                  <X size={16} />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Notifications;