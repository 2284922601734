import React, { useState, useEffect } from "react";
import { Search, Upload, X } from "lucide-react";
import Tooltip from "../ui/Tooltip";
import { DocumentList } from "./DocumentList";
import { useAgentContext } from "../../context/AgentContext";
import { toast } from "react-hot-toast";
import { documentApi } from "../../api/documentApi";
import { Document } from "../../types/document";
import { API_BASE_URL } from "../../api/documentApi";

const DocumentLibrary: React.FC = () => {
  const { selectedAgent } = useAgentContext();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<{[key: string]: number}>({});
  const [streamingResults, setStreamingResults] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(25);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const fetchDocuments = async () => {
    if (!selectedAgent) {
      console.warn('No agent selected');
      return;
    }

    setIsLoading(true);
    try {
      const response = await documentApi.searchDocuments(selectedAgent.id, '');
      console.log('Documents fetched:', response);
      
      const transformedDocuments = response.results
        .map((result, index) => {
          const doc = result.node;
          console.log('Complete document:', JSON.stringify(doc, null, 2));

          if (!doc) {
            console.warn('Document node is missing:', result);
            return null;
          }

          // Try to extract a meaningful title
          let displayName = '';
          
          // Helper function to clean text
          const cleanText = (text: string) => {
            return text
              .replace(/[^\w\s-]/g, ' ') // Replace special chars with spaces
              .replace(/\s+/g, ' ')      // Collapse multiple spaces
              .trim();
          };

          // Try to find a meaningful title from content
          if (doc.content) {
            const lines = doc.content.split('\n').map(line => line.trim()).filter(line => line);
            
            // Look for common document title patterns
            const titlePatterns = [
              // Chapter or section headings
              /^(?:Chapter|Section)\s+\d+[:.]?\s*(.+)/i,
              // Table headings
              /^Table\s+\d+[:.]?\s*(.+)/i,
              // Numbered headings
              /^(?:\d+\.|\[[\d\.]+\])\s*(.+)/,
              // File paths or names
              /\/([^\/]+\.[a-zA-Z0-9]+)$/,
              // All caps headings
              /^[A-Z][A-Z\s]{3,}(?:\s+\d+)?$/,
              // Title case headings
              /^(?:[A-Z][a-z]+\s*){2,}$/
            ];

            // Try each pattern
            for (const pattern of titlePatterns) {
              const match = lines.find(line => pattern.test(line));
              if (match) {
                displayName = match;
                break;
              }
            }

            // If no pattern matches, use first non-empty line that's not just numbers/symbols
            if (!displayName) {
              displayName = lines.find(line => {
                const cleaned = cleanText(line);
                return cleaned.length > 0 && 
                       cleaned.length < 100 &&
                       /[a-zA-Z]{2,}/.test(cleaned); // At least 2 letters
              }) || '';
            }
          }

          // Clean up the title
          displayName = cleanText(displayName);
          
          // Truncate with ellipsis if too long
          if (displayName.length > 50) {
            displayName = displayName.substring(0, 47) + '...';
          }

          // Fallback to document type or number if no good title found
          if (!displayName || displayName.length < 3) {
            const docType = doc.metadata?.type || 'Document';
            displayName = `${docType} ${index + 1}`;
          }

          return {
            id: `${doc.id || doc.doc_id}_${index}`,
            original_id: doc.id || doc.doc_id || '',
            title: displayName,
            content: doc.content || '',
            metadata: {
              type: doc.metadata?.type || doc.metadata?.file_type || 'unknown',
              created_at: doc.metadata?.created_at || doc.created_at || new Date().toISOString(),
              updated_at: doc.metadata?.updated_at || doc.updated_at || new Date().toISOString(),
              size: doc.metadata?.size || doc.metadata?.file_size || 0,
              uploaded_by: doc.metadata?.uploaded_by || 'Unknown',
              tags: doc.metadata?.tags || []
            }
          };
        })
        .filter((doc): doc is NonNullable<typeof doc> => doc !== null);

      console.log('Transformed documents:', transformedDocuments);
      setDocuments(transformedDocuments);
      setStreamingResults([`Loaded ${transformedDocuments.length} documents for ${selectedAgent.name}`]);
    } catch (error) {
      console.error('Error fetching documents:', error);
      toast.error('Failed to load documents');
      setStreamingResults(prev => [...prev, 'Error loading documents']);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAgent) {
      console.log('Loading documents for agent:', selectedAgent.id);
      fetchDocuments();
    } else {
      setDocuments([]);
      setStreamingResults(['Please select an agent to view their documents']);
    }
  }, [selectedAgent]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || !selectedAgent) return;

    setUploading(true);
    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });
    formData.append('agent_id', selectedAgent.id);
    formData.append('user_id', 'default_user');

    try {
      setStreamingResults(prev => [...prev, 'Starting file upload...']);
      
      const response = await fetch(
        `${API_BASE_URL}/api/v1/knowledge/files/upload`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Upload failed with status: ${response.status}`);
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('No reader available');
      }

      // Read the streaming response
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        // Decode and display the chunk
        const chunk = new TextDecoder().decode(value);
        setStreamingResults(prev => [...prev, chunk]);
        
        // Update progress (if the chunk contains progress information)
        try {
          const progressData = JSON.parse(chunk);
          if (progressData.progress) {
            setUploadProgress(prev => ({
              ...prev,
              [progressData.file_name || 'upload']: progressData.progress
            }));
          }
        } catch (e) {
          // If chunk isn't JSON, just display as text
          console.log('Progress chunk:', chunk);
        }
      }

      setStreamingResults(prev => [...prev, 'Upload complete']);
      toast.success('Files uploaded successfully');
      await fetchDocuments(); // Refresh document list

    } catch (error) {
      console.error('Error uploading files:', error);
      toast.error('Failed to upload files');
      setStreamingResults(prev => [...prev, `Upload error: ${error instanceof Error ? error.message : 'Unknown error'}`]);
    } finally {
      setUploading(false);
      setUploadProgress({});
    }
  };

  const handleSearch = async (term: string) => {
    setSearchTerm(term);
    if (!selectedAgent) return;

    setIsLoading(true);
    try {
      const response = await documentApi.searchDocuments(selectedAgent.id, term);
      console.log('Search results:', response);
      
      // Transform documents same as before
      const transformedDocuments = response.results
        .map((result, index) => {
          const doc = result.node;
          console.log('Complete document:', JSON.stringify(doc, null, 2));

          if (!doc) {
            console.warn('Document node is missing:', result);
            return null;
          }

          // Try to extract a meaningful title
          let displayName = '';
          
          // Helper function to clean text
          const cleanText = (text: string) => {
            return text
              .replace(/[^\w\s-]/g, ' ') // Replace special chars with spaces
              .replace(/\s+/g, ' ')      // Collapse multiple spaces
              .trim();
          };

          // Try to find a meaningful title from content
          if (doc.content) {
            const lines = doc.content.split('\n').map(line => line.trim()).filter(line => line);
            
            // Look for common document title patterns
            const titlePatterns = [
              // Chapter or section headings
              /^(?:Chapter|Section)\s+\d+[:.]?\s*(.+)/i,
              // Table headings
              /^Table\s+\d+[:.]?\s*(.+)/i,
              // Numbered headings
              /^(?:\d+\.|\[[\d\.]+\])\s*(.+)/,
              // File paths or names
              /\/([^\/]+\.[a-zA-Z0-9]+)$/,
              // All caps headings
              /^[A-Z][A-Z\s]{3,}(?:\s+\d+)?$/,
              // Title case headings
              /^(?:[A-Z][a-z]+\s*){2,}$/
            ];

            // Try each pattern
            for (const pattern of titlePatterns) {
              const match = lines.find(line => pattern.test(line));
              if (match) {
                displayName = match;
                break;
              }
            }

            // If no pattern matches, use first non-empty line that's not just numbers/symbols
            if (!displayName) {
              displayName = lines.find(line => {
                const cleaned = cleanText(line);
                return cleaned.length > 0 && 
                       cleaned.length < 100 &&
                       /[a-zA-Z]{2,}/.test(cleaned); // At least 2 letters
              }) || '';
            }
          }

          // Clean up the title
          displayName = cleanText(displayName);
          
          // Truncate with ellipsis if too long
          if (displayName.length > 50) {
            displayName = displayName.substring(0, 47) + '...';
          }

          // Fallback to document type or number if no good title found
          if (!displayName || displayName.length < 3) {
            const docType = doc.metadata?.type || 'Document';
            displayName = `${docType} ${index + 1}`;
          }

          return {
            id: `${doc.id || doc.doc_id}_${index}`,
            original_id: doc.id || doc.doc_id || '',
            title: displayName,
            content: doc.content || '',
            metadata: {
              type: doc.metadata?.type || doc.metadata?.file_type || 'unknown',
              created_at: doc.metadata?.created_at || doc.created_at || new Date().toISOString(),
              updated_at: doc.metadata?.updated_at || doc.updated_at || new Date().toISOString(),
              size: doc.metadata?.size || doc.metadata?.file_size || 0,
              uploaded_by: doc.metadata?.uploaded_by || 'Unknown',
              tags: doc.metadata?.tags || []
            }
          };
        })
        .filter((doc): doc is NonNullable<typeof doc> => doc !== null);

      setDocuments(transformedDocuments);
    } catch (error) {
      console.error('Error searching documents:', error);
      toast.error('Failed to search documents');
    } finally {
      setIsLoading(false);
    }
  };

  const generateVectorIndex = async () => {
    try {
      // TODO: Implement actual API call
      // await fetch('/document_ingestion/api/v1/knowledge/rag/generate_vector_index', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({
      //     index_name: 'document_index',
      //     node_label: 'Document',
      //     embedding_property: 'embedding',
      //     vector_dimensions: 768,
      //     similarity_function: 'cosine'
      //   })
      // });
      console.log("Vector index generated");
      setStreamingResults((prev) => [
        ...prev,
        "Vector index generated successfully.",
      ]);
    } catch (error) {
      console.error("Error generating vector index:", error);
      setStreamingResults((prev) => [
        ...prev,
        "Error occurred while generating vector index.",
      ]);
    }
  };

  const reindexDocumentChunks = async () => {
    try {
      // TODO: Implement actual API call
      // const response = await fetch('/document_ingestion/api/v1/knowledge/reindex_document_chunks', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ batch_size: 100 })
      // });
      // const reader = response.body.getReader();
      // while (true) {
      //   const { done, value } = await reader.read();
      //   if (done) break;
      //   console.log(new TextDecoder().decode(value));
      // }
      console.log("Document chunks reindexed");
      setStreamingResults((prev) => [
        ...prev,
        "Document chunks reindexed successfully.",
      ]);
    } catch (error) {
      // Add a modal for error handling or display a toast notification
      // console.error("Error reindexing document chunks:", error);
      // setStreamingResults((prev) => [
      //   ...prev,
      //   "Error occurred while reindexing document chunks.",
      // ]);
    }
  };

  // Add handlers for DocumentList props
  const handleViewDocument = (doc: Document) => {
    setSelectedDocument(doc);
    setIsViewModalOpen(true);
  };

  const handleUpdateDocument = async (id: string, metadata: any) => {
    if (!selectedAgent) return;
    try {
      // Extract the original ID by removing the index suffix
      const originalId = id.split('_')[0];
      const response = await documentApi.updateDocument(selectedAgent.id, originalId, metadata);
      if (response.status === 'success') {
        toast.success('Document updated successfully');
        await fetchDocuments(); // Refresh list
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error updating document:', error);
      toast.error('Failed to update document');
    }
  };

  const handleDeleteDocument = async (id: string) => {
    if (!selectedAgent) return;
    try {
      // Extract the original ID by removing the index suffix
      const originalId = id.split('_')[0];
      const response = await documentApi.deleteDocument(selectedAgent.id, originalId);
      if (response.status === 'success') {
        toast.success('Document deleted successfully');
        await fetchDocuments(); // Refresh list
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      toast.error('Failed to delete document');
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6" style={{ color: "#004DB5" }}>
        Document Library
      </h2>

      <div className="mb-6 flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="w-full sm:w-auto mb-4 sm:mb-0">
          <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
            <input
              type="text"
              placeholder="Search documents..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="w-full p-2 pl-10 border rounded-lg shadow-sm"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full sm:w-auto space-y-2 sm:space-y-0 sm:space-x-2">
          <Tooltip content="Upload new documents to the library">
            <label className="cursor-pointer bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center justify-center w-full sm:w-auto">
              <Upload className="inline mr-2 h-5 w-5" />
              Upload
              <input
                type="file"
                onChange={handleFileUpload}
                className="hidden"
                multiple
              />
            </label>
          </Tooltip>
          <div className="flex justify-between sm:justify-start space-x-2 w-full sm:w-auto">
            <Tooltip content="Generate a vector index for efficient similarity search across all documents. This improves search speed and accuracy.">
              <button
                onClick={generateVectorIndex}
                className="bg-[#004DB5] text-white px-2 sm:px-4 py-2 rounded-lg hover:bg-blue-600 text-sm sm:text-base flex-1 sm:flex-none"
              >
                Generate Index
              </button>
            </Tooltip>
            <Tooltip content="Reprocess and update the embeddings for all document chunks. Use this after significant changes to improve search quality.">
              <button
                onClick={reindexDocumentChunks}
                className="bg-[#004DB5] text-white px-2 sm:px-4 py-2 rounded-lg hover:bg-blue-600 text-sm sm:text-base flex-1 sm:flex-none"
              >
                Reindex Chunks
              </button>
            </Tooltip>
          </div>
        </div>
      </div>

      {uploading && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Upload Progress</h3>
          {Object.entries(uploadProgress).map(([fileName, progress]) => (
            <div key={fileName} className="mb-2">
              <div className="text-sm">{fileName}</div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-[#004DB5] h-2.5 rounded-full"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Upload Status</h3>
        <div className="bg-gray-100 p-4 rounded-lg max-h-40 overflow-y-auto">
          {streamingResults.map((result, index) => (
            <div key={index} className="text-sm mb-1">
              {result}
            </div>
          ))}
        </div>
      </div>
      {!isLoading && documents.length > 0 && (
        <DocumentList 
          documents={documents}
          onView={handleViewDocument}
          onUpdate={handleUpdateDocument}
          onDelete={handleDeleteDocument}
        />
      )}

      {isViewModalOpen && selectedDocument && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
            {/* Modal Header */}
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-lg font-semibold">{selectedDocument.title}</h3>
              <button 
                onClick={() => setIsViewModalOpen(false)}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            {/* Modal Content */}
            <div className="p-4 overflow-y-auto flex-grow">
              {/* Document Metadata */}
              <div className="mb-4 text-sm text-gray-600">
                <p>Type: {selectedDocument.metadata.type || 'Unknown'}</p>
                <p>Created: {new Date(selectedDocument.metadata.created_at || '').toLocaleString()}</p>
                <p>Updated: {new Date(selectedDocument.metadata.updated_at || '').toLocaleString()}</p>
                <p>Size: {selectedDocument.metadata.size || 0} bytes</p>
                <p>Uploaded by: {selectedDocument.metadata.uploaded_by}</p>
              </div>
              
              {/* Document Content */}
              <div className="mt-4 p-4 bg-gray-50 rounded-lg whitespace-pre-wrap font-mono text-sm">
                {selectedDocument.content}
              </div>
            </div>
            
            {/* Modal Footer */}
            <div className="border-t p-4 flex justify-end">
              <button
                onClick={() => setIsViewModalOpen(false)}
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentLibrary;
